import React from 'react';
import {Form, Grid} from 'semantic-ui-react';
import FormField from '../formField';
import {SHIPPINGS_GRID} from '../../../../constants/grids';
import {useSelector} from 'react-redux';
import {columnsTypesConfigSelector} from '../../../../ducks/representations';
import FormTable from "../../../../components/Form/FormTable";
import {arrayToColumnsConfig} from "../../../../utils/sort";
import {additionalServicesFields} from "../../../../constants/stoppers";
import {SETTINGS_TYPE_SHOW} from "../../../../constants/formTypes";
import {isAllowedFullEditSelector} from "../../../../ducks/profile";

const Costs = ({form = {}, onChange, settings, error}) => {
    const columnsConfig = useSelector(state => columnsTypesConfigSelector(state, SHIPPINGS_GRID));
    const isAllowedFullEdit = useSelector(state => isAllowedFullEditSelector(state));

    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <FormField
                            name="totalDeliveryCost"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="vatAmount"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="shippingCurrencyId"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <FormField
                            name="totalPrice"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="costAgreedTC"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="costAgreedTransportCoordinator"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <FormField
                            name="commentTC"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="commentTransportCoordinator"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <div className="w-100">
                        <FormTable
                            error={error}
                            label='additionalServices'
                            name='additionalServices'
                            form={form}
                            columnsConfig={arrayToColumnsConfig(additionalServicesFields.map(f => f.name === additionalServicesFields[1].name ? ({...f, name: "quantity"}) : f))}
                            columnsArray={additionalServicesFields.map(c => c.name === additionalServicesFields[1].name ? "quantity" : c.name)}
                            onChange={onChange}
                            isDisabled={!isAllowedFullEdit && settings.additionalServiceDisplay === SETTINGS_TYPE_SHOW}
                        />
                    </div>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default Costs;
