import React from 'react';
import {useTranslation} from "react-i18next";
import {Segment} from "semantic-ui-react";
import FormFields from "../../../../components/Form/Form";
import {FieldWithCreate} from "../fieldWithCreate";

const Information = ({form, settings, onChange, error, columnsConfig}) => {
    const {t} = useTranslation();

    const contentBlock = (fields, label, content) => {
        return <FormFields
            label={label && t(label)}
            form={form}
            error={error}
            settings={settings}
            handleChange={onChange}
            columnsConfig={columnsConfig}
            columnsArray={fields}
            content={content}
        />
    }

    const infoFields = [
        'shippingNumber',
        'transportCompanyId',
        'waybillNumberOneC',
        'shipmentNumber',
        'clientId',
        'transportCompanyServiceTypeId',
        'tarifficationType',
        'customsPostImportId',
        'customsPostExportId',
    ];

    const vehicleInfoGroupFields = [
        'vehicleTypeId',
        'numberOfVehicles',
        'addTempControl',
        'palletStacking',
        'shippingUploadingMethod',
    ];

    const commonCargoInfoFields = [
        'productTypes',
        'weightKg',
        'cargoDimensionType',
        'cargoCost',
        'cargoCostCurrencyId',
        'isDangerousCargo',
        'additionalInfo'
    ];

    const fieldsWithCreate = [
        {
            name: 'vehicleId',
            dictionaryName: 'vehicles'
        },
        {
            name: 'trailerId',
            dictionaryName: 'trailers'
        },
        {
            name: 'driverId',
            dictionaryName: 'transportCompanyDrivers'
        }
    ]

    const content = <>
        {
            fieldsWithCreate.map(field => <FieldWithCreate
                dictionaryName={field.dictionaryName}
                name={field.name}
                form={form}
                error={error}
                settings={settings}
                columnsConfig={columnsConfig}
                onChange={onChange}
            />)
        }
    </>

    return (
        <div className='create-form'>
            <div className='create-form__info'>
                {contentBlock(infoFields)}
            </div>
            <Segment className='grid-card-block'>
                {contentBlock(vehicleInfoGroupFields, 'vehicleInfoGroup', content)}
            </Segment>
            <Segment className='grid-card-block'>
                {contentBlock(commonCargoInfoFields, 'commonCargoInfo')}
            </Segment>
        </div>
    );
};

export default Information;