import React, {useState} from 'react';
import {Confirm} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import List from "../customGrid/list";
import {
    PERSONAL_CABINET_MEETING_PROTOCOLS
} from "../../constants/personalCabinetPages";
import {useDispatch, useSelector} from "react-redux";
import {itemsDeleteRequest} from "../../ducks/personalCabinet";
import Documents from "../customGrid/components/shared/documents";
import {canDeleteSelector} from "../../ducks/gridList";
import {PERSONAL_CABINET_CARD_LINK, PERSONAL_CABINET_NEW_LINK} from "../../router/links";

const MeetingProtocol = ({params: globalParams}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const name = PERSONAL_CABINET_MEETING_PROTOCOLS;

    const canDelete = useSelector(state => canDeleteSelector(state, name));

    let [trigger, setTrigger] = useState(false);
    let [ids, setIds] = useState(null);

    const deleteItems = (ids) => {
        ids.length && dispatch(itemsDeleteRequest({
            name,
            ids,
            callback: () => {
                setIds(null);
                setTrigger(tr => !tr);
            }}));
    }

    const btn = (id) => {
        return <Documents
            id={id}
            isBtn
            btnIcon={'upload'}
            btnPopupText={'addDoc'}
            cardId={id}
            gridName={PERSONAL_CABINET_MEETING_PROTOCOLS}
            isEditPermissions={true}
            btnPopupPosition={'top right'}
            updateHistory={()=> {
                setTrigger(null);
                setTrigger(true);
            }}
        />
    }

    return (
        <div className="container personal-cabinet__page">
            <List
                newLink={PERSONAL_CABINET_NEW_LINK.replace(':pageName', PERSONAL_CABINET_MEETING_PROTOCOLS)}
                cardLink={PERSONAL_CABINET_CARD_LINK.replace(':pageName', PERSONAL_CABINET_MEETING_PROTOCOLS)}
                nameReport={PERSONAL_CABINET_MEETING_PROTOCOLS}
                trigger={trigger}
                actionBtns={btn}
                infinityScroll
                noModes
                noFooter
                noRepresentation
                reportWithCheckbox
                customBtns={canDelete && [{
                    name: 'delete',
                    onClick: (ids) => setIds(ids),
                    icon: 'trash alternate outline',
                    disabled: false
                }]}
                isShowActions
                preFilters={globalParams.company && {transportCompanyId: globalParams.company.value}}
            />
            <Confirm
                open={ids}
                content={t('delete-items-confirm')}
                cancelButton={t('cancelConfirm')}
                confirmButton={t('delete')}
                onCancel={()=>setIds(null)}
                onConfirm={()=>{
                    deleteItems(ids)
                }}
            />
        </div>
    );
};

export default MeetingProtocol;
