import React from 'react';
import InfoIcon from "./infoIcon";
import {Popup} from "semantic-ui-react";
import Icon from "../../../components/CustomIcon";

const StatisticBlock = ({
                            icon,
                            label,
                            description,
                            value,
                            valueOutOf,
                            valueUnit,
                            valueInfo,
                            changeValue,
                            unit,
                            changeValueUnit,
                            gradeOfValue = 'neutral',
                            gradeOfChange = 'neutral',
                            onClick,
                        }) => {
    return (
        <div onClick={onClick} className={`styled-block-stat statistic-block ${!icon && 'statistic-block_mini'} ${(icon && !changeValue) && 'statistic-block_fix-styles'}`}>
            <div>
                <h3>
                    {label} <span>{unit && `(${unit})`}</span>
                    {description && <div className='label-description'>{description}</div>}
                </h3>
                {value && <div className={`statistic-block__value statistic-block_${gradeOfValue}`}>
                    {value}
                    <span className='statistic-block__value_out-of'>{valueOutOf && `/${valueOutOf}`}</span>
                    <span className='statistic-block__value_unit'> {valueUnit}</span>

                    {valueInfo && <Popup
                        className='statistic-block__popup'
                        content={valueInfo}
                        position='top left'
                        size='mini'
                        trigger={
                            <span className='statistic-block__value_info'>
                                <Icon name={'question'} size={16}/>
                            </span>
                        }
                    />}
                </div>}
                {changeValue &&
                    <div className={`statistic-block__change-value statistic-block__change-icon statistic-block_${gradeOfChange}`}>
                        {changeValue}
                        <span> {changeValueUnit && `(${changeValueUnit})`}</span></div>}
            </div>
            {icon && <div className='statistic-block__icon'>
                <InfoIcon iconName={icon}/>
            </div>}
        </div>
    );
};

export default StatisticBlock;