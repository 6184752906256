import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Form, Icon} from "semantic-ui-react";
import FormField from "../../components/BaseComponents";
import {useTranslation} from "react-i18next";
import List from "../customGrid/list";
import {SHIPPINGS_GRID} from "../../constants/grids";
import {useDispatch, useSelector} from "react-redux";
import {progressSelector} from "../../ducks/gridList";
import {MULTISELECT_NEW_TYPE, MULTISELECT_TYPE} from "../../constants/columnTypes";
import {clearFiltersData, filterSettingsSelector, getDataForReportRequest} from "../../ducks/reports";

const Report = ({name, initialParams, filterFields}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [params, setParams] = useState(initialParams);
    const [paramsOpen, setParamsOpen] = useState(true);
    const [height, setHeight] = useState(0);
    const [notChangeForm, setNotChangeForm] = useState(true);
    const [click, setClick] = useState(false);
    const [trigger, setTrigger] = useState(false);

    const loading = useSelector(state => progressSelector(state));
    const filterSettings = useSelector(state => filterSettingsSelector(state));

    useEffect(() => {
        dispatch(getDataForReportRequest({ gridName: name }))
        return () => dispatch(clearFiltersData())
    }, []);

    useEffect(() => {
        filterSettings && setParams(params => {
            return {
                ...params,
                ...filterSettings
            }
        });
    }, [filterSettings]);

    const ro = useRef(new ResizeObserver(el => {
        setHeight(el[0].target.offsetHeight)
    }))

    const resize = useCallback((container) => {
        if (container) ro.current.observe(container)
        else if (ro.current) {
            ro.current.disconnect()
            setHeight(0)
        }
    }, [ro.current])

    const handleChangeParams = (e, { name, value }) => {
        setParams(params => {
            return {
                ...params,
                [name]: value,
            };
        })

        notChangeForm && setNotChangeForm(false)
    }

    const generateReport = () => {
        setTrigger(trigger => !trigger);
        setClick(true);
    }

    return (
        <div className="container">
            <div className="report">
                <div ref={resize} className="report_params" id='params'>
                    <div className="card-content-block_header">
                        <div className="card-content-block_header_actions">
                            <div
                                className="card-content-block_header_accordion"
                                onClick={() => setParamsOpen(!paramsOpen)}
                            >
                                <Icon name={paramsOpen ? 'angle down' : 'angle up'}/>
                            </div>
                        </div>
                    </div>
                    {paramsOpen && <Form className='report__filters'>
                        {
                            filterFields.map(field => (
                                <FormField
                                    key={field.name}
                                    {...field}
                                    value={params[field.name]}
                                    onChange={handleChangeParams}
                                    textValue={([MULTISELECT_NEW_TYPE, MULTISELECT_TYPE].includes(field.type) && (!params[field.name] || params[field.name].length === 0)) && t('All')}
                                />
                            ))
                        }
                        <div className='field'>
                            <Button className='report_button' primary disabled={loading}
                                    onClick={generateReport}>
                                {t('Generate report')}
                            </Button>
                        </div>
                    </Form>}
                </div>

                <List
                    className='report__grid'
                    filters={params}
                    isReport
                    topBlockHeight={height}
                    nameReport={name}
                    trigger={trigger}
                    clickReport={click}
                    cardLink={`/grid/${SHIPPINGS_GRID}/:id#:name`}
                    infinityScroll={false}
                />
            </div>
        </div>
    )
};

export default Report;