import React from 'react';
import Select from "../../../components/BaseComponents/Select_new";
import {useTranslation} from "react-i18next";
import InfoIcon from "./infoIcon";
import {isNumber} from "../../../utils/numbersFormat";
import {Dimmer, Loader, Segment} from "semantic-ui-react";

const CompanyStatistics = ({handleChange, company, statData, loading, rank, rankLoading, disabled}) => {
    const {t} = useTranslation();

    return (
        <div className='company-statistics'>
            <div className='company-statistics__company-select'>
                <label>{t('selectCompany')}</label>
                <Select
                    noLabel
                    name="company"
                    source="transportCompanies/all"
                    onChange={handleChange}
                    value={company || null}
                    textValue={!company && t('All')}
                    isDisabled={disabled}
                />
            </div>
            <Segment vertical>
                <Dimmer inverted active={loading}>
                    <Loader/>
                </Dimmer>
                <div className='company-statistics__statistics'>
                    <InfoIcon
                        loading={rankLoading && !loading}
                        iconName='star'
                        infoName={rank}
                        infoPopupData={{
                            name: t('placeInRankingTc')
                        }}/>
                    <InfoIcon
                        iconName='up'
                        infoName={isNumber(statData.targetValueKPI) && statData.targetValueKPI+'%'}
                        infoPopupData={{
                            name: t('kpiTargetValue')
                        }}/>
                </div>
            </Segment>
        </div>
    );
};

export default CompanyStatistics;