import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, Grid, Icon, Loader, Table } from 'semantic-ui-react'
import Date from '../../components/BaseComponents/Date'
import { dateToString } from '../../utils/dateTimeFormater'
import {
    clearFiltersData,
    filterSettingsSelector,
    getDataForReportRequest,
} from '../../ducks/reports'
import { DOC_RETURN_REPORT_TYPE } from '../../constants/reportType'
import { useTranslation } from 'react-i18next'
import MultiSelect from "../../components/BaseComponents/MultiSelectInfinityScroll_new"
import useReactRouter from "use-react-router"
import List from "../../containers/customGrid/list"
import { progressSelector } from "../../ducks/gridList"
import qs from "qs"
import Select from "../../components/BaseComponents/Select_new"
import Documents from "../customGrid/components/shared/documents"
import { SHIPPINGS_GRID } from "../../constants/grids"
import { setUrlSearchParam } from "../../utils/urlParamsHelper"

const controlNames = {
    dateType: 'dateType',
    startDate: 'startDate',
    endDate: 'endDate'
}

const DocumentReturnReport = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const initialParams = {
        startDate: dateToString(),
        endDate: dateToString(),
        dateType: null,
        transportCompanyIds: [],
        planers: [],
        shippingIds: null,
        driverIds: [],
        vehicleIds: []
    }

    const { location, history } = useReactRouter()
    const globalParams = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}

    let [params, setParams] = useState(initialParams)
    let [paramsOpen, setParamsOpen] = useState(true)
    let [notChangeForm, setNotChangeForm] = useState(true)
    let [click, setClick] = useState(false)

    let [height, setHeight] = useState(0)

    let [trigger, setTrigger] = useState(false)

    const loading = useSelector(state => progressSelector(state))
    const filterSettings = useSelector(state => filterSettingsSelector(state))

    const getShippingNumberFromSearchQuery = () => {
        const params = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}
        return params.shippingNumber
    }

    useEffect(() => {
        dispatch(
            getDataForReportRequest({ gridName: DOC_RETURN_REPORT_TYPE, shippingNumber: getShippingNumberFromSearchQuery() }),
        )
        return () => dispatch(clearFiltersData())
    }, [])

    useEffect(() => {
        setParams(params => {
            return {
                ...params,
                'transportCompanyIds': globalParams.company ? [JSON.parse(globalParams.company)] : (filterSettings.transportCompanies || params.transportCompanyIds),
                'dateType': globalParams.dateType ? getDateTypeFromGlobalParams() : params.dateType || filterSettings.dateType,
                'shippingIds': params.shippingIds || filterSettings.shippings,
                'startDate': globalParams.startDate ? globalParams.startDate : (filterSettings.shippings ? null : params.startDate),
                'endDate': globalParams.endDate ? globalParams.endDate : (filterSettings.shippings ? null : params.endDate)
            }
        })
        if (filterSettings.shippings) {
            setTrigger(!trigger)
            setClick(true)
        }
    }, [filterSettings, globalParams.company, globalParams.startDate, globalParams.endDate])

    useEffect(() => {
        if (globalParams.trigger) {
            history.replace(setUrlSearchParam(location, 'trigger', null))
            generateReport()
        }
    }, [params])

    const getDateTypeFromGlobalParams = () => {
        return {
            isBulkUpdateOnly: false,
            isFilterOnly: false,
            name: t(globalParams.dateType),
            useNameAsSortingParameter: true,
            value: globalParams.dateType
        }
    }

    const handleChangeParams = (e, { name, value }) => {
        setParams(params => {
            const newParams = {
                ...params,
                [name]: value,
            }

            if (name === controlNames.dateType) {
                if (value && !params.dateType) {
                    newParams[controlNames.startDate] = dateToString()
                    newParams[controlNames.endDate] = dateToString()
                } else if (!value) {
                    newParams[controlNames.startDate] = null
                    newParams[controlNames.endDate] = null
                }
            }

            return newParams
        })

        notChangeForm && setNotChangeForm(false)
    }

    const generateReport = () => {
        setClick(true)
        setTrigger(tr => !tr)
    }

    const ro = useRef(new ResizeObserver(el => {
        setHeight(el[0].target.offsetHeight)
    }))

    const resize = useCallback((container) => {
        if (container) ro.current.observe(container)
        else if (ro.current) {
            ro.current.disconnect()
            setHeight(0)
        }
    }, [ro.current])

    const btn = (id) => {
        return <Documents
            id={id}
            isBtn
            btnIcon={'upload'}
            btnPopupText={'addDoc'}
            cardId={id}
            gridName={DOC_RETURN_REPORT_TYPE}
            isEditPermissions={true}
            btnPopupPosition={'top right'}
            updateHistory={() => {
                setTrigger(null)
                setTrigger(true)
            }}
        />
    }

    return (
        <div className="container">
            <div className="report">
                <div ref={resize} className="report_params" id='params'>
                    <div className="card-content-block_header">
                        <div className="card-content-block_header_actions">
                            <div
                                className="card-content-block_header_accordion"
                                onClick={() => setParamsOpen(!paramsOpen)}
                            >
                                <Icon name={paramsOpen ? 'angle down' : 'angle up'} />
                            </div>
                        </div>
                    </div>
                    {paramsOpen && <Form>
                        <Grid>
                            <Grid.Row columns={5}>
                                <Grid.Column>
                                    <Select
                                        isTranslate
                                        name={controlNames.dateType}
                                        value={params.dateType}
                                        source='documentReturnDateType'
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Date
                                        name={controlNames.startDate}
                                        value={params.startDate}
                                        isDisabled={!params.startDate}
                                        onChange={handleChangeParams}
                                        noClearable
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Date
                                        name={controlNames.endDate}
                                        value={params.endDate}
                                        isDisabled={!params.endDate}
                                        onChange={handleChangeParams}
                                        noClearable
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <MultiSelect
                                        name="transportCompanyIds"
                                        source="documentReturnReport/transportCompanies"
                                        isDisabled={filterSettings.transportCompanies && filterSettings.transportCompanies.length && params.transportCompanyIds && params.transportCompanyIds.length}
                                        textValue={(!params.transportCompanyIds || params.transportCompanyIds.length === 0) && t('All')}
                                        value={params.transportCompanyIds}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <MultiSelect
                                        name="shippingIds"
                                        source="documentReturnReport/shippingNumbers"
                                        textValue={(!params.shippingNumbers || params.shippingNumbers.length === 0) && t('All')}
                                        value={params.shippingIds || []}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={5}>
                                <Grid.Column>
                                    <MultiSelect
                                        name="driverIds"
                                        source="documentReturnReport/transportCompanyDrivers"
                                        textValue={(!params.driverNames || params.driverNames.length === 0) && t('All')}
                                        value={params.driverIds}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <MultiSelect
                                        name="vehicleIds"
                                        source="documentReturnReport/vehicles"
                                        textValue={(!params.vehicleNumbers || params.vehicleNumbers.length === 0) && t('All')}
                                        value={params.vehicleIds}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <MultiSelect
                                        isTranslate
                                        name="planers"
                                        value={params.planers}
                                        textValue={(!params.planers || params.planers.length === 0) && t('All')}
                                        source={'documentReturnReport/TripCreatorName'}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                                <Grid.Column className='col-w-2'>
                                    <Button className='report_button' primary disabled={loading}
                                        onClick={generateReport}>
                                        {t('Generate report')}
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>}
                </div>

                <List
                    filters={{ ...params, shippingIds: params.shippingIds || [] }}
                    isReport
                    topBlockHeight={height}
                    nameReport={DOC_RETURN_REPORT_TYPE}
                    trigger={trigger}
                    clickReport={click}
                    actionBtns={btn}
                    cardLink={`/grid/${SHIPPINGS_GRID}/:id#:name`}
                    editableColumns={['transportCompanyComments', 'fmComment', 'potentialPenalty']}
                    isShowActions
                    infinityScroll={false}
                />

            </div>
        </div>
    )
}

export default DocumentReturnReport
