import React from 'react';
import InfoIcon from "./infoIcon";
import DoughnutСhart from "./doughnutСhart";

const StatisticWithDiagram = ({icon, label, labels, positivePercent, counts, onClick}) => {
    return (
        <div onClick={onClick} className='styled-block-stat statistic-diagram'>
            <div className='statistic-diagram__header'>
                <InfoIcon iconName={icon}/>
                <h3>{label}</h3>
            </div>
            <div>
                <DoughnutСhart positivePercent={positivePercent} labels={labels} counts={counts}/>
            </div>
        </div>
    );
};

export default StatisticWithDiagram;