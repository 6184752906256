import React, {useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import {TC_FORM_NO_LOGGED_LINK} from "./links";
import {idNewSelector, regTCRequest} from "../ducks/gridCard";
import {useDispatch, useSelector} from "react-redux";

function RouteReg() {
    const dispatch = useDispatch();
    const idNew = useSelector(state => idNewSelector(state));

    useEffect(()=>{
        dispatch(regTCRequest({isNew: true}));
    }, []);

    return (
        idNew ? <Redirect to={TC_FORM_NO_LOGGED_LINK.replace(':id', idNew)} /> : null
    );
}

export default RouteReg;
