import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Form, Grid, Table, Icon, Segment, Dropdown, Pagination} from 'semantic-ui-react';
import {parseDate} from '../../utils/dateTimeFormater';
import {
    clearFiltersData,
    filterSettingsSelector,
    getDataForReportRequest,
    getReportRequest,
    progressSelector,
    totalCountSelector,
    turnoverInRublesSelector,
    turnoverInShippingsSelector,
} from '../../ducks/reports';
import {useTranslation} from 'react-i18next';
import Block from '../../components/CardLayout/components/block';
import MultiSelectIS from "../../components/BaseComponents/MultiSelectInfinityScroll";
import {PERSONAL_CABINET, PERSONAL_CABINET_TURNOVER} from "../../constants/personalCabinetPages";
import Years from "../../components/BaseComponents/Years";
import {optionsNumberOfTc} from "../../constants/paginationOptions";
import {months} from "../../constants/months";
import {filtersFromSearch} from "../../utils/urlParamsHelper";
import useReactRouter from "use-react-router";
import {toNumber} from "lodash";

const Turnover = ({params: globalParams}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {location} = useReactRouter();

    const initialParams = {
        startDate: new Date().getFullYear() - 1,
        endDate: new Date().getFullYear(),
        transportCompanies: [],
    };

    let [params, setParams] = useState(initialParams);
    let [paramsOpen, setParamsOpen] = useState(true);
    let [click, setClick] = useState(false);

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(optionsNumberOfTc[0].value);

    let [height, setHeight] = useState(0);

    useEffect(() => {
        let el = document.getElementById('params');
        if (el) {
            setHeight(el.getBoundingClientRect().height);
        }
    }, [params, paramsOpen])

    const turnoverInRubles = useSelector(turnoverInRublesSelector);
    const turnoverInShippings = useSelector(turnoverInShippingsSelector);

    const totalCount = useSelector(totalCountSelector);
    const loading = useSelector(progressSelector);

    const loadData = () => {
        getReport();
        document.getElementsByClassName('card-content-block_open')[0].scrollTo(0, 0);
    }

    useEffect(() => {
        if (click) loadData();
    }, [page, count]);


    const mapDataForGenerateReport = () => {
        delete params.trigger;
        return {
            gridName: `${PERSONAL_CABINET}/${PERSONAL_CABINET_TURNOVER}`,
            params: {
                ...params,
                skip: count * (page - 1),
                take: count
            },
        };
    };

    const filterSettings = useSelector(state => filterSettingsSelector(state));

    useEffect(() => {
        dispatch(
            getDataForReportRequest({gridName: `${PERSONAL_CABINET}/${PERSONAL_CABINET_TURNOVER}`}),
        );
        return () => dispatch(clearFiltersData());
    }, []);

    const setFilters = (trigger) => {
        globalParams = {...(globalParams || {}), ...(filtersFromSearch(location) || {})}
        setParams(params => {
            return {
                ...params,
                transportCompanies: globalParams.company ? [JSON.parse(globalParams.company)] : (filterSettings.transportCompanies || []),
                startDate: toNumber(globalParams.startYear) || params.startDate,
                endDate: toNumber(globalParams.endYear) || params.endDate,
                trigger: trigger
            }
        });
    }

    useEffect(() => {
        setFilters();
    }, [filterSettings, globalParams]);

    useEffect(() => {
        setFilters(filtersFromSearch(location).trigger);
    }, [location]);

    useEffect(() => {
        if (params.trigger) generateReport();
    }, [params.trigger]);

    const getReport = () => {
        dispatch(getReportRequest(mapDataForGenerateReport()));
    };

    const handleChangeParams = (e, {name, value}) => {
        const newParams = {
            ...params,
            [name]: value,
        };
        if (name === 'detalizationType' && params.detalizationType !== value) newParams['detalizationTypeValue'] = null;
        setParams(newParams);
    };

    const handleChangeYears = (name, value) => {
        setParams({
            ...params,
            startDate: value.startYear,
            endDate: value.endYear
        });
    }

    const generateReport = () => {
        !click && setClick(true);
        loadData();
    };

    const TableData = ({name, items}) => {
        return <Table className='report_table-data turnover' celled>
            <Table.Header className='personal-cabinet__table_header'>
                <Table.Row>
                    <Table.HeaderCell className='column-facet__first'
                                      colSpan='14'>{t(name)}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {
                    items.map(item => (
                        <>
                            <Table.Row key={item.transportCompanyId} className='turnover__header'>
                                <Table.Cell width={3} className='column-facet__first'>
                                    <b>{item.transportCompanyTitle}</b>
                                </Table.Cell>
                                {
                                    months.map(m => (
                                        <Table.Cell width={1} textAlign='center'>
                                            {t(m)}
                                        </Table.Cell>
                                    ))
                                }
                                <Table.Cell width={1} textAlign='center'>
                                    {t('total')}
                                </Table.Cell>
                            </Table.Row>
                            {
                                item.years && item.years.map(y => (
                                    <Table.Row key={item.transportCompanyId + "_" + y.year}>
                                        <Table.Cell className='column-facet__first'>
                                            {y.year}
                                        </Table.Cell>
                                        {
                                            months.map((m, index) => (
                                                <Table.Cell textAlign='center'>
                                                    {y.valuesByMonths[index + 1]}
                                                </Table.Cell>
                                            ))
                                        }
                                        <Table.Cell textAlign='center'>
                                            {y.total}
                                        </Table.Cell>
                                    </Table.Row>
                                ))
                            }
                        </>
                    ))
                }
            </Table.Body>
        </Table>
    }

    const item = {
        menuItem: PERSONAL_CABINET_TURNOVER,
        render: () => (
            click && <div>
                <TableData name={'turnoverInRubles'} items={turnoverInRubles}/>
                <TableData name={'turnoverInShippings'} items={turnoverInShippings}/>
            </div>
        ),
    };

    const pagination = <Segment className='report__pagination' basic>
        <Form.Field inline>
            <label>{t('numberOfRows')}</label>
            <Dropdown
                className='report__pagination-select'
                options={optionsNumberOfTc}
                selection
                text={count}
                onChange={(e, {value}) => {
                    setCount(value);
                    setPage(1);
                }}
            />
        </Form.Field>
        <Pagination
            activePage={page}
            onPageChange={(e, {activePage}) => setPage(activePage)}
            totalPages={Math.ceil(totalCount / count)}
        />
    </Segment>

    return (
        <div className="container personal-cabinet__page">
            <div className="report">
                <div className="report_params" id='params'>
                    <div className="card-content-block_header">
                        <div className="card-content-block_header_actions">
                            <div
                                className="card-content-block_header_accordion"
                                onClick={() => setParamsOpen(!paramsOpen)}
                            >
                                <Icon name={paramsOpen ? 'angle down' : 'angle up'}/>
                            </div>
                        </div>
                    </div>
                    {paramsOpen && <Form>
                        <Grid>
                            <Grid.Row columns={4}>
                                <Grid.Column>
                                    <MultiSelectIS
                                        isDisabled={filterSettings.transportCompanies && filterSettings.transportCompanies.length}
                                        name="transportCompanies"
                                        source="transportCompanies/all"
                                        textValue={(!params.transportCompanies || params.transportCompanies.length === 0) && t('All')}
                                        value={params.transportCompanies}
                                        onChange={handleChangeParams}
                                        withAll
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Years
                                        startYear={params.startDate}
                                        endYear={params.endDate}
                                        onChange={handleChangeYears}
                                        name='periodYears'
                                    />
                                </Grid.Column>
                                <Grid.Column className=''>
                                    <Button className='report_button' primary
                                            disabled={loading || !(params.startDate && params.endDate && (parseDate(params.startDate) <= parseDate(params.endDate)))}
                                            onClick={generateReport}>
                                        {t('Generate report')}
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>}
                </div>

                <div className={`report_table ${!paramsOpen ? 'report_table_max' : ''}`}>
                    <Block
                        height={height}
                        item={item}
                        loading={loading}
                        footer={click && pagination}
                    />
                </div>
            </div>
        </div>
    );
};

export default Turnover;

