import React, {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {Dropdown, Form, Visibility} from 'semantic-ui-react';
import './style.scss';
import {countSelector, getLookupRequest, listFromMultiSelect} from '../../ducks/lookup';
import {PAGE_SIZE} from "../../constants/settings";

const MultiSelect = ({
    value,
    onChange,
    placeholder = '',
    isDisabled,
    name,
    text,
    loading,
    clearable,
    source,
    isTranslate,
    error,
    textValue,
    noLabel,
    isRequired,
    autoComplete,
    sourceParams,
    isBody
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const context = useRef(null);
    let [searchQuery, setSearchQuery] = useState('');
    let [counter, setCounter] = useState(PAGE_SIZE);

    const [isOpen, setIsOpen] = useState(false);

    const toTop = () => {
        context.current.scrollTop = 0;
        loadData(true);
    }

    useEffect(() => {
        isOpen && toTop();
    }, [searchQuery, sourceParams, isOpen]);

    const loadData = (isNew = true) => {
        setCounter(prevState => {
            dispatch(
                getLookupRequest({
                    isBody,
                    name: source,
                    isForm: false,
                    sourceParams,
                    search: searchQuery,
                    take: PAGE_SIZE,
                    skip: isNew ? 0 : prevState,
                    selectedValues: isNew ? value?.map(v=>v.value && v.value) : []
                }),
            );
            return !isNew ? prevState + PAGE_SIZE : PAGE_SIZE;
        });
    }

    useEffect(() => {
        if (value && value.length) {
            const everyList = value.every(v => !!valuesList.find(vl => vl.value === v.value));
            !everyList && loadData(true);
        }
    }, [value]);

    const valuesList = useSelector(state =>
        isDisabled ? [...value.map(v => ({...v, text: v.name}))] : listFromMultiSelect(state, source, isTranslate, t),
    );
    const count = useSelector(state => countSelector(state, source));

    const handleChange = (e, { value: newValue }) => {
        const values = Array.isArray(newValue) ? valuesList.filter(v=>newValue.includes(v.value)) : [...(value || []), newValue];
        setSearchQuery('');
        onChange(e, { value: values, name });
    };

    const txt = () => {
        if (value && value.find(v=>v.isBlocking)) {
            let val = valuesList.find(v=>v.value === value.find(v=>v.isBlocking).value);
            return val ? val.text : textValue;
        } else return textValue;
    }

    const scroll = () => {
        if (counter < count) loadData(false);
    };

    const handleSearchChange = (e, { searchQuery }) => {
        setSearchQuery(searchQuery);
    };

    const valuesListFilter = (value, valuesList) => {
        const values = (value || []).map(val => val.value);
        return valuesList.filter(v => !values.includes(v.value));
    };

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>{`${t(text || name)}${
                    isRequired ? ' *' : ''
                }`}</label>
            ) : null}
            <div className="form-select">
                <Dropdown
                    placeholder={placeholder}
                    fluid
                    clearable={clearable}
                    selection
                    loading={loading}
                    search
                    text={txt()}
                    error={error}
                    multiple
                    value={value && !value.find(v=>v.isBlocking) ? value.sort(function (a, b) {
                        if (a.name > b.name) return 1;
                        if (a.name < b.name) return -1;
                        return 0;
                    }).map(item => item.value) : []}
                    options={valuesList}
                    onChange={handleChange}
                    selectOnBlur={false}
                    autoComplete={autoComplete}
                    disabled={isDisabled}
                    searchQuery={searchQuery}
                    onSearchChange={handleSearchChange}
                    onClose={() => {
                        toTop();
                        setIsOpen(false);
                    }}
                    onOpen={() => setIsOpen(true)}
                >
                    <div role="listbox" className={`menu transition`} ref={context}>
                        {valuesList && valuesList.length ? (
                            valuesListFilter(value, valuesList).map((item, index) => (
                                <Dropdown.Item
                                    key={item.value + index}
                                    value={item.value}
                                    onClick={(e)=>handleChange(e, {value: item})}
                                >
                                    {item.name}
                                </Dropdown.Item>
                            ))
                        ) : (
                            <div className="message">No results found.</div>
                        )}
                        <Visibility
                            continuous={true}
                            once={false}
                            context={context.current}
                            onTopVisible={scroll}
                        />
                    </div>
                </Dropdown>
            </div>
            {error && typeof error === 'string' && <span className="label-error" dangerouslySetInnerHTML={{ __html: error }} />}
        </Form.Field>  
    );
};
export default React.memo(MultiSelect);