import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Loader, Menu, Segment, Table} from "semantic-ui-react";
import {monthsShortNames} from "../../../constants/months";

const CompanyTable = ({years, yearsData, loading, kpiColor, sortOn}) => {
    const {t} = useTranslation();
    const [selectYear, setSelectYear] = useState(years[0]);

    useEffect(() => {
        !years.includes(selectYear) && setSelectYear(years[0]);
    }, [years]);

    const [sort, setSort] = useState({});

    const SORT_ASC = "asc";
    const SORT_DESC = "desc";

    const columns = ['month', 'shippingsCount', 'kpiLoad', 'kpiUnload', 'kpiAverage', 'kpiDynamics'];

    return (
        <div className='company-stat-table'>
            <Menu compact>
                {
                    years.map(year => (
                        <Menu.Item
                            key={'year-item_'+year}
                            active={selectYear === year}
                            onClick={() => setSelectYear(year)}
                        >
                            {year}
                        </Menu.Item>
                    ))
                }
            </Menu>
            <Segment vertical>
                <Loader active={loading}/>
                <Table singleLine striped>
                    <Table.Header>
                        <Table.Row>
                            {
                                columns.map(col =>
                                    <Table.HeaderCell key={'col_'+col}>
                                        <div
                                            className='company-stat-table__header-cell'
                                            onClick={() => sortOn && setSort({[col]: (sort[col] ? (sort[col] === SORT_ASC ? SORT_DESC : null) : SORT_ASC)})}
                                        >
                                            <div>{t(col)}</div>
                                            {sort[col] && <div className='company-stat-table__sort-btns'>
                                                <div className={sort[col] === SORT_ASC ? 'active' : ''}
                                                     onClick={() => setSort(sort[col] !== SORT_ASC ? {[col]: SORT_ASC} : sort)}/>
                                                <div className={sort[col] === SORT_DESC ? 'active' : ''}
                                                     onClick={() => setSort(sort[col] !== SORT_DESC ? {[col]: SORT_DESC} : sort)}/>
                                            </div>}
                                        </div>
                                    </Table.HeaderCell>
                                )
                            }
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            monthsShortNames.map((month, index) => {
                                const monthData = (yearsData && yearsData[selectYear]) ? yearsData[selectYear].find(m => m.month === index + 1) : null;
                                return <Table.Row key={`${month}_${index}`}>
                                    <Table.Cell>{index < 9 ? 0 : ''}{index + 1}/{selectYear}</Table.Cell>
                                    <Table.Cell>{monthData && monthData.shippingsCount}</Table.Cell>
                                    <Table.Cell>{(monthData && monthData.loadingKPI !== null) && `${monthData.loadingKPI}%`}</Table.Cell>
                                    <Table.Cell>{(monthData && monthData.unloadingKPI !== null) && `${monthData.unloadingKPI}%`}</Table.Cell>
                                    <Table.Cell
                                        className={`statistic-block_${kpiColor(monthData && monthData.meanKPI)}`}>{(monthData && monthData.meanKPI !== null) && `${monthData.meanKPI}%`}</Table.Cell>
                                    <Table.Cell
                                        textAlign='center'
                                        className={monthData && `statistic-block__change-icon statistic-block_${monthData.dynamic > 0 ? 'positive' : (monthData.dynamic < 0 ? 'negative' : 'neutral')}`}>
                                        {(monthData && monthData.dynamic !== null) && `${monthData.dynamic > 0 ? '+' : ''}${monthData.dynamic}%`}
                                    </Table.Cell>
                                </Table.Row>
                            })
                        }
                    </Table.Body>
                </Table>
            </Segment>
        </div>
    );
};

export default CompanyTable;