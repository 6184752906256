import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Dropdown } from 'semantic-ui-react';
import { MULTISELECT_TYPE, TEXT_TYPE } from '../../../constants/columnTypes';
import { useSelector } from 'react-redux';
import { listFromMultiSelect } from '../../../ducks/lookup';

const SelectionField = ({onChange, column, row: rowInit = {}, readOnly=false, valueIsName=true, rowWithoutValues = false }) => {
    const row = (!rowInit || typeof (rowInit) !== 'object') ? {isExcept: false, value: rowInit} : rowInit;
    const isExcept = row.isExcept || false;
    const value = row.value || null;
    const { t } = useTranslation();
    const [isOnly, setIsOnly] = useState(!isExcept);
    const handleClick = (e, { checked }) => {
        setIsOnly(checked);
        onChange({ name: column.name, isExcept: !checked, value: value });
    };

    const handleChange = (e, { value: val }) => {
        onChange({ name: column.name, isExcept: isExcept, value: val });
    };

    const slider = column.type === MULTISELECT_TYPE;

    const valuesList = useSelector(state =>
        listFromMultiSelect(state, column.source, true, t, valueIsName),
    );

    const field = useCallback(() => {
        return (
            <>
                {column.type === TEXT_TYPE ? (
                    <div>{row.value}</div>
                ) : (
                    <Dropdown
                        text={!value || value.length === 0 ? t('All') : ''}
                        fluid
                        multiple
                        selection
                        search
                        options={valuesList}
                        value={value || []}
                        onChange={handleChange}
                        disabled={readOnly}
                    />
                )}
            </>
        );
    }, [row, valuesList]);

    return (
        <div className={`selection-field ${!slider ? 'selection-field_no-select' : ''}`}>
            {
                (!rowWithoutValues && slider) &&
                    <div className="selection-field__slider" style={{visibility: !(!value || value.length === 0) ? 'visible' : 'hidden'}}>
                        <label
                            onClick={()=>setIsOnly(!isOnly)}
                            className={`ui slider checkbox selection-field__label ${!isOnly ?'selection-field__label_opacity': ''}`}>{t('except')}</label>
                        <Checkbox
                            checked={isOnly}
                            onChange={handleClick}
                            slider
                            disabled={readOnly}
                        />
                        <label
                            onClick={()=>setIsOnly(!isOnly)}
                            className={`ui slider checkbox selection-field__label ${isOnly ?'selection-field__label_opacity': ''} selection-field__label_right`}>{t('only')}</label>
                    </div>
            }
            {field()}
        </div>
    );
};

export default SelectionField;
