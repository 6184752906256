import React, {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {Dropdown, Form, Visibility} from 'semantic-ui-react';
import './style.scss';
import { getLookupRequest, listFromMultiSelect, } from '../../ducks/lookup';
import {PAGE_SIZE} from "../../constants/settings";

const MultiSelect = ({
    value,
    onChange,
    placeholder = '',
    isDisabled,
    name,
    text,
    loading,
    clearable,
    source,
    isTranslate,
    error,
    textValue,
    noLabel,
    isRequired,
    autoComplete,
    sourceParams,
    withAll = false
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const context = useRef(null);
    let [searchQuery, setSearchQuery] = useState('');
    let [counter, setCounter] = useState(PAGE_SIZE);
    let [listIsLoaded, setListIsLoaded] = useState(false);

    const toTop = () => {
        context.current.scrollTop = 0;
        setCounter(PAGE_SIZE);
    }

    useEffect(() => {
        toTop();
    }, [searchQuery]);

    const handleOpen = () => {
        source && dispatch(
            getLookupRequest({
                name: source,
                isForm: true,
                sourceParams,
                withAll
            }),
        );
    }

    const valuesList = useSelector(state =>
        listFromMultiSelect(state, source, isTranslate, t),
    );

    const handleChange = (e, { value: newValue }) => {
        const values = Array.isArray(newValue)? valuesList.filter(v=>newValue.includes(v.value))
            : (newValue.isBlocking ? [newValue] : [...(value || []).filter(v => !v.isBlocking), newValue]);
        setSearchQuery('');
        onChange(e, { value: values.length ? values : null, name });
    };

    const txt = () => {
        if (value && value.find(v=>v.isBlocking)) {
            let val = valuesList.find(v=>v.value === value.find(v=>v.isBlocking).value);
            return val ? val.text : textValue;
        } else return textValue;
    }

    const scroll = () => {
        if (counter < valuesList.length) {
            setCounter(prevState => prevState + PAGE_SIZE);
        }
    };

    const handleSearchChange = (e, { searchQuery }) => {
        setSearchQuery(searchQuery);
    };

    const valuesListFilter = (value, valuesList, searchQuery) => {
        const values = value.map(val=>val.value);
        return valuesList.filter(v =>
            ((v.text && v.text.toUpperCase().includes(searchQuery.toUpperCase()))
            || (v.name && v.name.toUpperCase().includes(searchQuery.toUpperCase()))
            || (v.value && v.value.toUpperCase().includes(searchQuery.toUpperCase())))
            && !values.includes(v.value)
        );
    };

    useEffect(()=>{
        if (!listIsLoaded && (((value || []).length && !isDisabled) || isDisabled)) {
            setListIsLoaded(true);
            handleOpen();
        }
    }, [isDisabled, (value || []).length]);

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>{`${t(text || name)}${
                    isRequired ? ' *' : ''
                }`}</label>
            ) : null}
            <div className="form-select">
                <Dropdown
                    placeholder={placeholder}
                    fluid
                    clearable={clearable || !!(value && value.find(v=>v.isBlocking))}
                    selection
                    loading={loading}
                    search
                    text={txt()}
                    error={error}
                    multiple
                    value={value && !value.find(v=>v.isBlocking) ? value.map(item => item.value) : ['']}
                    options={valuesList}
                    onChange={handleChange}
                    selectOnBlur={false}
                    autoComplete={autoComplete}
                    disabled={isDisabled}
                    searchQuery={searchQuery}
                    onSearchChange={handleSearchChange}
                    onClose={toTop}
                    onOpen={handleOpen}
                >
                    <div role="listbox" className={`menu transition`} ref={context}>
                        {valuesList && valuesList.length ? (
                            valuesListFilter(value || [], valuesList, searchQuery).slice(0, counter).map(item => (
                                <Dropdown.Item
                                    key={item.value}
                                    value={item.value}
                                    onClick={(e)=>handleChange(e, {value: item})}
                                >
                                    {item.name}
                                </Dropdown.Item>
                            ))
                        ) : (
                            <div className="message">No results found.</div>
                        )}
                        <Visibility
                            continuous={false}
                            once={false}
                            context={context.current}
                            onTopVisible={scroll}
                        />
                    </div>
                </Dropdown>
            </div>
            {error && typeof error === 'string' && <span className="label-error" dangerouslySetInnerHTML={{ __html: error }} />}
        </Form.Field>
    );
};
export default React.memo(MultiSelect);