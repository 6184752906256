import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Form, Grid, Icon, Segment, Table} from 'semantic-ui-react';
import FormField from "../formField";
import {SETTINGS_TYPE_EDIT} from "../../../../constants/formTypes";

const VehicleTable = ({form = {}, onChange, settings = {}, error, columnsConfig, nameInForm, isRented=false}) => {
    const {t} = useTranslation();
    const [rowsData, setRowsData] = useState((form[nameInForm] && form[nameInForm].length) ? form[nameInForm] : [{isRented: isRented}]);

    useEffect(()=>{
        setRowsData((form[nameInForm] && form[nameInForm].length) ? form[nameInForm] : [{isRented: isRented}]);
    }, [form]);

    const newRow = () => {
        onChange(null, {name: nameInForm, value: [...rowsData, {}]});
    }

    const disabledButtons = settings.vehicleTypeId !== SETTINGS_TYPE_EDIT || settings.vehicleCount !== SETTINGS_TYPE_EDIT;

    const onChangeForm = useCallback((e, {name, value}) => {
        let arr = [];
        value.forEach((v, index) => {
            if(!v.vehicleTypeId && !v.vehicleCount) arr.push(index);
        });
        arr.forEach(i=>value.splice(i, 1, {}));
        onChange(e, {name, value});
    }, []);

    const onChangeField = (id, {name, value}) => {
        let rowsNew = [...rowsData]
        rowsNew[id][name] = value;
        rowsNew[id]['isRented'] = isRented;
        onChangeForm(null, {name: nameInForm, value: rowsNew});
    }

    const deleteRow = (id) => {
        const rowsNew = [...rowsData];
        rowsNew.splice(id, 1)
        onChange(null, {name: nameInForm, value: rowsNew});
    }

    const rows = () => {
        return rowsData.map((row, index) => (
            <Table.Row key={index}>
                <Table.Cell style={{minWidth: '200px'}}>
                    <FormField
                        name="vehicleTypeId"
                        columnsConfig={columnsConfig}
                        settings={settings}
                        form={row}
                        error={error}
                        onChange={(e, {name, value})=>onChangeField(index, {name, value})}
                    />
                </Table.Cell>
                <Table.Cell>
                    <FormField
                        name="vehicleCount"
                        columnsConfig={columnsConfig}
                        settings={settings}
                        form={row}
                        error={error}
                        onChange={(e, {name, value})=>onChangeField(index, {name, value})}
                    />
                </Table.Cell>
                <Table.Cell>
                    <Button icon onClick={newRow} disabled={disabledButtons}>
                        <Icon name='plus'/>
                    </Button>
                    <Button icon onClick={()=>deleteRow(index)} disabled={rowsData.length === 1 || disabledButtons}>
                        <Icon name='minus'/>
                    </Button>
                </Table.Cell>
            </Table.Row>
        ))
    }

    return (
        <Table singleLine>
            <Table.Body>
                {rows()}
            </Table.Body>
        </Table>
    );
};

export default VehicleTable;
