import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {Dimmer, Icon, List, Loader, Segment} from "semantic-ui-react";
import {useDispatch, useSelector} from "react-redux";
import {clearLinks, getLinks, linksSelector, progressSelector} from "../../ducks/personalCabinet";

const LinksBlock = ({type, name, icon = 'info', color}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const links = useSelector(linksSelector);
    const loading = useSelector(progressSelector);

    useEffect(() => {
        dispatch(getLinks({type}));
        return () => dispatch(clearLinks());
    }, [type]);

    return (
        <div className="container personal-cabinet__page">
            <h2>{t(name)}</h2>
            <div className={`links-block ${color}`}>
                <div className='links-block__data links-block__icon'>
                    <Icon size='big' name={icon}/>
                </div>
                <div className='links-block__data links-block__links'>
                    <List>
                        {
                            links.length ? links.map(link => (
                                <List.Item target="_blank" href={link.link}>
                                    {link.name}
                                </List.Item>
                            )) : <Loader active={loading} inline='centered' />
                        }
                    </List>

                </div>
            </div>
        </div>
    );
};

export default LinksBlock;