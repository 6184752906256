import React, {useEffect, useState} from 'react';
import './styles.scss';
import {useTranslation} from "react-i18next";
import Years from "../../components/BaseComponents/Years";
import {Dimmer, Grid, Loader, Segment} from "semantic-ui-react";
import CompanyStatistics from "./components/companyStatistics";
import StatisticBlock from "./components/statisticBlock";
import StatisticWithDiagram from "./components/statisticWithDiagram";
import Graph from "./components/graph";
import CompanyTable from "./components/table";
import {useDispatch, useSelector} from "react-redux";
import {
    clearDefaultParams,
    companyRankLoadSelector,
    companyRankSelector,
    companyStatisticsLoadSelector,
    companyStatisticsSelector,
    companyYearsStatLoadSelector,
    companyYearsStatSelector,
    defaultParamsSelector,
    getCompanyStats,
    getDefaultParams,
    getRankCompany,
    getYearsCompanyStats
} from "../../ducks/personalCabinet";
import {formatWithCommas, formatWithSpaces, isNumber} from "../../utils/numbersFormat";
import Period from "../../components/BaseComponents/Period";
import {dateToString} from "../../utils/dateTimeFormater";
import {SHIPPINGS_GRID} from "../../constants/grids";
import {GRID_LIST_LINK, PERSONAL_CABINET_LINK, REPORT_LINK} from "../../router/links";
import {PERSONAL_CABINET_KPI, PERSONAL_CABINET_TURNOVER} from "../../constants/personalCabinetPages";
import {DOC_RETURN_REPORT_TYPE} from "../../constants/reportType";
import qs from "qs";

const docReturnDeliveryDateType = 'deliveryDate'

const PersonalCabinetHome = ({setParams: setGlobalParams, params: globalParams}) => {
    const {t} = useTranslation();

    const statPeriod = (start, end) => {
        return `${start || dateToString().replace(/.{2}/, '01')} - ${end || dateToString()}`;
    }

    const [defaultParamIsLoaded, setDefaultParamIsLoaded] = useState(false);

    const [params, setParams] = useState({
        startDate: globalParams.startYear ? globalParams.startYear : new Date().getFullYear() - 1,
        endDate: globalParams.endYear ? globalParams.endYear : new Date().getFullYear(),
        company: globalParams.company ? JSON.parse(globalParams.company) : null,
        statPeriod: statPeriod(globalParams.startDate, globalParams.endDate),
    });

    const getStringItem = (i) => {
        const parts = (params.statPeriod || "").split(" - ");
        return (parts[i] || null);
    };

    const paramsToGlobal = () => {
        return {
            ...params,
            company: params.company && JSON.stringify(params.company),
            startYear: params.startDate,
            endYear: params.endDate,
            startDate: getStringItem(0),
            endDate: getStringItem(1)
        }
    }

    useEffect(() => {
        setGlobalParams(paramsToGlobal());
    }, [params]);


    const handleChangeYears = (name, value) => {
        setParams({
            ...params,
            startDate: value.startYear,
            endDate: value.endYear
        });
    }

    const handleChange = (e, {name, value}) => {
        setParams({
            ...params,
            [name]: value
        });
    }

    const dispatch = useDispatch();

    const yearsData = useSelector(companyYearsStatSelector);
    const yearsDataLoading = useSelector(companyYearsStatLoadSelector);

    const statisticsData = useSelector(companyStatisticsSelector);
    const statisticsDataLoading = useSelector(companyStatisticsLoadSelector);

    const rank = useSelector(companyRankSelector);
    const rankLoading = useSelector(companyRankLoadSelector);

    const defaultParams = useSelector(defaultParamsSelector);

    const loadStats = (payload) => {
        dispatch(getCompanyStats(payload));
        dispatch(getRankCompany(payload));
    };

    useEffect(() => {
        dispatch(getDefaultParams());
        return () => dispatch(clearDefaultParams());
    }, []);

    useEffect(() => {
        if (defaultParams) {
            defaultParams.transportCompany && handleChange(null, {name: 'company', value: defaultParams.transportCompany});
            setDefaultParamIsLoaded(true);
        }
    }, [defaultParams]);

    useEffect(() => {
        const payload = {
            period: params.statPeriod,
            transportCompanyId: params.company ? params.company.value : null
        };
        defaultParamIsLoaded && loadStats(payload);
    }, [params.statPeriod, params.company, defaultParamIsLoaded]);

    useEffect(() => {
        defaultParamIsLoaded && dispatch(getYearsCompanyStats({
            period: `01.01.${params.startDate} - 31.12.${params.endDate}`,
            transportCompanyId: params.company ? params.company.value : null
        }));
    }, [params.startDate, params.endDate, params.company, defaultParamIsLoaded]);

    const labels = [t('shippingsArrivalOnTime'), t('shippingsLate')];

    const kpiColor = x => {
        return x <= 85 ? 'negative' : (x >= 98 ? 'positive' : 'average');
    }

    const onClick = (page, filterNames) => {
        const filterData = {
            ...paramsToGlobal(),
            firstLoadingDateTime: params.statPeriod.replaceAll(' ', ''),
            transportCompanyId: (params.company || {}).value,
            status: defaultParams.statuses,
            documentsDeliveryStatus: defaultParams.documentsDeliveryStatus,
            company: params.company && JSON.stringify(params.company),
            trigger: true,
            potentialPenalty: true,
            clearOldFilters: true,
            dateType: docReturnDeliveryDateType
        }
        let filters = {};
        filterNames ? filterNames.forEach(f => filters[f] = filterData[f]) : filters = filterData;
        const data = `${page}?${qs.stringify(filters)}`;
        window.open(data, '_blank');
    }

    const openKpi = () => onClick(PERSONAL_CABINET_LINK.replace(':pageName', PERSONAL_CABINET_KPI), [
        'startDate',
        'endDate',
        'company',
        'trigger'
    ]);

    const openDocReturn = (potentialPenalty) => {
        let filtersNames = [
            'dateType',
            'startDate',
            'endDate',
            'trigger',
            'company',
            'clearOldFilters'
        ]
        potentialPenalty && filtersNames.push('potentialPenalty');
        return () => onClick(REPORT_LINK.replace(':type', DOC_RETURN_REPORT_TYPE), filtersNames)
    }

    const openShippingsGrid = () => onClick(GRID_LIST_LINK.replace(':name', SHIPPINGS_GRID), [
        'status',
        'documentsDeliveryStatus',
        'transportCompanyId',
        'firstLoadingDateTime',
        'clearOldFilters'
    ])

    const openTurnover = () => onClick(PERSONAL_CABINET_LINK.replace(':pageName', PERSONAL_CABINET_TURNOVER), [
        'startYear',
        'endYear',
        'company',
        'trigger'
    ])

    return (
        <div className="container personal-cabinet__page personal-cabinet__page_home">
            <Grid>
                <Grid.Row>
                    <Grid.Column className='personal-cabinet__block' width={9}>
                        <h1>{t('personalCabinet')}</h1>
                        <CompanyStatistics
                            loading={statisticsDataLoading}
                            rankLoading={rankLoading}
                            statData={statisticsData}
                            rank={rank}
                            handleChange={handleChange}
                            company={params.company}
                            disabled={defaultParamIsLoaded && defaultParams && defaultParams.transportCompany}
                        />

                        <div className='more-company-statistics'>
                            <div className='more-company-statistics__header'>
                                <h2>{t('companyStatistics')}</h2>
                                <div>
                                    <label>{t('selectPeriod')}:</label>
                                    <Period
                                        noLabel
                                        name={'statPeriod'}
                                        onChange={handleChange}
                                        value={params.statPeriod}
                                        setBtn
                                    />
                                </div>
                            </div>
                            <Segment vertical>
                                <Dimmer inverted active={statisticsDataLoading}>
                                    <Loader/>
                                </Dimmer>
                                <div className='stat-data-blocks'>
                                    <StatisticBlock
                                        label={t('shippings')}
                                        icon='vehicle'
                                        value={formatWithSpaces(statisticsData.shippingsCount)}
                                        onClick={openShippingsGrid}
                                    />

                                    <StatisticBlock
                                        label={t('averageKpi')}
                                        icon='kpi'
                                        value={isNumber(statisticsData.meanKPI) && statisticsData.meanKPI + '%'}
                                        gradeOfValue={kpiColor(statisticsData.meanKPI)}
                                        onClick={openKpi}
                                    />

                                    <StatisticBlock
                                        label={t('turnover')}
                                        unit={t('mln-rub')}
                                        icon='turnover'
                                        value={isNumber(statisticsData.turnover) && formatWithCommas(statisticsData.turnover)}
                                        onClick={openTurnover}
                                    />
                                </div>
                                <div className='stat-data-blocks'>
                                    <StatisticWithDiagram
                                        icon='time-in'
                                        label={t('loadingKpi')}
                                        labels={labels}
                                        positivePercent={statisticsData.loadingKPI || 0}
                                        counts={[statisticsData.loadingAtTime || 0, statisticsData.loadingLates || 0]}
                                        onClick={openKpi}
                                    />
                                    <StatisticWithDiagram
                                        icon='time-out'
                                        label={t('unloadingKpi')}
                                        labels={labels}
                                        positivePercent={statisticsData.unloadingKPI || 0}
                                        counts={[statisticsData.unloadingAtTime || 0, statisticsData.unloadingLates || 0]}
                                        onClick={openKpi}
                                    />
                                </div>
                            </Segment>
                        </div>
                    </Grid.Column>
                    <Grid.Column className='personal-cabinet__block' width={7}>
                        <div className='more-company-statistics__header select-years'>
                            <div>
                                <label>{t('selectPeriodOrYears')}:</label>
                                <Years
                                    setBtn
                                    startYear={params.startDate}
                                    endYear={params.endDate}
                                    onChange={handleChangeYears}
                                />
                            </div>
                        </div>
                        <div>
                            <Graph loading={yearsDataLoading}
                                   yearsData={(yearsData && yearsData.fromYear && yearsData.toYear) && yearsData}/>
                        </div>
                        <CompanyTable
                            loading={yearsDataLoading}
                            years={[params.startDate, params.endDate]}
                            yearsData={yearsData && yearsData.years}
                            kpiColor={kpiColor}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
};

export default PersonalCabinetHome;
