import React from 'react';
import Icon from "../../../components/CustomIcon";
import {Loader, Popup} from "semantic-ui-react";

const InfoIcon = ({iconName, infoName, infoPopupData, loading}) => {

    const trigger = <div className='info-icon'>
        <div className='info-icon__circle'>
            <Icon name={iconName} size={24}/>
        </div>
        {(infoName || loading) && <div className='info-icon__info-name'>{loading ? <Loader active inline size={'mini'}/> : infoName}</div>}
    </div>

    const content = infoPopupData && <div className='info-icon__popup'>
        <div className='popup-label'>{infoPopupData.name}</div>
        {infoPopupData.stats && <div className='popup-data'>
            {
                infoPopupData.stats.map((st, index) => <div key={st.name+index} className={st.color ? `color_${st.color}` : ''}>{st.name}<div/>{st.value}</div>)
            }
        </div>}
    </div>

    return (
        infoPopupData ? <Popup
            className='personal-cabinet__popup_info-icon'
            content={content}
            position='bottom left'
            size='mini'
            trigger={trigger}
        /> : trigger
    );
};

export default InfoIcon;