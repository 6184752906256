import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Form, Icon, Input} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

const Years = ({
                   setBtn = false,
                   startYear,
                   endYear,
                   onChange,
                   name,
                   isDisabled,
                   isRequired
               }) => {
    const {t} = useTranslation();

    const START_YEAR = 'startYear';
    const END_YEAR = 'endYear';

    const [year, setYear] = useState(2000);
    const [isOpen, setIsOpen] = useState(false);
    const [yearType, setYearType] = useState(START_YEAR);
    const [value, setValue] = useState({});

    const trigger = <Input
        readOnly
        onClick={() => setIsOpen(true)}
        value={`${value.startYear || '----'} - ${value.endYear || '----'}`}
    />

    const yearOnOpen = () => {
        let year = 2000;
        for (let st = year; st <= startYear; st += 12) {
            if (startYear < st + 12) {
                setYear(st);
                return null;
            }
        }
        return year;
    }

    useEffect(() => {
        setValue({
            startYear,
            endYear
        })
    }, [startYear, endYear, isOpen]);

    const handleYear = (i) => {
        switch (i) {
            case value.startYear:
                setYearType(START_YEAR);
                return;
            case value.endYear:
                setYearType(END_YEAR);
                return;
            default:
                handleChange(yearType === START_YEAR ? START_YEAR : END_YEAR, i);
                return;
        }
    }

    const handleChange = (nameValue, val) => {
        let valueData = {
            startYear: nameValue === START_YEAR ? val : value.startYear,
            endYear: nameValue === END_YEAR ? val : value.endYear
        }
        !setBtn ? onChange(name, valueData) : setValue(valueData);
    }

    const years = () => {
        let array = [];
        for (let i = year; i < year + 12; i++) {
            array.push(<Button
                key={'year_'+i}
                disabled={(yearType === END_YEAR && i < value.startYear) || (yearType === START_YEAR && i > value.endYear)}
                onClick={() => handleYear(i)}
                id='years__btn'
                className={`years__btn ${value.startYear === i ? 'years__btn_start' : ''} ${value.endYear === i ? 'years__btn_end' : ''}`}>
                {i}
            </Button>)
        }
        return array;
    }

    return (
        <Form.Field>
            <label className={isDisabled ? 'label-disabled' : null}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `${t(name)}${isRequired ? ' *' : ''}`,
                        }}
                    />
            </label>
            <div className={`form-select ${isDisabled ? 'form-select_disabled' : ''}`}>
                <Dropdown
                    icon={null}
                    fluid
                    trigger={trigger}
                    onFocus={yearOnOpen}
                    onBlur={() => setIsOpen(false)}
                    open={isOpen}
                    openOnFocus
                >
                    <Dropdown.Menu className='years__menu'>
                        <div className='years__btns'>
                            <Icon
                                name='left chevron'
                                disabled={(yearType === END_YEAR && year <= value.startYear)}
                                onClick={() => setYear(year - 12)}/>
                            <div>{`${year} - ${year + 11}`}</div>
                            <Icon
                                name='right chevron'
                                disabled={(yearType === START_YEAR && year + 12 > value.endYear)}
                                onClick={() => setYear(year + 12)}/>
                        </div>
                        <div className='years__select-block'>
                            {
                                years()
                            }
                        </div>
                        <div className='years__select-block'>
                            {setBtn && <Button
                                className='years__select-btn'
                                onClick={() => {
                                    onChange(name, value);
                                    setIsOpen(false);
                                }}>
                                {t('applyBtn')}
                            </Button>}
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </Form.Field>
    );
};

export default Years;