import React from 'react';
import {DICTIONARY_CARD_LINK} from "../../../router/links";
import {Button} from "semantic-ui-react";

const LinkToCardBtn = ({dictionaryName, val}) => {
    const onClick = () => {
        window.open(DICTIONARY_CARD_LINK.replace(':name', dictionaryName).replace(':id', val.value), '_blank')
    }

    return <Button
        className='margin-right-10'
        disabled={!val}
        icon='share'
        onClick={onClick}
    />
}

export default LinkToCardBtn;