import React from 'react';
import OperationalReport from "./operationalReport";
import {
    DOC_RETURN_REPORT_TYPE,
    OPERATIONAL_REPORT_TYPE,
    REGISTRIES_FOR_PAYMENT_TYPE,
    TRADING_REPORT_TYPE
} from "../../constants/reportType";
import DocumentReturnReport from "./documentReturnReport";
import RegistriesForPaymentReport from "./registriesForPaymentReport";
import Report from "./report";
import TradingReport from "./tenderReport";

const Reports = (props) => {
    const {match} = props;
    const name = (match.params || {}).type;

    const getReport = () => {
        switch (name) {
            case OPERATIONAL_REPORT_TYPE:
                return <OperationalReport/>;
            case DOC_RETURN_REPORT_TYPE:
                return <DocumentReturnReport/>;
            case REGISTRIES_FOR_PAYMENT_TYPE:
                return <RegistriesForPaymentReport/>;
            case TRADING_REPORT_TYPE:
                return <TradingReport/>;
            default:
                return <Report
                    name={name}
                    initialParams={{}}
                    filterFields={[]}
                />
        }
    }

    return getReport();
};

export default Reports;