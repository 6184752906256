import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Dropdown, Form} from 'semantic-ui-react';
import './style.scss';

const mapStringToArray = str => {
    return str.split(/[\s,;]+/).map(i => i.trim()).filter(i => i)
};

const AdditionalMultiSelect = ({
                                   value: valueStr = '',
                                   onChange,
                                   placeholder = '',
                                   isDisabled,
                                   name,
                                   text,
                                   loading,
                                   clearable,
                                   source,
                                   isTranslate,
                                   error,
                                   textValue,
                                   noLabel,
                                   isRequired,
                                   autoComplete,
                                   children,
                               }) => {
    const {t} = useTranslation();
    let [valuesList, setValuesList] = useState([]);
    let [value, setValue] = useState([]);

    useEffect(() => {
        setValue(valueStr ? mapStringToArray(valueStr) : []);
    }, [valueStr]);

    useEffect(() => {
        setValuesList((value || []).map(item => ({
            value: item,
            text: item,
        })))
    }, [value]);

    const handleChange = (e, {value}) => {
        onChange(e, {value: value.join(','), name});
    };

    const handleAddition = (e, {value: newValue}) => {
        const values = mapStringToArray(newValue);
        handleChange(e, {value: Array.from(new Set([...(value || []), ...values]))});
    };

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>{`${t(text || name)}${
                    isRequired ? ' *' : ''
                    }`}</label>
            ) : null}
            <div className="form-select">
                <Dropdown
                    placeholder={placeholder}
                    fluid
                    clearable={clearable}
                    selection
                    loading={loading}
                    search
                    text={textValue}
                    error={error}
                    multiple
                    value={value}
                    options={valuesList}
                    allowAdditions
                    onAddItem={handleAddition}
                    onChange={handleChange}
                    selectOnBlur={false}
                    autoComplete={autoComplete}
                    disabled={isDisabled}
                />
                {children && children}
            </div>
            {error && typeof error === 'string' && <span className="label-error">{error}</span>}
        </Form.Field>
    );
};
export default React.memo(AdditionalMultiSelect);
