import React from 'react'
import { Form } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import { formatDate, parseDate } from '../../utils/dateTimeFormater'
import { useTranslation } from 'react-i18next'
import InputMask from 'react-input-mask'

const Date = ({
    value,
    name,
    onChange,
    isDisabled,
    noLabel,
    text,
    placeholder,
    className,
    isRequired,
    noClearable = false,
    error
}) => {
    const getClassNames = () => {
        const classNames = []

        if (error) {
            classNames.push('input-error')
        }

        if (className) {
            classNames.push(className)
        }

        return classNames.join(' ')
    }

    const { t } = useTranslation()

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span dangerouslySetInnerHTML={{ __html: `${t(text || name)}${isRequired ? ' *' : ''}` }} />
                </label>
            ) : null}
            <DatePicker
                disabled={isDisabled || false}
                placeholderText={placeholder || t("dateInputPlaceholder")}
                className={getClassNames()}
                isClearable={!(isDisabled || false) && !noClearable}
                selected={parseDate((value || '').substring(0, 10))}
                locale={localStorage.getItem('i18nextLng')}
                dateFormat="dd.MM.yyyy"
                onChange={(date, e) => {
                    onChange(e, { name: name, value: date ? formatDate(date) : null })
                }}
                type="text"
                customInput={
                    <InputMask mask="99.99.9999" />
                }
            />
            {error && typeof error === 'string' ? (
                <span className="label-error" dangerouslySetInnerHTML={{ __html: error }} />
            ) : null}
        </Form.Field>
    )
}
export default Date
