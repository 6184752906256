import React, { useEffect, useState, useRef } from 'react'
import {
    Button, Icon, Popup,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
    Grid,
    Loader
} from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import {
    getTemplatesListRequest,
    restoreLoaderSelector,
    restoreTemplatesRequest,
    templateSelector,
    uploadLoaderSelector,
    uploadTemplatesRequest,
    progressSelector
} from '../../ducks/reports'
import { downloadDocumentRequest } from '../../ducks/documents'
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import './style.scss'

const AdminTemplates = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const fileUploader = useRef(null)

    const [rows, setRows] = useState([])
    const [currentType, setCurrentType] = useState(null)

    const loading = useSelector(progressSelector)
    const templates = useSelector(templateSelector)
    const uploadLoader = useSelector(uploadLoaderSelector)
    const restoreLoader = useSelector(restoreLoaderSelector)

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        dispatch(getTemplatesListRequest())
    }

    useEffect(() => {
        setRows(templates)
    }, [templates])

    const handleDownload = id => {
        dispatch(downloadDocumentRequest({
            id
        }))
    }

    const onFilePicked = e => {
        const file = e.target.files[0]
        if (file && file.size <= 26214400) {
            const data = new FormData()
            data.append('formFile', file)

            dispatch(
                uploadTemplatesRequest({
                    form: data,
                    callbackSuccess: loadData,
                    reportType: currentType,
                }),
            )
            setCurrentType(null)
        } else if (file.size > 26214400) {
            toast.error(t('error_file_size'))
        }
        fileUploader.current.value = null
    }

    const handleRestore = id => {
        dispatch(restoreTemplatesRequest({ id, callbackSuccess: loadData }))
    }

    return (
        <div className="wider ui container container-margin-top-bottom">
            <Loader active={loading} size="huge" className="table-loader">
                Loading
            </Loader>
            <Grid className='table-header-menu'>
                <Grid.Row>
                    <Grid.Column width={5} verticalAlign="middle">
                        <span className="table-header-menu_title">{t('Template Excel')}</span>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <div className="scroll-table-container">
                <Table
                    className='grid-table'
                    celled
                    selectable>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell className='column-facet__first'>{t('Report name')}</TableHeaderCell>
                            <TableHeaderCell>{t('previousTemplate')}</TableHeaderCell>
                            <TableHeaderCell>{t('actualTemplate')}</TableHeaderCell>
                            <TableHeaderCell>{t('description')}</TableHeaderCell>
                            <TableHeaderCell className='cell-template-actions' />
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {rows &&
                            rows.map(row => (
                                <TableRow key={row.reportType}>
                                    <TableCell className='column-facet__first'>{row.reportName}</TableCell>
                                    <TableCell>
                                        {row.previousGenericReportTemplateId && (
                                            <span
                                                className="template_link"
                                                onClick={() =>
                                                    handleDownload(row.previousGenericReportTemplateId)
                                                }
                                            >
                                                {t('downloadFileTemplate')}
                                            </span>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {row.currentGenericReportTemplateId && (
                                            <span
                                                className="template_link"
                                                onClick={() =>
                                                    handleDownload(row.currentGenericReportTemplateId)
                                                }
                                            >
                                                {t('downloadFileTemplate')}
                                            </span>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {row.description}
                                    </TableCell>
                                    <TableCell>
                                        <Popup
                                            content={t('Upload new')}
                                            trigger={<Button
                                                icon
                                                loading={uploadLoader}
                                                onClick={() => {
                                                    setCurrentType(row.reportType)
                                                    fileUploader && fileUploader.current.click()
                                                }}
                                            >
                                                <Icon name="upload" />
                                            </Button>}
                                        />
                                        <Popup
                                            content={t('restorePreviousTemplate')}
                                            trigger={<Button icon loading={restoreLoader} disabled={!row.previousGenericReportTemplateId} onClick={() => handleRestore(row.reportType)}>
                                                <Icon name="undo" />
                                            </Button>}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <input
                    type="file"
                    ref={fileUploader}
                    style={{ display: 'none' }}
                    onChange={onFilePicked}
                />
            </div>
        </div>
    )
}

export default AdminTemplates
