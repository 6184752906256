import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Form, Modal} from "semantic-ui-react";
import FormField from "../BaseComponents";
import './style.scss';

const RequiredFieldsModal = ({title, text, open = false, error, onClose, id, fields = [], saveRequest, closeIcon = false, saveBtnName, loading, oldForm, btns}) => {
    const {t} = useTranslation();

    const [form, setForm] = useState({});

    const onChange = (e, {name, value}) => {
        setForm(form => ({
            ...form,
            [name] : value
        }));
    };

    const save = () => {
        saveRequest({form, id, callbackSuccess: onClose});
    }

    useEffect(() => {
        if (open) {
            oldForm && setForm({...oldForm})
        } else setForm({})
    }, [open]);

    const requiredValuesIsNoEmpty = fields.filter(f => f.isRequired).every(f => !!form[f.name]);

    return (
        <Modal
            open={open}
            closeIcon={closeIcon}
            size={'small'}
            className={'required-fields-modal'}
        >
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                <p>{text}</p>
                <Form style={{textAlign: 'center'}}>
                    {
                        fields.map(field => (
                            <Form.Group key={field.name}>
                                <FormField
                                    text={t(field.text || field.name)}
                                    name={field.name}
                                    value={form[field.name]}
                                    error={error[field.name]}
                                    type={field.type}
                                    onChange={onChange}
                                    source={field.source}
                                    isRequired={field.isRequired}
                                />
                            </Form.Group>
                        ))
                    }
                </Form>
            </Modal.Content>
            <Modal.Actions>
                {btns && btns.map(b => b)}
                <Button loading={loading} disabled={loading || !requiredValuesIsNoEmpty} primary onClick={save}>{saveBtnName || t('SaveButton')}</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default RequiredFieldsModal;
