import {Button, Grid, Icon} from "semantic-ui-react";
import FormField from "./formField";
import LinkToCardBtn from "./linkToCardBtn";
import {SETTINGS_TYPE_HIDE, SETTINGS_TYPE_SHOW} from "../../../constants/formTypes";
import {DICTIONARY_NEW_LINK} from "../../../router/links";
import React from "react";
import useReactRouter from "use-react-router";
import {useSelector} from "react-redux";
import {canCreateByFormSelector} from "../../../ducks/dictionaryView";

export const FieldWithCreate = ({
                                    name,
                                    dictionaryName,
                                    linkToCardValueName,
                                    columnsConfig,
                                    settings,
                                    form,
                                    error,
                                    onChange
                                }) => {
    const {history, location} = useReactRouter();
    const canCreate = useSelector(state => canCreateByFormSelector(state, dictionaryName))

    return <Grid className='field-with-create'>
        <Grid.Row textAlign="bottom">
            <Grid.Column width={14}>
                <FormField
                    name={name}
                    columnsConfig={columnsConfig}
                    settings={settings}
                    form={form}
                    error={error}
                    onChange={onChange}
                />
            </Grid.Column>
            <Grid.Column width={2} textAlign="right">
                <div className='d-flex'>
                    <LinkToCardBtn
                        dictionaryName={dictionaryName}
                        val={form[linkToCardValueName || name]}
                    />
                    <Button
                        icon
                        disabled={
                            !canCreate ||
                            settings[name] === SETTINGS_TYPE_SHOW ||
                            settings[name] === SETTINGS_TYPE_HIDE ||
                            columnsConfig[name].isReadOnly
                        }
                        onClick={() => {
                            history.push({
                                pathname: DICTIONARY_NEW_LINK.replace(
                                    ':name',
                                    dictionaryName,
                                ),
                                state: {
                                    pathname: location.pathname,
                                    form,
                                    name,
                                },
                            });
                        }}
                    >
                        <Icon name="add"></Icon>
                    </Button>
                </div>
            </Grid.Column>
        </Grid.Row>
    </Grid>
}
