import React, { useEffect } from 'react';
import { history } from '../../src/store/configureStore';
import { ConnectedRouter } from 'connected-react-router';
import MainRoute from '../router';
import Header from '../components/Header';

import { useDispatch, useSelector } from 'react-redux';

import 'semantic-ui-css/semantic.min.css';
import 'react-datepicker/dist/react-datepicker.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-resizable/css/styles.css';
import '../style/main.scss';
import ToastPortalContainer from '../components/ToastContainer';
import {
    getUserProfile,
    userNameSelector
} from '../ducks/profile';
import {
    isAuthSelector,
    liabilityInsurancePolicyShow,
    liabilityInsurancePolicyViewSelector
} from '../ducks/login';
import {Confirm, Dimmer, Loader} from 'semantic-ui-react';
import ModalInfo from "../components/ModalInfo";
import AuctionRulesModal from "./auctionRules";
import {DICTIONARY_CARD_LINK} from "../router/links";
import {useTranslation} from "react-i18next";

const App = () => {
    const dispatch = useDispatch();
    const userName = useSelector(state => userNameSelector(state));
    const isAuth = useSelector(state => isAuthSelector(state));
    const insurancePolicyReminder = useSelector(state => liabilityInsurancePolicyViewSelector(state));

    const getProfile = () => {
        if (!userName) {
            dispatch(getUserProfile());
        }
    };

    useEffect(getProfile, []);

    const { t } = useTranslation();

    return (
        <>
            <ConnectedRouter history={history}>
                {userName || !isAuth ? (
                    <>
                        <Header />
                        <MainRoute />
                    </>
                ) : (
                    <Dimmer active inverted>
                        <Loader size="huge">Loading</Loader>
                    </Dimmer>
                )}
            </ConnectedRouter>
            <ToastPortalContainer />
            <ModalInfo />
            <AuctionRulesModal />
            <Confirm
                open={insurancePolicyReminder}
                header={t('liability-insurance-policy-modal-title')}
                content={t('liability-insurance-policy-modal-content')}
                cancelButton={t('enterLater')}
                confirmButton={t('addPolicy')}
                onCancel={()=>dispatch(liabilityInsurancePolicyShow(false))}
                onConfirm={()=>{
                    dispatch(liabilityInsurancePolicyShow(false));
                    history.push(`${DICTIONARY_CARD_LINK.replace(':name', 'transportCompanies').replace(':id', insurancePolicyReminder)}`);
                }}
            />
        </>
    );
};

export default App;
