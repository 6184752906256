import React from 'react';
import {Modal} from "semantic-ui-react";
import CustomDictionaryList from "../customDictionary/list";

const ConfirmationsDaysModal = ({open, setModalOpen}) => {

    return (
        <Modal
            open={open}
            onOpen={()=>setModalOpen(true)}
            onClose={()=>setModalOpen(false)}
            closeIcon
        >
            <CustomDictionaryList
                modalName='confirmationRegisterForTcDays'
            />
        </Modal>
    );
};

export default ConfirmationsDaysModal;
