import React, { useRef } from 'react';
import CellValue from '../../ColumnsValue';
import {LINK_TYPE} from "../../../constants/columnTypes";
import {maxWidthColumnSettings} from "../../../constants/bannersColumns";

const BodyCellComponent = ({
    column,
    children,
    value,
    valueText,
    indexColumn,
    indexRow,
    toggleIsActive,
    t,
    id,
}) => {
    const cellRef = useRef(null);

    column.isFixedPosition = column.isFixedPosition || column.type === LINK_TYPE;
    column.style = ['noticeBody', 'address', 'comment', 'description'].includes(column.name) ? maxWidthColumnSettings : (column.style || null)

    return (
        <td
            className={`${column.isFixedPosition ? 'no-scroll no-scroll-value' : ''} ${indexColumn === 0 && 'column-facet__first'}`}
            ref={cellRef}
            style={
                column.isFixedPosition
                    ? {
                        left: `${150 * indexColumn}px`,
                        maxWidth: '150px',
                        minWidth: '150px',
                    }
                    : null
            }
        >
            <CellValue
                {...column}
                toggleIsActive={toggleIsActive}
                indexRow={indexRow}
                indexColumn={indexColumn}
                value={value}
                valueText={valueText}
                width={column.isFixedPosition ? 150 : null}
                id={id}
                t={t}
            />
        </td>
    );
};

export default React.memo(BodyCellComponent);
