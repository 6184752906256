import React from 'react';
import FormFields from "../../../../components/Form/Form";
import {additionalServicesFields} from "../../../../constants/stoppers";
import FormTable from "../../../../components/Form/FormTable";
import {arrayToColumnsConfig} from "../../../../utils/sort";

const Costs = ({form, settings, onChange, error, columnsConfig}) => {
    const fields = [
        'deliveryCostWithoutVAT',
        'shippingCurrencyId'
    ]
    return (
        <div className='create-form__info margin-bottom-24'>
            <FormFields
                form={form}
                error={error}
                settings={settings}
                handleChange={onChange}
                columnsConfig={columnsConfig}
                columnsArray={fields}
            />
            <FormTable
                error={error}
                label='additionalServices'
                name='additionalServices'
                form={form}
                columnsConfig={arrayToColumnsConfig(additionalServicesFields)}
                columnsArray={additionalServicesFields.map(c => c.name)}
                onChange={onChange}
            />
        </div>
    );
};

export default Costs;