export const numbersFormat = (x, n = 2) => {
    if (isNaN(x) || isNaN(n)) return false;
    const m = Math.pow(10, n);
    return Math.round(x * m) / m;
};

export const isNumber = (x) => typeof x === 'number';

export const formatWithSpaces = (x) => (x || x === 0) ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : null;

export const formatWithCommas = (x) => (x || x === 0) ? x.toString().replace('.', ",") : null;