import React, { useEffect, useState } from 'react';

import { Button, Container, Dropdown, Flag, Form, Icon, Input, Segment } from 'semantic-ui-react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    errorSelector,
    getLoginPageRequest,
    isAuthSelector,
    loginPageSelector,
    loginRequest,
    progressSelector,
} from '../../ducks/login';
import languages from '../../constants/languages';
import './style.scss';
import {REG_LINK} from "../../router/links";
import SupportContacts from "../../components/Header/supportContacts";

const img = require('../../img/logo.png');

const Login = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const page = useSelector(state => loginPageSelector(state)) || {};
    const isAuth = useSelector(state => isAuthSelector(state));
    const error = useSelector(state => errorSelector(state));
    const loginProgress = useSelector(state => progressSelector(state, 'login_progress'));
    const pageLoadingProgress = useSelector(state => progressSelector(state, 'page_progress'));
    const { form: formElements = {} } = page;
    const { inputs = [], login_btn = {} } = formElements;

    const [form, setForm] = useState({});

    useEffect(() => {
        dispatch(getLoginPageRequest());
    }, []);

    const handleChange = (e, { name, value }) => {
        const values = {
            ...form,
            [name]: value,
        };
        setForm(values);
    };

    const handleChangeLang = (e, { value }) => {
        i18n.changeLanguage(value);
    };

    const handleFormSubmit = (e, { api }) => {
        const params = {
            form: {
                ...form,
                language: i18n.language,
            },
            api: login_btn.api,
        };
        dispatch(loginRequest(params));
    };

    return (
        <>
            <div className="login-bgn">
                <Container className='login-header'>
                    <div className="language-switcher">
                        <Flag
                            name={
                                languages.find(item => item.value === i18n.language) &&
                                languages.find(item => item.value === i18n.language).flag
                            }
                        />{' '}
                        <Dropdown
                            inline
                            options={languages}
                            value={i18n.language}
                            onChange={handleChangeLang}
                        />
                    </div>
                    <SupportContacts text={'supportPortalContacts'} isLoginPage/>
                </Container>
                <div className="ui text container login-wrapper">
                    <Segment raised className="login-segment login-segment-transparent">
                        <div className="login-logo login-logo-transparent">
                            <img src={img} title="Fm logistic" alt="Fm logistic" />
                        </div>
                        <div className="login-descr">{t('Login to the TC Portal')}</div>
                        <div className="login-form">
                            <Form onSubmit={handleFormSubmit}>
                                {inputs.map(input => (
                                    <div className="margin-bottom-24" key={input.name}>
                                        <Input
                                            fluid
                                            icon={input.icon}
                                            iconPosition="left"
                                            name={input.name}
                                            value={form[input.name]}
                                            placeholder={t(input.name)}
                                            type={input.type}
                                            onChange={handleChange}
                                        />
                                    </div>
                                ))}
                                <div
                                    className="login-error login-center"
                                    style={{ visibility: error ? 'visible' : 'hidden' }}
                                >
                                    {t(error)}
                                </div>
                                <div
                                    className="login-buttons login-center"
                                    style={{ paddingTop: '5px !important' }}
                                >
                                    <Button
                                        floated="right"
                                        primary
                                        api={login_btn.api}
                                        loading={loginProgress}
                                    >
                                        <Icon name="sign-in" />
                                        {t(login_btn.name)}
                                    </Button>
                                </div>
                            </Form>
                            <div align='center'>
                                <a className='login-sign-up' href={REG_LINK}>{t('sign-up')}</a>
                            </div>
                        </div>
                    </Segment>
                </div>
            </div>
        </>
    );
};

export default Login;
