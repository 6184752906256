import React, {useState} from 'react';
import {Button, Confirm, Divider, Icon, Popup} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import FormFields from "./Form";

const FormTable = ({label, name, form, columnsArray, columnsConfig, onChange, error, index: i, isDisabled}) => {
    const {t} = useTranslation();

    const [rowForDelete, setRowForDelete] = useState(null);
    const items = form[name] || [];

    const nameError = (fieldName, index) => {
        return `${name}_${fieldName}${i !== undefined ? `_${i}` : ''}_${index}`
    }

    const onChangeValue = (index, {value, name: valName}) => {
        let newValue = [...items];

        newValue[index] = {
            ...newValue[index],
            [valName]: value
        };

        onChange(null, {
            value: newValue,
            name
        }, nameError(valName, index))
    };

    const add = () => {
        onChange(null, {
            value: [...(items || []), {}],
            name
        })
    }

    const del = () => {
        onChange(null, {
            value: items.filter((s, i) => i !== rowForDelete),
            name
        });
        setRowForDelete(null);
    }

    return (
        <div className='form-table'>
            {label && <div className='form-table__label'>
                <label>{t(label)}</label>
                <Divider/>
            </div>}
            {!isDisabled && <div className='white-btn'>
                <a onClick={add}>
                    <Icon name='plus'/>
                    {t('add')}
                </a>
            </div>}
            {
                items.map((item, i) => {
                    return <div key={i} className='form-table__item'>
                        <FormFields
                            noLabel={i > 0}
                            form={item}
                            columnsConfig={columnsConfig}
                            columnsArray={columnsArray}
                            colCount={columnsArray.length}
                            error={error}
                            index={i}
                            handleChange={(e, p) => onChangeValue(i, p)}
                            nameError={nameError}
                            disabled={isDisabled}
                        />
                        <div>
                            <Popup
                                trigger={<Button
                                    icon='trash'
                                    onClick={() => setRowForDelete(i)}
                                    disabled={isDisabled}
                                />}
                                content={t('delete')}
                            />
                            <Confirm
                                open={rowForDelete !== null}
                                content={t(`deleteItemConfirm`)}
                                onCancel={() => setRowForDelete(null)}
                                cancelButton={t('cancelConfirm')}
                                onConfirm={del}
                            />
                        </div>
                    </div>
                })
            }
        </div>
    );
};

export default FormTable;