import React from 'react';
import {Form} from "semantic-ui-react";
import Links from "../../../../components/BaseComponents/Links";
import {useTranslation} from "react-i18next";

const Training = ({form}) => {
    const {t} = useTranslation();
    return (
        <Form className="tabs-card">
            <Links name={t('linkToTraining')} value={[form.trainingLink]}/>
        </Form>
    );
};

export default Training;