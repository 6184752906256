import React, {useRef, useState, useCallback, useEffect} from 'react';
import CellValue from '../../ColumnsValue';
import {Button, Dropdown, Form, Icon, Loader, Modal, Popup, Table} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import FormField from '../../BaseComponents';
import {COMMENTS_TYPE, LINK_TYPE, LINKS_TYPE} from '../../../constants/columnTypes';
import { ORDERS_GRID } from '../../../constants/grids';
import _ from 'lodash';
import {auctionSavePriceRequest} from "../../../ducks/gridCard";
import {useDispatch, useSelector} from "react-redux";
import {getLookupRequest, listSelector} from "../../../ducks/lookup";
import {useTranslation} from "react-i18next";

const ModalComponent = ({ element, props, children }) => {
    if (!element) {
        return <>{children}</>;
    }
    return React.cloneElement(element, props, children);
};

const DropdownValue = ({gridName, name, value, setValue, onSave, handleClick, id, rowNumber, status, invokeMassUpdate, isReadOnly}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const valuesList = useSelector(state => listSelector(state, null, t));

    const [disabled, setDisabled] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [valueForm, setValueForm] = useState('');

    const onOpen = () => {
        !isReadOnly && dispatch(
            getLookupRequest({
                name,
                entities: gridName
            }),
        );
    }

    const onClick = (value) => {
        setValue(value);
        if (value === 'notOk') {
            setOpenModal(true);
        }
        else onSave({valueData: value});
        document.getElementById(`btn-body_cell${id}`).click();
    }

    const handleClose = () => {
        setOpenModal(false);
        setValueForm(null);
    }

    const handleChange = useCallback((e, { value }) => {
        setValueForm(value);
    }, []);

    const handleSave = () => {
        onSave({
            valueData: 'notOk',
            callback: (callback) => invokeMassUpdate({
                ids: [id],
                name: gridName,
                field: 'transportCompanyComments',
                value: valueForm,
                callbackFunc: () => {
                },
                callbackSuccess: () => {
                    callback && callback();
                    handleClose();
                },
            })
        });
    }

    return <>
        <Popup
            className='btn-body_cell__popup'
            content={!disabled && <div className='btn-body_cell__dropdown'>
                {valuesList.map(v => <div onClick={()=>onClick(v.value)} className='btn-body_cell__item'>{v.name}</div>)}
            </div>}
            onOpen={onOpen}
            trigger={<Button onClick={()=>!isReadOnly && handleClick(()=>setDisabled(false))} id={`btn-body_cell${id}`} className='btn-body_cell'>{t(value)}</Button>}
            on='click'
            basic
            position='bottom center'
        />
        <Modal open={openModal} size="tiny" closeOnDimmerClick={false}>
            <Modal.Header>
                {t(`edit_${gridName}`, {
                    number: rowNumber,
                    status: t(status),
                })}
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Form onSubmit={handleSave}>
                        <FormField name='transportCompanyComments' width={'100%'} autoFocus value={valueForm} onChange={handleChange} />
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}>{t('cancelConfirm')}</Button>
                <Button
                    color="primary"
                    disabled={!valueForm || valueForm === ''}
                    onClick={handleSave}
                >
                    {t('SaveButton')}
                </Button>
            </Modal.Actions>
        </Modal>
    </>
}

const BodyCell = ({
    className,
    value,
    valueText,
    column,
    loadList,
    indexRow,
    indexColumn,
    modalCard,
    gridName,
    t,
    checkForEditing,
    invokeMassUpdate,
    status,
    rowId,
    rowNumber,
    cardLink,
    goToCard,
    isReport,
    editableColumns,
    copyDisabled,
    isRefresh,
    editColumns,
    name,
    openCardByCellClick = false,
    isAvailable
}) => {
    const contextRef = useRef(null);
    const dispatch = useDispatch();
    let [open, setOpen] = useState(false);
    let [valueForm, setValue] = useState(null);
    const [valueCommentForm, setValueComment] = useState('');
    let [progress, setProgress] = useState(false);

    const isReadOnly = editableColumns ? !editableColumns.includes(column.name) : false;

    const copyToClipboard = () => {
        const text = contextRef.current && contextRef.current.textContent;
        navigator.clipboard &&
            navigator.clipboard.writeText(text).then(
                () => {
                    toast.info(t('copied_to_clipboard_success'));
                },
                error => {
                    toast.error(t('copied_to_clipboard_error', { error }));
                },
            );
    };

    const openCard = () => {
        goToCard && goToCard(true, rowId, name);
    }

    const handleClick = (rowId, fieldName, state, callback) => {
        setProgress(true);
        checkForEditing({
            rowId,
            fieldName,
            state,
            forEntity: gridName,
            t,
            callbackSuccess: () => {
                callback ? callback() : handleOpen();
            },
            callbackError: openCardByCellClick ? openCard : null,
            callbackFunc: () => {
                setProgress(false);
            },
        });
    };

    const handleOpen = () => {
        setOpen(true);
        setValue(valueText ? { value, name: valueText } : (column.type !== COMMENTS_TYPE ? value : ''));
    };

    const handleClose = () => {
        setOpen(false);
        setValue(null);
    };

    const handleSave = ({valueData = null, callback}) => {
        setProgress(true);
        invokeMassUpdate({
            ids: [rowId],
            name: gridName,
            field: editColumns ? editColumns[0].name : column.name,
            value: !editColumns ? (valueData || valueForm) : {
                value: {
                    value: valueForm,
                    comment: valueCommentForm
                }
            },
            callbackFunc: () => {
                setProgress(false);
            },
            callbackSuccess: () => {
                callback ? callback(()=>loadList(false, true)) : loadList(false, true);
                handleClose();
            },
        });
    };

    const handleChange = useCallback((e, { value }) => {
        setValue(value);
    }, []);

    const handleCellClick = () => {
        (column.type !== LINK_TYPE && column.type !== LINKS_TYPE && !isReadOnly)
            && handleClick(rowId, column.name, status)
    };

    const getModalCard = useCallback(() => {
        return (
            <ModalComponent
                element={modalCard()}
                props={{
                    id: rowId,
                    status,
                    loadList,
                    title: `edit_${gridName}`,
                }}
                key={`modal_${rowId}`}
            />
        );
    }, []);

    const handleSavePriceOffer = () => {
        dispatch(auctionSavePriceRequest({
            id: rowId,
            params: {
                transportCompanyAuctionPriceOffer: valueForm,
                shippingVersion: null
            },
            callbackSuccess: () => {
                loadList(false, true);
                handleClose();
            }
        }));

    }

    const isDropdown = column.name === 'statusByTC';

    const newWindow = (gridName, name) => {
        const newWindowFields = {
            //Статусы возврата документов
            documentReturnReport: [
                'shippingNumber' //Номер перевозки
            ],
            //Таблица согласования Реестра для оплаты
            registriesForPayment: [
                'shippingNumber' //Номер перевозки
            ],
            //Опоздания
            lates: [
                'shippingNumber' //Номер перевозки
            ]
        };
        const arr = newWindowFields[gridName];
        return arr && arr.includes(name);
    };

    return (
        <>
            <Table.Cell className={`hover-cursor-pointer ${className || ''}`} onClick={(e) => {
                e.stopPropagation();
                !isDropdown && isAvailable && handleCellClick();
            }}>
                <div className="cell-grid">
                    <div
                        className={`cell-grid-value ${value ? '' : 'cell-grid-value_empty'}`}
                        ref={contextRef}
                    >
                        {!isDropdown ? <CellValue
                            {...column}
                            indexRow={indexRow}
                            indexColumn={indexColumn}
                            value={value}
                            valueText={valueText}
                            width={column.width}
                            gridName={gridName}
                            rowId={rowId}
                            t={t}
                            goToCard={goToCard}
                            isAvailable={isAvailable}
                            newWindow={(column.type === LINK_TYPE) && newWindow(gridName, column.name)}
                        /> : <DropdownValue
                                id={rowId}
                                handleClick={(c)=>handleClick(rowId, column.name, status, c)}
                                onSave={handleSave}
                                setValue={setValue}
                                name={column.name}
                                gridName={gridName}
                                value={value}
                                status={status}
                                rowNumber={rowNumber}
                                invokeMassUpdate={invokeMassUpdate}
                                isReadOnly={isReadOnly}
                        />}
                    </div>
                    {(!isReport || !isReadOnly) && <div className="cell-actions" onClick={e => e.stopPropagation()}>
                        {progress ? (
                            <Loader active={true} size="mini"/>
                        ) : (
                            <>
                                {!copyDisabled && value !== null && (
                                    <div className="cell-grid-copy-btn">
                                        <Icon
                                            name="clone outline"
                                            size="small"
                                            onClick={copyToClipboard}
                                        />
                                    </div>
                                )}
                                {
                                    column.isEditButtonVisible && (status === 'auctionOpened' || (!isReadOnly && isReport)) ? (
                                        <Button icon size='mini' compact className="cell-actions_btn"
                                                onClick={handleCellClick}>
                                            <Icon name='edit outline'/>
                                        </Button>
                                    ) : null
                                }
                            </>
                        )}
                    </div>}
                </div>
            </Table.Cell>
            <Modal open={open} size="tiny" closeOnDimmerClick={false}>
                <Modal.Header>
                    {!isReport || isRefresh ? t(`edit_${gridName}`, {
                        number: rowNumber,
                        status: t(status),
                    }) : t(column.name)}
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Form onSubmit={handleSave}>
                            {
                                (editColumns || [column]).map((col, index) => (
                                    <FormField
                                        {...col}
                                        width={'100%'}
                                        autoFocus={index === 0}
                                        value={index === 0 ? valueForm : valueCommentForm}
                                        onChange={index === 0 ? handleChange : (e, {value}) => setValueComment(value)}
                                    />
                                ))
                            }
                        </Form>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={handleClose}>{t('cancelConfirm')}</Button>
                    <Button
                        color="primary"
                        disabled={_.isEqual(valueForm, value) || (column.type === COMMENTS_TYPE && (!valueForm || valueForm === ''))}
                        onClick={(column && column.name === "transportCompanyAuctionPriceOffer")? handleSavePriceOffer : handleSave}
                    >
                        {t('SaveButton')}
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default React.memo(
    BodyCell /*, (prevProps, nextProps) => {
    return prevProps.column === nextProps.column &&
        prevProps.loadList === nextProps.loadList &&
        prevProps.indexRow === nextProps.indexRow &&
        prevProps.indexColumn === nextProps.indexColumn &&
        prevProps.modalCard === nextProps.modalCard &&
        prevProps.gridName === nextProps.gridName &&
        prevProps.t === nextProps.t &&
        prevProps.checkForEditing === nextProps.checkForEditing &&
        prevProps.invokeMassUpdate === nextProps.invokeMassUpdate &&
        _.isEqual(prevProps.row, nextProps.row)
}*/,
);
