import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Form, Modal} from "semantic-ui-react";
import Date from "../../components/BaseComponents/Date";
import {parseDate} from "../../utils/dateTimeFormater";
import {useSelector} from "react-redux";
import {isTransportCompanyUserSelector} from "../../ducks/profile";
import MultiSelect from "../../components/BaseComponents/MultiSelectInfinityScroll_new";
import {toast} from "react-toastify";

const RegistriesForPaymentUpdateModal = ({open, onSave, setModalOpen}) => {
    const {t} = useTranslation();
    const [params, setParams] = useState({});
    const isTransportCompanyUser = useSelector(state => isTransportCompanyUserSelector(state));

    const handleChangeParams = (e, {name, value}) => {
        setParams(params => {
            const resetNumbers = !!((name !== 'shippingNumbers') && (params.shippingNumbers || []).length);
            resetNumbers && toast.info(t('resetShippingNumbersFilter'))

            return {
                ...params,
                shippingNumbers: resetNumbers ? [] : params.shippingNumbers,
                [name]: value,
            }
        });
    };

    const getParams = () => ({
        startDate: params.startDate,
        endDate: params.endDate,
        transportCompanyIds: (params.transportCompanies || []).map(v => v.value),
        shippingIds: (params.shippingNumbers || []).map(v => v.value),
    });

    const handleSave = () => {
        onSave(getParams());
        setModalOpen(false);
    }

    return (
        <Modal
            size='small'
            dimmer="blurring"
            id="fieldModal"
            open={open}
            onOpen={()=>setModalOpen(true)}
            onClose={()=>setModalOpen(false)}
            className="representation-modal"
            closeIcon
        >
            <Modal.Header>{t('Update')}</Modal.Header>
            <Modal.Content>
                <Form className='selected_dates'>
                    <div>
                        <div className='input-modal'>
                            <Date
                                text="periodStartDate"
                                name="startDate"
                                value={params.startDate}
                                onChange={handleChangeParams}
                            />
                        </div>
                        <div className='input-modal'>
                            <Date
                                text="periodEndDate"
                                name="endDate"
                                value={params.endDate}
                                onChange={handleChangeParams}
                            />
                        </div>
                    </div>
                    <div>
                        {!isTransportCompanyUser && <div className='input-modal'>
                            <MultiSelect
                                name="transportCompanies"
                                source="transportCompanies/registriesForPayment"
                                value={params.transportCompanies || []}
                                onChange={handleChangeParams}
                            />
                        </div>}
                        <div className='input-modal'>
                            <MultiSelect
                                name="shippingNumbers"
                                source="registriesForPayment/shippingNumber"
                                value={params.shippingNumbers || []}
                                onChange={handleChangeParams}
                                sourceParams={getParams()}
                                isBody
                            />
                        </div>
                    </div>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={()=>setModalOpen(false)}>
                    {t('CancelButton')}
                </Button>
                <Button
                    primary
                    disabled={(!(params.shippingNumbers || []).length) && !(params.startDate && params.endDate && (parseDate(params.startDate) <= parseDate(params.endDate)))}
                    onClick={handleSave}
                >
                    {t('SaveButton')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default RegistriesForPaymentUpdateModal;
