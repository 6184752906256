import React from 'react';
import FormField from "../../containers/customGrid/components/formField";
import './style.scss'
import {Form} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

const NumberAndDate = ({className = '', name, form, error, onChange, columnsConfig, settings}) => {
    const {t} = useTranslation();

    const numberName = `${name}Number`;
    const dateName = `${name}Date`;



    return columnsConfig[numberName] ? (
        <Form.Field className={`number-date ${className}`}>
            <Form.Field>
                <label>{t(columnsConfig[numberName].displayNameKey)}</label>
                <FormField
                    noLabel
                    name={numberName}
                    columnsConfig={columnsConfig}
                    settings={settings}
                    form={form}
                    error={error}
                    onChange={onChange}
                />
            </Form.Field>
            <Form.Field>
                <label>{t('dated')}</label>
                <FormField
                    noLabel
                    name={dateName}
                    columnsConfig={columnsConfig}
                    settings={settings}
                    form={form}
                    error={error}
                    onChange={onChange}
                />
            </Form.Field>
        </Form.Field>
    )
    : null
};

export default NumberAndDate;