import React, {useCallback, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Information from './shippingTabs/information';
import Stoppers from './shippingTabs/stoppers';
import History from './shared/history';
import {useDispatch, useSelector} from 'react-redux';
import {userNameSelector, userPermissionsSelector} from '../../../ducks/profile';
import CardLayout from '../../../components/CardLayout';
import Costs from './shippingTabs/costs';
import AuctionPriceOffers from "./shippingTabs/auctionPriceOffers";
import AuctionParameters from "./shippingTabs/auctionParameters";
import AuctionPriceOffer from "./shippingTabs/auctionPriceOffer";
import DeliveryDocuments from "./shippingTabs/deliveryDocuments";
import {anomaliesLoadingSelector, clearError} from "../../../ducks/gridCard";
import Fines from "./shippingTabs/fines";

const ShippingCard = ({
    form,
    onChangeForm: onChange,
    name,
    id,
    onClose: beforeClose,
    settings,
    title,
    actionsFooter,
    onClose,
    actionsHeader,
    loading,
    load,
    error,
    updateHistory,
    saveForm,
    scrollTo = null
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const userPermissions = useSelector(state => userPermissionsSelector(state));
    const profile = useSelector(state => userNameSelector(state));
    const canScroll = !useSelector(state => anomaliesLoadingSelector(state)) && !loading;
    const { orders = [] } = form;
    let [routeActiveIndex, setRouteActiveIndex] = useState(0);

    const handleTabChange = useCallback((e, { activeIndex }) => {
        setRouteActiveIndex(activeIndex);
    }, []);

    const loadData = () => {
        load();
    }

    const onChangeForm = (e, d, errName) => {
        onChange(e, d);
        errName && dispatch(clearError && clearError(errName));
    }

    const getPanes = () => {
        const obj = [
            {
                menuItem: t('information'),
                render: () => (
                    <Information
                        form={form}
                        onChange={onChangeForm}
                        name={name}
                        updateHistory={updateHistory}
                        error={error}
                        settings={settings}
                    />
                ),
            },
            {
                menuItem: t('stoppersFormCaption'),
                render: () => (
                    <Stoppers
                        error={error}
                        form={form}
                        routeActiveIndex={routeActiveIndex}
                        settings={settings}
                        tabChange={handleTabChange}
                        onChange={onChangeForm}
                        updateHistory={updateHistory}
                    />
                ),
            },
            {
                menuItem: t('deliveryDocuments'),
                disabled: !form.transportCompanyId,
                isOpen: form.transportCompanyId,
                render: () => (
                    <DeliveryDocuments
                        name={name}
                        updateHistory={updateHistory}
                        form={form}
                        onChange={onChangeForm}
                        error={error}
                        settings={settings}
                    />
                ),
            },
            {
                menuItem: t('costs'),
                render: () => (
                    <Costs
                        form={form}
                        settings={settings}
                        error={error}
                        onChange={onChangeForm}
                    />
                ),
            },
            {
                menuItem: t('fines'),
                render: () => (
                    <Fines
                        form={form}
                        settings={settings}
                        error={error}
                        onChange={onChangeForm}
                    />
                ),
            }
        ];

        if (form.auctionPriceOffers && form.auctionPriceOffers.isVisible) {
            obj.unshift({
                menuItem: t('auctionPriceOffers'),
                render: () => (
                    <AuctionPriceOffers
                        load={loadData}
                        items={form.auctionPriceOffers.items}
                        shippingVersion={form.rowVersion}
                        cardId={id}
                        isSelectButtonsVisible={form.auctionPriceOffers.isSelectButtonsVisible}
                    />
                )
            })
        }

        if (form.isAuctionVisible) {
            obj.unshift({
                menuItem: t('auctionParameters'),
                render: () => (
                    <AuctionParameters
                        form={form}
                        onChange={onChangeForm}
                        error={error}
                        settings={settings}
                    />
                )
            })
        }

        if (form.isAuctionPriceOfferVisible) {
            obj.unshift({
                menuItem: t('auctionPriceOffer'),
                render: () => (
                    <AuctionPriceOffer
                        form={form}
                        onChange={onChangeForm}
                        error={error}
                        settings={settings}
                        saveForm={saveForm}
                        load={loadData}
                    />
                ),
            })
        }

        if (userPermissions.includes(12)) {
            obj.push({
                menuItem: t('history'),
                render: () => <History cardId={id} status={form.status} />,
            });
        }

        return obj;
    };

    return (
        <>
            <CardLayout
                title={title}
                actionsFooter={actionsFooter}
                actionsHeader={actionsHeader}
                content={getPanes}
                onClose={onClose}
                loading={loading}
                scrollTo={canScroll && scrollTo}
            />
        </>
    );
};

export default ShippingCard;
