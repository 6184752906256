import React, {useState} from 'react';
import {Button, Checkbox, Modal} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import rules from './rules'
import {useDispatch, useSelector} from "react-redux";
import {
    acceptAuctionRules, auctionRulesCallbackSelector,
    auctionRulesViewSelector,
    setShowAuctionRules,
} from "../../ducks/profile";

const AuctionRulesModal = () => {
    const openRulesAuction = useSelector(state => auctionRulesViewSelector(state));
    const callbackData = useSelector(state => auctionRulesCallbackSelector(state));
    const [isChecked, setIsChecked] = useState();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const clickCancel = () => {
        dispatch(setShowAuctionRules());
    }

    const clickAccept = () => {
        dispatch(acceptAuctionRules(callbackData));
    }

    return (
        <Modal open={openRulesAuction} size="large" dimmer="blurring">
            <Modal.Header>
                {t('auctionRulesModalTitle')}
            </Modal.Header>
            <Modal.Content style={{maxHeight: '60vh', overflow: 'auto'}}>
                {
                    rules()
                }
                <Checkbox
                    className='auction-rules__checkbox'
                    label={<label><b>{t('acceptAuctionRulesCheckBoxTitle')}</b></label>}
                    onChange={(e, {checked}) => setIsChecked(checked)}
                    checked={isChecked}
                />
            </Modal.Content>
            <Modal.Actions className="confirm-dialog-actions">
                <div>
                    <Button onClick={clickCancel}>
                        {t('cancelConfirm')}
                    </Button>
                    <Button color="blue" disabled={!isChecked} onClick={clickAccept}>
                        {t('acceptAuctionRulesButtonTitle')}
                    </Button>
                </div>
            </Modal.Actions>
        </Modal>
    );
};

export default AuctionRulesModal;