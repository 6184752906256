import React from 'react';
import {useTranslation} from 'react-i18next';
import CardLayout from '../../../components/CardLayout';
import {useSelector} from "react-redux";
import {columnsTypesConfigSelector} from "../../../ducks/representations";
import {Content} from "./content";

const FreeTransportCard = ({
                               form,
                               onChangeForm,
                               name,
                               settings,
                               title,
                               actionsFooter,
                               onClose,
                               actionsHeader,
                               loading,
                               error,
                           }) => {
    const {t} = useTranslation();
    const columnsConfig = useSelector(state => columnsTypesConfigSelector(state, name));
    const columnsArray = ['fromWhereId', 'whereToId', 'possibleLoadingPeriod',
        'vehicleTypeId', 'transportCount', 'isOwnTransport', 'transportTotalCostWithoutVAT',
        'transportTotalCostWithVAT', 'currencyId', 'bargain', 'contactPhoneNumber', 'comment'];

    const getPanes = () => {
        const obj = [
            {
                menuItem: t('information'),
                render: () => (
                    <Content
                        form={form}
                        error={error}
                        settings={settings}
                        columnsConfig={columnsConfig}
                        dictionaryName={name}
                        handleChange={onChangeForm}
                        columnsArray={columnsArray}
                    />
                ),
            },
        ];

        return obj;
    };

    return (
        <CardLayout
            title={title}
            actionsFooter={actionsFooter}
            actionsHeader={actionsHeader}
            onClose={onClose}
            loading={loading}
        >
            {getPanes()[0].render()}
        </CardLayout>
    );
};

export default FreeTransportCard;