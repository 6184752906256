import React from 'react';
import {useTranslation} from 'react-i18next';
import CardLayout from '../../../components/CardLayout';
import {useSelector} from "react-redux";
import {columnsTypesConfigSelector} from "../../../ducks/representations";
import {Content} from "./content";
import History from "./shared/history";
import {Segment} from "semantic-ui-react";

const GridCard = ({
                      form,
                      onChangeForm,
                      name,
                      settings,
                      title,
                      actionsFooter,
                      onClose,
                      actionsHeader,
                      loading,
                      error,
                      withHistory,
                      allPlacesIsInformation,
                      customBlockForSections
                  }) => {
    const {t} = useTranslation();
    const informationName = 'information';

    const columnsConfig = useSelector(state => columnsTypesConfigSelector(state, name));
    const getContent = () => {
        const customSectionPlaces = new Set(Object.keys(columnsConfig).map(c => columnsConfig[c] && columnsConfig[c].customSectionPlace));
        const contentBlock = (block, label) => {
            const customBlock = customBlockForSections && customBlockForSections[block];
            const columnsArray = Object.keys(columnsConfig).filter(c => columnsConfig[c] && (block ? columnsConfig[c].customSectionPlace === block : !columnsConfig[c].customSectionPlace)) || [];
            return <Content
                label={label && t(label)}
                form={form}
                error={error}
                settings={settings}
                columnsConfig={columnsConfig}
                dictionaryName={name}
                handleChange={onChangeForm}
                columnsArray={columnsArray}
                customBlock={customBlock}
            />
        }

        const arr = allPlacesIsInformation
            ? [{
                menuItem: t(informationName),
                render: () => (
                    <div>
                        {
                            [...customSectionPlaces].filter(c => c).map(block => (
                                <Segment className='grid-card-block'>
                                    {contentBlock(block, block)}
                                </Segment>
                            ))
                        }
                    </div>
                )
            }]
            : [...customSectionPlaces].map(block => ({
                menuItem: t(block || informationName),
                render: () => contentBlock(block),
            }));

        if (withHistory) arr.push({
            menuItem: t('history'),
            render: () => <History/>,
        })

        return arr;
    };

    return (
        <CardLayout
            title={title}
            actionsFooter={actionsFooter}
            actionsHeader={actionsHeader}
            onClose={onClose}
            loading={loading}
            content={getContent}
        />
    );
};

export default GridCard;