import React from 'react';
import {Button, Table, Form, Checkbox} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import FormField from "../customGrid/components/formField";

const TaskCompletion = ({columnsConfig, settings, form, error, handleChange, name}) => {
    const {t} = useTranslation();

    const rows = form[name] || [];
    const columnsArray = ['problem', 'action', 'responsiable', 'responsiableName', 'deadlineTask'];

    const onChange = (id, index, e, {name: nameCol, value}) => {
        const rowIndex = id ? rows.findIndex(r => r.id = id) : index;

        const newRows = [...rows];
        newRows[rowIndex] = {
            ...newRows[rowIndex],
            [nameCol]: value
        };

        handleChange(e, {
            name,
            value: newRows
        })
    }

    const addRow = () => {
        handleChange(null, {
            name,
            value: [...rows, {isDone: false}]
        })
    }

    return (
        <div className='padding-14'>
            <div className='d-flex j-c-end margin-bottom-10'>
                <Button onClick={addRow}>
                    {t('add')}
                </Button>
            </div>
            <div className='task-table__block'>
                <Table className='task-table'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                <b>{t('complete')}</b>
                            </Table.HeaderCell>
                            {
                                columnsArray.map(c => (
                                    <Table.HeaderCell>
                                        <b>{t((columnsConfig[c] || {}).displayNameKey || c)}</b>
                                    </Table.HeaderCell>
                                ))
                            }
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            rows.map((row, i) => (
                                <Table.Row key={i}>
                                    {
                                        <Table.Cell textAlign='center'>
                                            <Checkbox
                                                checked={row['isDone']}
                                                onChange={() => onChange(row.id, i, null, {
                                                    name: 'isDone',
                                                    value: !row.isDone
                                                })}
                                                toggle
                                            />
                                        </Table.Cell>
                                    }
                                    {
                                        columnsArray.map(c => (
                                            <Table.Cell className='ui form'>
                                                <Form.Field>
                                                    <FormField
                                                        name={c}
                                                        columnsConfig={columnsConfig}
                                                        form={row}
                                                        error={error}
                                                        onChange={(...args) => onChange(row.id, i, ...args)}
                                                        settings={settings}
                                                        noLabel
                                                    />
                                                </Form.Field>
                                            </Table.Cell>

                                        ))
                                    }
                                </Table.Row>
                            ))
                        }
                    </Table.Body>
                </Table>
            </div>
        </div>

    );
};

export default TaskCompletion;