import React, { useEffect } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import {
    ADMIN_TEMPLATES_LINK,
    API_LINK,
    BANNERS_LINK,
    DICTIONARY_CARD_LINK,
    DICTIONARY_LIST_LINK,
    DICTIONARY_NEW_LINK,
    FIELDS_SETTING_LINK,
    GRID_CARD_LINK,
    GRID_LIST_LINK,
    GRID_NEW_LINK,
    LOGIN_LINK,
    NEW_ROLE_LINK,
    NEW_USER_LINK,
    PARAMS_SETTING_LINK,
    PARAMS_TRANSPORT_COMPANY_SETTING_LINK,
    PERSONAL_CABINET_CARD_LINK,
    PERSONAL_CABINET_LINK,
    PERSONAL_CABINET_NEW_LINK,
    PROFILE_LINK,
    REG_LINK,
    REPORT_LINK,
    ROLE_LINK,
    ROLES_LINK,
    SHIPPING_ANOMALY_PHOTOS_LINK,
    SHURL_LINK,
    STOPPER_ANOMALY_PHOTOS_LINK,
    TC_FORM_NO_LOGGED_LINK,
    USER_LINK,
    USERS_LINK,
} from './links';
import CustomGrid from '../containers/customGrid/list';
import CustomGridCard from '../containers/customGrid/card';
import CustomDictionaryList from '../containers/customDictionary/list';
import CustomDictionaryCard from '../containers/customDictionary/card';
import PrivateRoute from './privateRoute';
import Login from '../containers/login';
import RolesList from '../containers/roles/roles_list';
import RoleCard from '../containers/roles/role_card_new';
import UsersList from '../containers/users/users_list';
import UserCard from '../containers/users/user_card_new';
import FieldsSetting from '../containers/fieldsSetting/list';
import {homePageSelector} from '../ducks/profile';
import Profile from "../containers/users/profile";
import AdminTemplates from "../containers/adminTemplates";
import {
    ADMIN_TEMPLATES_PERMISSION,
    BANNERS_PERMISSION,
    EDIT_ROLES_PERMISSION,
    EDIT_USER_PERMISSION,
    PERSONAL_CABINET_PERMISSION,
    PROFILE_PERMISSION,
} from "../constants/permissions";
import Params from "../containers/paramsSetting/params";
import BannersList from "../containers/banners/banners_list";
import RouteReg from "./routeReg";
import PrivateRegRoute from "./privateRegRoute";
import PersonalCabinet from "../containers/personalCabinet/personalCabinet";
import KpiAnalysis from "../containers/personalCabinet/kpiAnalysis";
import {
    PERSONAL_CABINET_ADDITIONAL_INFORMATION,
    PERSONAL_CABINET_AVAILABLE_PARK,
    PERSONAL_CABINET_CONTACTS_FM,
    PERSONAL_CABINET_HOME,
    PERSONAL_CABINET_KPI,
    PERSONAL_CABINET_MEETING_PROTOCOLS,
    PERSONAL_CABINET_LATENESS,
    PERSONAL_CABINET_TURNOVER,
    PERSONAL_CABINET_DOCUMENTS_AND_INSTRUCTIONS
} from "../constants/personalCabinetPages";
import PersonalCabinetHome from "../containers/personalCabinet/home";
import Lateness from "../containers/personalCabinet/lateness";
import MeetingProtocol from "../containers/personalCabinet/meetingProtocol";
import Turnover from "../containers/personalCabinet/turnover";
import ContactsFM from "../containers/personalCabinet/contactsFm";
import AvailablePark from "../containers/personalCabinet/availablePark";
import AdditionalInformation from "../containers/personalCabinet/additionalInformation";
import MeetingProtocolForm from "../containers/personalCabinet/meetingProtocolForm";
import AnomaliesPhotos from "../containers/customGrid/components/anomaliesPhotos";
import ShurlRoute from "./shurlRoute";
import ApiDownloadRoute from "./ApiDownloadRoute";
import DocumentsAndInstructions from "../containers/personalCabinet/documentsAndInstructions";
import UnauthRoute from "./unauthRoute";
import Reports from "../containers/reports/reports";

const MainRoute = withRouter(props => {
    const homePage = useSelector(state => homePageSelector(state));

    useEffect(
        () => {
            const { history, location } = props;
            const { pathname } = location;
            if (pathname === '/grid' && homePage) {
                history.push(homePage);
            }
        },
        [homePage],
    );

    return (
        <Switch>
            <PrivateRoute exact path="/" component={() => <Redirect to={homePage} />} />
            <PrivateRegRoute exact path={TC_FORM_NO_LOGGED_LINK} component={CustomGridCard} />
            <Route path={API_LINK} component={ApiDownloadRoute} />
            <Route exact path={REG_LINK} component={RouteReg} />
            <Route exact path={SHURL_LINK} component={ShurlRoute} />
            <PrivateRoute exact path={GRID_NEW_LINK} component={CustomGridCard} />
            <PrivateRoute exact path={REPORT_LINK} component={Reports} />
            <PrivateRoute exact path={GRID_CARD_LINK} component={CustomGridCard} />
            <PrivateRoute exact path={GRID_LIST_LINK} component={CustomGrid} />
            <PrivateRoute exact path={DICTIONARY_NEW_LINK} component={CustomDictionaryCard} />
            <PrivateRoute exact path={DICTIONARY_CARD_LINK} component={CustomDictionaryCard} />
            <PrivateRoute exact path={DICTIONARY_LIST_LINK} component={CustomDictionaryList} />
            <PrivateRoute exact path={NEW_ROLE_LINK} permission={EDIT_ROLES_PERMISSION} component={RoleCard} />
            <PrivateRoute exact path={ROLE_LINK} permission={EDIT_ROLES_PERMISSION} component={RoleCard} />
            <PrivateRoute exact path={ROLES_LINK} permission={EDIT_ROLES_PERMISSION} component={RolesList} />
            <PrivateRoute exact path={NEW_USER_LINK} permission={EDIT_USER_PERMISSION} component={UserCard} />
            <PrivateRoute exact path={USER_LINK} permission={EDIT_USER_PERMISSION} component={UserCard} />
            <PrivateRoute exact path={USERS_LINK} permission={EDIT_USER_PERMISSION} component={UsersList} />
            <PrivateRoute exact path={BANNERS_LINK} permission={BANNERS_PERMISSION} component={BannersList} />
            <PrivateRoute exact path={PROFILE_LINK} permission={PROFILE_PERMISSION} component={Profile} />
            <PrivateRoute exact path={PARAMS_SETTING_LINK} permission={PROFILE_PERMISSION} component={Params} />
            <PrivateRoute exact path={PARAMS_TRANSPORT_COMPANY_SETTING_LINK} permission={PROFILE_PERMISSION} component={Params} />
            <PrivateRoute exact path={ADMIN_TEMPLATES_LINK} permission={ADMIN_TEMPLATES_PERMISSION} component={AdminTemplates} />
            <PrivateRoute exact path={SHIPPING_ANOMALY_PHOTOS_LINK} component={({...props})=><AnomaliesPhotos {...props}/>} />
            <PrivateRoute exact path={STOPPER_ANOMALY_PHOTOS_LINK} component={({...props})=><AnomaliesPhotos {...props} isStopper/>} />
            <PrivateRoute exact path={PERSONAL_CABINET_LINK.replace(':pageName', PERSONAL_CABINET_KPI)} permission={PERSONAL_CABINET_PERMISSION} component={() => <PersonalCabinet component={KpiAnalysis} activeItem={PERSONAL_CABINET_KPI}/>} />
            <PrivateRoute exact path={PERSONAL_CABINET_LINK.replace(':pageName', PERSONAL_CABINET_HOME)} permission={PERSONAL_CABINET_PERMISSION} component={() => <PersonalCabinet component={PersonalCabinetHome} activeItem={PERSONAL_CABINET_HOME}/>} />
            <PrivateRoute exact path={PERSONAL_CABINET_LINK.replace(':pageName', PERSONAL_CABINET_LATENESS)} permission={PERSONAL_CABINET_PERMISSION} component={() => <PersonalCabinet component={Lateness} activeItem={PERSONAL_CABINET_LATENESS}/>} />
            <PrivateRoute exact path={PERSONAL_CABINET_LINK.replace(':pageName', PERSONAL_CABINET_TURNOVER)} permission={PERSONAL_CABINET_PERMISSION} component={() => <PersonalCabinet component={Turnover} activeItem={PERSONAL_CABINET_TURNOVER}/>} />
            <PrivateRoute exact path={PERSONAL_CABINET_LINK.replace(':pageName', PERSONAL_CABINET_CONTACTS_FM)} permission={PERSONAL_CABINET_PERMISSION} component={() => <PersonalCabinet component={ContactsFM} activeItem={PERSONAL_CABINET_CONTACTS_FM}/>} />
            <PrivateRoute exact path={PERSONAL_CABINET_LINK.replace(':pageName', PERSONAL_CABINET_AVAILABLE_PARK)} permission={PERSONAL_CABINET_PERMISSION} component={() => <PersonalCabinet component={AvailablePark} activeItem={PERSONAL_CABINET_AVAILABLE_PARK}/>} />
            <PrivateRoute exact path={PERSONAL_CABINET_LINK.replace(':pageName', PERSONAL_CABINET_ADDITIONAL_INFORMATION)} permission={PERSONAL_CABINET_PERMISSION} component={() => <PersonalCabinet component={AdditionalInformation} activeItem={PERSONAL_CABINET_ADDITIONAL_INFORMATION}/>} />
            <PrivateRoute exact path={PERSONAL_CABINET_LINK.replace(':pageName', PERSONAL_CABINET_MEETING_PROTOCOLS)} permission={PERSONAL_CABINET_PERMISSION} component={() => <PersonalCabinet component={MeetingProtocol} activeItem={PERSONAL_CABINET_MEETING_PROTOCOLS}/>} />
            <PrivateRoute exact path={PERSONAL_CABINET_LINK.replace(':pageName', PERSONAL_CABINET_DOCUMENTS_AND_INSTRUCTIONS)} permission={PERSONAL_CABINET_PERMISSION} component={() => <PersonalCabinet component={DocumentsAndInstructions} activeItem={PERSONAL_CABINET_DOCUMENTS_AND_INSTRUCTIONS}/>} />
            <PrivateRoute exact path={PERSONAL_CABINET_NEW_LINK.replace(':pageName', PERSONAL_CABINET_MEETING_PROTOCOLS)} permission={PERSONAL_CABINET_PERMISSION} component={({...props}) => <PersonalCabinet {...props} component={MeetingProtocolForm} activeItem={PERSONAL_CABINET_MEETING_PROTOCOLS}/>} />
            <PrivateRoute exact path={PERSONAL_CABINET_CARD_LINK.replace(':pageName', PERSONAL_CABINET_MEETING_PROTOCOLS)} permission={PERSONAL_CABINET_PERMISSION} component={({...props}) => <PersonalCabinet {...props} component={MeetingProtocolForm} activeItem={PERSONAL_CABINET_MEETING_PROTOCOLS}/>} />
            <PrivateRoute
                exact
                path={FIELDS_SETTING_LINK}
                permission="editFieldProperties"
                component={FieldsSetting}
            />
            <UnauthRoute exact path={LOGIN_LINK} component={Login} />
            <PrivateRoute exact path="*" component={() => <Redirect to={homePage} />} />
        </Switch>
    );
});

export default MainRoute;
