import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'semantic-ui-react';
import './style.scss';
import InfoComponent from './InfoComponent';
import { contentSelector, hideModal, isOpenSelector } from '../../ducks/modal';
import {useTranslation} from "react-i18next";

const modalRoot = document.getElementById('modal-root');
let el = document.createElement('div');

const ModalInfo = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const open = useSelector(state => isOpenSelector(state));
    const content = useSelector(state => contentSelector(state));
    const { message, entries, text, callbackSuccess, buttons } = content;

    const onClose = () => {
        dispatch(hideModal());
        callbackSuccess && callbackSuccess();
    };

    return (
        <Modal
            open={open}
            closeIcon
            closeOnDimmerClick={false}
            className={message && entries && entries.length ? 'modal-info' : 'modal-confirm'}
            onClose={onClose}
        >
            {message && <Modal.Header>{t(message)}</Modal.Header>}
            <Modal.Content scrolling>
                {text
                    ? <span dangerouslySetInnerHTML={{ __html: t(text) }} />
                    : entries && entries.length
                    ? entries.map(item => <InfoComponent item={item} />)
                    : null}
            </Modal.Content>
            <Modal.Actions>
                {buttons && buttons.length ? (
                    <>
                        {
                            buttons.map(button => (
                                <Button primary={button.type === 1} onClick={typeof (button.action) === 'function' ? () => dispatch(button.action()) : onClose}>{t(button.text)}</Button>
                            ))
                        }
                    </>
                ) : (
                    <Button primary onClick={onClose}>
                        OK
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    );
};

export default ModalInfo;
