import { createSelector } from 'reselect';
import { downloader, postman } from '../utils/postman';
import { all, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {dateToUTC} from '../utils/dateTimeFormater';
import { errorMapping } from '../utils/errorMapping';
import downloadFile from "../utils/downloadFile";
import {hideModal, showModal} from "./modal";
import {ACTIVE_TYPE, HIDDEN_TYPE} from "../constants/columnTypes";
import {userPermissionsSelector} from "./profile";

//*  TYPES  *//

const GET_DICTIONARY_LIST_REQUEST = 'GET_DICTIONARY_LIST_REQUEST';
const GET_DICTIONARY_LIST_SUCCESS = 'GET_DICTIONARY_LIST_SUCCESS';
const GET_DICTIONARY_LIST_ERROR = 'GET_DICTIONARY_LIST_ERROR';

const GET_DICTIONARY_CARD_REQUEST = 'GET_DICTIONARY_CARD_REQUEST';
const GET_DICTIONARY_CARD_SUCCESS = 'GET_DICTIONARY_CARD_SUCCESS';
const GET_DICTIONARY_CARD_ERROR = 'GET_DICTIONARY_CARD_ERROR';

const DICTIONARY_IMPORT_FROM_EXCEL_REQUEST = 'DICTIONARY_IMPORT_FROM_EXCEL_REQUEST';
const DICTIONARY_IMPORT_FROM_EXCEL_SUCCESS = 'DICTIONARY_IMPORT_FROM_EXCEL_SUCCESS';
const DICTIONARY_IMPORT_FROM_EXCEL_ERROR = 'DICTIONARY_IMPORT_FROM_EXCEL_ERROR';

const DICTIONARY_EXPORT_TO_EXCEL_REQUEST = 'DICTIONARY_EXPORT_TO_EXCEL_REQUEST';
const DICTIONARY_EXPORT_TO_EXCEL_SUCCESS = 'DICTIONARY_EXPORT_TO_EXCEL_SUCCESS';
const DICTIONARY_EXPORT_TO_EXCEL_ERROR = 'DICTIONARY_EXPORT_TO_EXCEL_ERROR';

const SAVE_DICTIONARY_CARD_REQUEST = 'SAVE_DICTIONARY_CARD_REQUEST';
const SAVE_DICTIONARY_CARD_SUCCESS = 'SAVE_DICTIONARY_CARD_SUCCESS';
const SAVE_DICTIONARY_CARD_ERROR = 'SAVE_DICTIONARY_CARD_ERROR';

const DELETE_DICTIONARY_ENTRY_REQUEST = 'DELETE_DICTIONARY_ENTRY_REQUEST';
const DELETE_DICTIONARY_ENTRY_SUCCESS = 'DELETE_DICTIONARY_ENTRY_SUCCESS';
const DELETE_DICTIONARY_ENTRY_ERROR = 'DELETE_DICTIONARY_ENTRY_ERROR';

const DELETE_DICTIONARY_ROWS_REQUEST = 'DELETE_DICTIONARY_ROWS_REQUEST';
const DELETE_DICTIONARY_ROWS_SUCCESS = 'DELETE_DICTIONARY_ROWS_SUCCESS';
const DELETE_DICTIONARY_ROWS_ERROR = 'DELETE_DICTIONARY_ROWS_ERROR';

const GET_DICTIONARY_CARD_DEFAULT_VALUE_REQUEST = 'GET_DICTIONARY_CARD_DEFAULT_VALUE_REQUEST';
const GET_DICTIONARY_CARD_DEFAULT_VALUE_SUCCESS = 'GET_DICTIONARY_CARD_DEFAULT_VALUE_SUCCESS';
const GET_DICTIONARY_CARD_DEFAULT_VALUE_ERROR = 'GET_DICTIONARY_CARD_DEFAULT_VALUE_ERROR';

const RUN_CONFIRM_ACTION = 'RUN_CONFIRM_ACTION';

const CLEAR_DICTIONARY_INFO = 'CLEAR_DICTIONARY_INFO';
const CLEAR_DICTIONARY_CARD = 'CLEAR_DICTIONARY_CARD';
const CLEAR_ERROR = 'CLEAR_ERROR';

const TOGGLE_ACTIVE_REQUEST = 'TOGGLE_ACTIVE_REQUEST';
const TOGGLE_ACTIVE_SUCCESS = 'TOGGLE_ACTIVE_SUCCESS';
const TOGGLE_ACTIVE_ERROR = 'TOGGLE_ACTIVE_ERROR';

const GET_ALL_IDS_REQUEST = 'GET_ALL_IDS_REQUEST';
const GET_ALL_IDS_SUCCESS = 'GET_ALL_IDS_SUCCESS';
const GET_ALL_IDS_ERROR = 'GET_ALL_IDS_ERROR';

//*  INITIAL STATE  *//

const initial = {
    list: [],
    card: {},
    totalCount: 0,
    error: [],
    progress: false,
    cardProgress: false,
    importProgress: false,
    exportProgress: false,
    listForModal: []
};

//*  REDUCER  *//

export default (state = initial, { type, payload }) => {
    switch (type) {
        case GET_DICTIONARY_LIST_REQUEST:
        case SAVE_DICTIONARY_CARD_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_DICTIONARY_CARD_REQUEST:
            return {
                ...state,
                cardProgress: true,
            };
        case GET_DICTIONARY_LIST_SUCCESS:
            return {
                ...state,
                list: !payload.isTableModal
                    ? (payload.isConcat ? [...state.list, ...payload.items] : payload.items)
                    : state.list,
                listForModal: payload.isTableModal ? payload.items : state.listForModal,
                progress: false,
                totalCount: payload.totalCount,
            };
        case GET_DICTIONARY_LIST_ERROR:
            return {
                ...state,
                progress: false,
                list: [],
                totalCount: 0,
            };
        case GET_DICTIONARY_CARD_SUCCESS:
            return {
                ...state,
                cardProgress: false,
                card: payload,
            };
        case GET_DICTIONARY_CARD_ERROR:
            return {
                ...state,
                card: {},
                cardProgress: false,
            };
        case CLEAR_DICTIONARY_INFO:
            return {
                ...state,
                ...initial,
            };
        case CLEAR_DICTIONARY_CARD:
            return {
                ...state,
                card: {},
                error: [],
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: state.error && state.error.filter(item => item.name !== payload),
            };
        case SAVE_DICTIONARY_CARD_SUCCESS:
            return {
                ...state,
                error: [],
                progress: false,
            };
        case SAVE_DICTIONARY_CARD_ERROR:
            return {
                ...state,
                error: payload,
                progress: false,
            };
        case DELETE_DICTIONARY_ENTRY_ERROR:
            return {
                ...state,
                error: payload,
                progress: false,
            };
        case DELETE_DICTIONARY_ROWS_ERROR:
            return {
                ...state,
                error: payload,
                progress: false,
            };
        case DICTIONARY_IMPORT_FROM_EXCEL_REQUEST:
            return {
                ...state,
                importProgress: true,
            };
        case DICTIONARY_IMPORT_FROM_EXCEL_SUCCESS:
        case DICTIONARY_IMPORT_FROM_EXCEL_ERROR:
            return {
                ...state,
                importProgress: false,
            };
        case DICTIONARY_EXPORT_TO_EXCEL_REQUEST:
            return {
                ...state,
                exportProgress: true,
            };
        case DICTIONARY_EXPORT_TO_EXCEL_SUCCESS:
        case DICTIONARY_EXPORT_TO_EXCEL_ERROR:
            return {
                ...state,
                exportProgress: false,
            };
        case GET_DICTIONARY_CARD_DEFAULT_VALUE_SUCCESS:
            return {
                ...state,
                card: payload,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export const getListRequest = payload => {
    return {
        type: GET_DICTIONARY_LIST_REQUEST,
        payload,
    };
};

export const getCardRequest = payload => {
    return {
        type: GET_DICTIONARY_CARD_REQUEST,
        payload,
    };
};

export const saveDictionaryCardRequest = payload => {
    return {
        type: SAVE_DICTIONARY_CARD_REQUEST,
        payload,
    };
};

export const clearDictionaryInfo = () => {
    return {
        type: CLEAR_DICTIONARY_INFO,
    };
};

export const clearDictionaryCard = () => {
    return {
        type: CLEAR_DICTIONARY_CARD,
    };
};

export const importFromExcelRequest = payload => {
    return {
        type: DICTIONARY_IMPORT_FROM_EXCEL_REQUEST,
        payload,
    };
};

export const exportToExcelRequest = payload => {
    return {
        type: DICTIONARY_EXPORT_TO_EXCEL_REQUEST,
        payload,
    };
};

export const clearError = payload => {
    return {
        type: CLEAR_ERROR,
        payload,
    };
};

export const deleteDictionaryEntryRequest = payload => {
    return {
        type: DELETE_DICTIONARY_ENTRY_REQUEST,
        payload,
    };
};

export const deleteDictionaryRowsRequest = payload => {
    return {
        type: DELETE_DICTIONARY_ROWS_REQUEST,
        payload,
    };
};

export const getDictionaryCardDefaultValueRequest = payload => {
    return {
        type: GET_DICTIONARY_CARD_DEFAULT_VALUE_REQUEST,
        payload,
    };
};

const runConfirmAction = payload => {
    return {
        type: RUN_CONFIRM_ACTION,
        payload,
    }
};

export const toggleActiveRequest = payload => {
    return {
        type: TOGGLE_ACTIVE_REQUEST,
        payload,
    }
};

export const getAllIdsRequest = payload => {
    return {
        type: GET_ALL_IDS_REQUEST,
        payload,
    };
};

//*  SELECTORS *//

const stateSelector = state => state.dictionaryView;
const getKey = (state, key = 'progress') => key;
const stateProfile = state => state.profile;
const dictionaryName = (state, name) => name;

export const columnsFromCardSelector = createSelector([stateProfile, dictionaryName], (state, name) => {
    const dictionary = state.dictionaries && state.dictionaries.find(item => item.name === name);
    return dictionary ? dictionary.columns.filter(column => (column.displayMode === 0 || column.displayMode === 2) && !column.isVisibleFieldFor) : [];
});

export const columnsFromListSelector = createSelector([stateProfile, dictionaryName], (state, name) => {

    const dictionary = state.dictionaries && state.dictionaries.find(item => item.name === name);
    const columnsWithStyles = ['transportCompanyName', 'transportCompanyId'];
    if (name === 'documentTemplates') columnsWithStyles.push('name', 'description');
    let columns = dictionary ? dictionary.columns.filter(column => column.type !== HIDDEN_TYPE && (column.displayMode === 0 || column.displayMode === 1)).map(column =>
        (columnsWithStyles.includes(column.name)) ? {
            ...column, style: {
                maxWidth: '500px',
                minWidth: '300px',
                wordWrap: 'break-word',
                whiteSpace: 'normal'
            }
        } : column) : [];

    let cols = (columns.length && columns.find(item => item.isDefault)) ? columns.filter(item => item.isDefault) : columns;
    if (name === 'transportCompanies' || name === 'transportCompanyServiceTypes') {
        const activeTypeCols = ['isAuctionsAvailable', 'isPaymentTermsReducedShipping', 'isPaymentTermsReducedRegistry', 'workByVAT', 'isDriverAppAvailable', 'autoApprovalNewDriversAndVehicles', 'worksFromScans'];
        cols = cols.map(col => ({
            ...col,
            type: activeTypeCols.includes(col.name) ? ACTIVE_TYPE : col.type
        }));
    }

    return cols;
});

export const isReadOnlyFieldsSelector = createSelector([stateProfile, dictionaryName], (state, name) => {
    const dictionary = state.dictionaries && state.dictionaries.find(item => item.name === name);
    let obj = {};
    (dictionary ? dictionary.columns.filter(column => column.isReadOnlyFieldFor && column.isReadOnlyFieldFor.length ) : []).forEach(item => {
        item.isReadOnlyFieldFor.forEach(i => {
            obj = {
                ...obj,
                [i]: item.name
            }
        })
    });

    return obj;
});

export const isVisibleFieldsSelector = createSelector([stateProfile, dictionaryName], (state, name) => {
    const dictionary = state.dictionaries && state.dictionaries.find(item => item.name === name);
    let obj = {};
    (dictionary ? dictionary.columns.filter(column => column.isVisibleFieldFor) : []).forEach(item => {
        obj = {
            ...obj,
            [item.isVisibleFieldFor]: item.name
        }
    });

    return obj;
});

export const progressSelector = createSelector(stateSelector, state => state.progress);
export const listForModalSelector = createSelector(stateSelector, state => state.listForModal);
export const cardProgressSelector = createSelector(stateSelector, state => state.cardProgress);
export const totalCountSelector = createSelector(stateSelector, state => state.totalCount);
export const listSelector = createSelector(stateSelector, state => state.list);
export const bannersListSelector = createSelector(stateSelector,
    state => {
        let banners = [];
        state.list.forEach(row => {
            banners.push({
                ...row,
                createAtLocal: dateToUTC(row.createAt, 'DD.MM.YYYY HH:mm'),
                period: row.showFrom || row.showTo ? `${row.showFrom ? row.showFrom : '...'} - ${row.showTo ? row.showTo : '...'}` : '',
            })
        });
        return banners;
    }
);
export const cardSelector = createSelector(stateSelector, state => state.card);
export const errorSelector = createSelector(stateSelector, state => errorMapping(state.error));

export const canCreateByFormSelector = createSelector(
    [stateProfile, dictionaryName],
    (state, name) => {
        const dictionary =
            state.dictionaries && state.dictionaries.find(item => item.name === name);
        return dictionary ? dictionary.canCreateByForm : false;
    },
);

export const canImportFromExcelSelector = createSelector(
    [stateProfile, dictionaryName],
    (state, name) => {
        const dictionary =
            state.dictionaries && state.dictionaries.find(item => item.name === name);
        return dictionary ? dictionary.canImportFromExcel : false;
    },
);

export const canExportToExcelSelector = createSelector(
    [stateProfile, dictionaryName],
    (state, name) => {
        const dictionary =
            state.dictionaries && state.dictionaries.find(item => item.name === name);
        return dictionary ? dictionary.canExportToExcel : false;
    },
);

export const trackChangesSelector = createSelector(
    [stateProfile, dictionaryName],
    (state, name) => {
        const dictionary =
            state.dictionaries && state.dictionaries.find(item => item.name === name);
        return dictionary ? dictionary.trackChanges : false;
    },
);

export const checkboxRowsSelector = createSelector(
    [stateProfile, dictionaryName],
    (state, name) => {
        const dictionary =
            state.dictionaries && state.dictionaries.find(item => item.name === name);
        return dictionary ? dictionary.haveCheckboxes : false;
    },
);

export const canDeleteSelector = createSelector([stateProfile, dictionaryName], (state, name) => {
    const dictionary = state.dictionaries && state.dictionaries.find(item => item.name === name);
    return dictionary ? dictionary.canDelete : false;
});

export const setNotificationsSelector = createSelector(
    [
        stateProfile,
        dictionaryName,
        state => userPermissionsSelector(state)
    ],
    (state, name, permissions) => {
    return name === 'transportCompanies' && permissions.includes(45);
});

export const importProgressSelector = createSelector(stateSelector, state => state.importProgress);
export const exportProgressSelector = createSelector(stateSelector, state => {
    return state.exportProgress;
});

//*  SAGA  *//

export function* getListSaga({ payload }) {
    try {
        const { filter = {}, name, isConcat, scrollTop, isTableModal = false } = payload;

        const result = yield postman.post(`/${name}/search`, filter);

        yield put({ type: GET_DICTIONARY_LIST_SUCCESS, payload: { ...result, isConcat, isTableModal } });
        scrollTop && scrollTop();
    } catch (error) {
        yield put({ type: GET_DICTIONARY_LIST_ERROR });
    }
}

function* getCardSaga({ payload }) {
    try {
        const { name, id, redirectCallback } = payload;
        const result = yield postman.get(`${name}/getById/${id}`);
        if (result.needRedirection) {
            yield put(
                showModal({
                    text: result.message,
                    callbackSuccess: redirectCallback,
                }),
            );
        } else {
            yield put({ type: GET_DICTIONARY_CARD_SUCCESS, payload: result.result });
        }

    } catch (error) {
        yield put({ type: GET_DICTIONARY_CARD_ERROR });
    }
}

function* saveDictionaryCardSaga({ payload }) {
    try {
        const { params, name, changedProps, callbackSuccess } = payload;
        const result = yield postman.post(`/${name}/saveOrCreate`, {data: params, changedProps});

        if (result.isError) {
            if (result.confirmationButtons && result.confirmationButtons.length) {
                yield put({
                    type: SAVE_DICTIONARY_CARD_ERROR,
                    payload: [],
                });
                yield put(
                    showModal({
                        text: result.message,
                        message: result.title
                        ,
                        buttons: result.confirmationButtons.map(item => ({
                            text: item.text,
                            type: item.buttonType,
                            action: item.buttonType === 0 ? () => hideModal() : () => runConfirmAction({url: item.url, data: item.data, callbackSuccess})
                        }))
                    }),
                );
            } else {
                toast.error(result.message);
                yield put({
                    type: SAVE_DICTIONARY_CARD_ERROR,
                    payload: result.errors,
                });
            }
        } else {
            yield put({
                type: SAVE_DICTIONARY_CARD_SUCCESS,
            });

            if (result.message && result.message !== '') toast.info(result.message);
            callbackSuccess && callbackSuccess(result.id, result.result);
        }
    } catch (e) {
        yield put({
            type: SAVE_DICTIONARY_CARD_ERROR,
        });
    }
}

function* importFromExcelSaga({ payload }) {
    try {
        const { form, name, callbackSuccess } = payload;
        const result = yield postman.post(`${name}/importFromExcel`, form, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        if (result.isError) {
            toast.error(result.message, {
                autoClose: false,
            });
        } else {
            result.message && toast.info(result.message, {
                autoClose: false,
            });

            yield put({
                type: DICTIONARY_IMPORT_FROM_EXCEL_SUCCESS,
            });

            callbackSuccess && callbackSuccess();
        }
    } catch (e) {
        yield put({
            type: DICTIONARY_IMPORT_FROM_EXCEL_ERROR,
        });
    }
}

function* exportToExcelSaga({ payload }) {
    try {
        const { name, filter } = payload;
        const res = yield downloader.post(`/${name}/exportToExcel`, filter.filter, {
            responseType: 'blob',
        });
        downloadFile(res);
        yield put({ type: DICTIONARY_EXPORT_TO_EXCEL_SUCCESS });
    } catch (e) {
        yield put({
            type: DICTIONARY_EXPORT_TO_EXCEL_ERROR,
        });
    }
}

function* deleteDictionaryEntrySaga({ payload }) {
    try {
        const { name, id, callbackSuccess } = payload;
        const result = yield postman.delete(`/${name}/delete`, { params: { id } });

        if (result.isError) {
            toast.error(result.message);
        };

        yield put({
            type: DELETE_DICTIONARY_ENTRY_SUCCESS,
        });

        callbackSuccess && callbackSuccess();
    } catch (e) {
        yield put({
            type: DELETE_DICTIONARY_ENTRY_ERROR,
        });
    }
}

function* deleteDictionaryRowsSaga({ payload }) {
    try {
        const { name, ids, callbackSuccess } = payload;
        const result = yield postman.delete(`/${name}/multiDelete`,  {params : {ids} });

        if (result.isError) {
            toast.error(result.message);
        }

        yield put({
            type: DELETE_DICTIONARY_ROWS_SUCCESS,
        });

        callbackSuccess && callbackSuccess();
    } catch (e) {
        yield put({
            type: DELETE_DICTIONARY_ROWS_ERROR,
        });
    }
}

function* getDictionaryCardDefaultValueSaga({ payload }) {
    try {
        const result = yield postman.get(`/${payload}/defaults`);

        yield put({
            type: GET_DICTIONARY_CARD_DEFAULT_VALUE_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({
            type: GET_DICTIONARY_CARD_DEFAULT_VALUE_ERROR,
        });
    }
}

function* runConfirmActionSaga({ payload }) {
    try {
        const {url, data, callbackSuccess} = payload;

        const result = yield postman.post(url, data);

        yield put(hideModal());

        callbackSuccess && callbackSuccess(result.id, result.result, true);

    } catch (e) {
        console.log('--error')
    }
}

function* toggleActiveSaga({ payload }) {
    try {
        const { id, active, callbackSuccess, name, keyName } = payload;
        const result = yield postman.post(`/${name}/${keyName}/${id}/${active}`);
        yield put({
            type: TOGGLE_ACTIVE_SUCCESS,
        });
        callbackSuccess && callbackSuccess();
    } catch (e) {
        yield put({
            type: TOGGLE_ACTIVE_ERROR,
            payload: e,
        });
    }
}

function* getAllIdsSaga({ payload }) {
    try {
        const { filter, name, callbackSuccess } = payload;
        const result = yield postman.post(`/${name}/ids`, filter);

        yield put({
            type: GET_ALL_IDS_SUCCESS,
            payload: result,
        });

        callbackSuccess && callbackSuccess(result);
    } catch (e) {
        yield put({
            type: GET_ALL_IDS_ERROR,
            payload: e,
        });
    }
}

export function* saga() {
    yield all([
        takeEvery(GET_DICTIONARY_LIST_REQUEST, getListSaga),
        takeEvery(GET_DICTIONARY_CARD_REQUEST, getCardSaga),
        takeEvery(SAVE_DICTIONARY_CARD_REQUEST, saveDictionaryCardSaga),
        takeEvery(DICTIONARY_IMPORT_FROM_EXCEL_REQUEST, importFromExcelSaga),
        takeEvery(DICTIONARY_EXPORT_TO_EXCEL_REQUEST, exportToExcelSaga),
        takeEvery(DELETE_DICTIONARY_ENTRY_REQUEST, deleteDictionaryEntrySaga),
        takeEvery(DELETE_DICTIONARY_ROWS_REQUEST, deleteDictionaryRowsSaga),
        takeEvery(GET_DICTIONARY_CARD_DEFAULT_VALUE_REQUEST, getDictionaryCardDefaultValueSaga),
        takeEvery(RUN_CONFIRM_ACTION, runConfirmActionSaga),
        takeEvery(TOGGLE_ACTIVE_REQUEST, toggleActiveSaga),
        takeEvery(GET_ALL_IDS_REQUEST, getAllIdsSaga),
    ]);
}
