import React from 'react';
import Report from "./report";
import {TRADING_REPORT_TYPE} from "../../constants/reportType";
import {DATE_TYPE, MULTISELECT_NEW_TYPE, SELECT_TYPE} from "../../constants/columnTypes";
import {dateToString} from "../../utils/dateTimeFormater";

const TradingReport = () => {
    const filterFields = [
        {
            name: 'shippingIds',
            type: MULTISELECT_NEW_TYPE,
            source: `${TRADING_REPORT_TYPE}/shippingNumbers`
        },
        {
            name: 'transportCompanyIds',
            type: MULTISELECT_NEW_TYPE,
            source: `${TRADING_REPORT_TYPE}/transportCompanies`
        },
        {
            name: 'startDate',
            type: DATE_TYPE,
        },
        {
            name: 'endDate',
            type: DATE_TYPE,
        },
        {
            isTranslate: true,
            name: 'dateType',
            type: SELECT_TYPE,
            source: `tradingReportDateType`
        },
        {
            name: 'fromWhere',
            type: MULTISELECT_NEW_TYPE,
            source: `${TRADING_REPORT_TYPE}/whereFrom`
        },
        {
            name: 'whereTo',
            type: MULTISELECT_NEW_TYPE,
            source: `${TRADING_REPORT_TYPE}/whereTo`
        },
    ]

    return (
        <Report
            name={TRADING_REPORT_TYPE}
            initialParams={{
                startDate: dateToString(),
                endDate: dateToString(),
            }}
            filterFields={filterFields}
        />
    );
};

export default TradingReport;