import React from 'react';
import {useTranslation} from 'react-i18next';
import Information from './regTransportCompanyTabs/information';
import {useSelector} from 'react-redux';
import CardLayout from '../../../components/CardLayout';
import DocumentsRegTC from "./regTransportCompanyTabs/documents";
import History from "./shared/history";
import {SETTINGS_TYPE_HIDE} from "../../../constants/formTypes";
import {documentsTemplatesLoadingSelector} from "../../../ducks/documents";
import Training from "./regTransportCompanyTabs/training";

const RegistrationTCCard = ({
                                form,
                                onChangeForm,
                                name,
                                id,
                                onClose: beforeClose,
                                settings,
                                title,
                                actionsFooter,
                                onClose,
                                actionsHeader,
                                loading,
                                load,
                                error,
                                updateHistory,
                                //saveForm,
                                isRegTC = false,
                                scrollTo = null
                            }) => {
    const {t} = useTranslation();

    const canScroll = !useSelector(state => documentsTemplatesLoadingSelector(state));

    const getPanes = () => {
        const obj = [
            {
                menuItem: t('applicationForm'),
                render: () => (
                    <Information
                        form={form}
                        onChange={onChangeForm}
                        error={error}
                        settings={settings}
                        updateHistory={!isRegTC && updateHistory}
                        isRegTC={isRegTC}
                    />
                ),
            },
            {
                menuItem: t('documents'),
                disabled: settings && settings['documents'] === SETTINGS_TYPE_HIDE,
                isOpen: !(settings && settings['documents'] === SETTINGS_TYPE_HIDE),
                render: () => (
                    <DocumentsRegTC
                        form={form}
                        onChange={onChangeForm}
                        error={error}
                        settings={settings}
                        updateHistory={!isRegTC && updateHistory}
                        isRegTC={isRegTC}
                    />
                ),
            }
        ];

        if (form.isEducationVisiable) obj.splice(1, 0, {
            menuItem: t('training'),
            render: () => <Training form={form}/>,
        });

        if (!isRegTC) obj.push({
            menuItem: t('history'),
            render: () => <History cardId={id} status={form.status}/>,
        });

        return obj;
    };

    return (
        <>
            <CardLayout
                title={title}
                actionsFooter={actionsFooter}
                actionsHeader={actionsHeader}
                content={getPanes}
                onClose={onClose}
                loading={loading}
                scrollTo={canScroll && scrollTo}
            />
        </>
    );
};

export default RegistrationTCCard;
