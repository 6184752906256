import React from 'react';
import {cargoUnitsFields, stopperFields} from "../../../../constants/stoppers";
import FormFields from "../../../../components/Form/Form";
import FormTable from "../../../../components/Form/FormTable";
import {arrayToColumnsConfig} from "../../../../utils/sort";
import { SETTINGS_TYPE_SHOW } from '../../../../constants/formTypes';

const Stopper = ({form, onChange, settings, stopper, index, error}) => {
    const nameError = (name, index) => {
        return `stoppers_${name}_${index}`;
    }

    const onChangeValue = (e, {value, name}, nameErr) => {
        let newValue = [...form.stoppers];

        newValue[index] = {
            ...newValue[index],
            [name]: value
        };

        onChange(e, {
            value: newValue,
            name: 'stoppers'
        }, nameErr || nameError(name, index))
    };

    const fields = [
        'pointType',
        'clientName',
        'countryId',
        'cityId',
        'postalCode',
        'address',
        'dateTime',
        'instruction',
    ]

    const customDisplayNames = [
        {
            name: 'clientName',
            key: (stopper.pointType || {}).value
                ? stopper.pointType.value === 'unloading'
                    ? 'stopper_clientNameDelivery'
                    : stopper.pointType.value === 'uploading'
                        ? 'stopper_clientNameLoading'
                        : 'stopper_clientNameCheckPoint'
                : null
        }
    ]

    return (
        <div>
            <FormFields
                form={stopper}
                error={error}
                settings={settings}
                handleChange={onChangeValue}
                columnsConfig={arrayToColumnsConfig(stopperFields, customDisplayNames)}
                columnsArray={fields}
                index={index}
                colCount={2}
                nameError={nameError}
            />
            <FormTable
                label='cargoUnits'
                name='cargoUnits'
                form={stopper}
                columnsConfig={arrayToColumnsConfig(cargoUnitsFields)}
                columnsArray={cargoUnitsFields.map(c => c.name)}
                onChange={onChangeValue}
                index={index}
                error={error}
                isDisabled={settings.cargoUnitsDisplay === SETTINGS_TYPE_SHOW}
            />
        </div>
    );
};

export default Stopper;