import React from 'react'
import {useTranslation} from 'react-i18next'
import {Form, Input} from 'semantic-ui-react'

const PhoneNumber = ({
                         value,
                         name,
                         onChange,
                         onBlur,
                         isDisabled,
                         noLabel,
                         className,
                         text,
                         error,
                         type,
                         datalist: valuesList = [],
                         placeholder,
                         isRequired,
                         autoComplete,
                         autoFocus,
                         warningText
                     }) => {
    const {t} = useTranslation()

    const onChangeValue = (e, {value}) => {
        const regex = /^\+?\d*$/;
        const isValid = !value || regex.test(value);
        isValid && onChange(e, {value, name})
    }

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `${t(text || name)}${isRequired ? ' *' : ''}`,
                        }}
                    />
                    {warningText && <span className='label-warning-text'> ({t(warningText)})</span>}
                </label>
            ) : null}

            <Input
                value={value || ''}
                onChange={onChangeValue}
                placeholder={placeholder}
                list={valuesList && name}
                className={className}
                type={type}
                disabled={isDisabled}
                name={name}
                onBlur={onBlur}
                autoFocus={autoFocus}
                autoComplete={autoComplete}
                error={error}
            />

            {error && typeof error === 'string' ? (
                <span className="label-error" dangerouslySetInnerHTML={{__html: error}}/>
            ) : null}
            {valuesList && valuesList.length ? (
                <datalist id={name}>
                    {valuesList.map(item => (
                        <option key={item.id} value={item.name}/>
                    ))}
                </datalist>
            ) : null}
        </Form.Field>
    )
}

export default PhoneNumber
