import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { postman } from '../utils/postman';
import { createSelector } from 'reselect';
import {toast} from "react-toastify";

//*  TYPES  *//

const LATES_DELETE_DOC_REQUEST = 'LATES_DELETE_DOC_REQUEST';
const LATES_DELETE_DOC_SUCCESS = 'LATES_DELETE_DOC_SUCCESS';
const LATES_DELETE_DOC_ERROR = 'LATES_DELETE_DOC_ERROR';

const ITEMS_DELETE_REQUEST = 'ITEMS_DELETE_REQUEST';
const ITEMS_DELETE_SUCCESS = 'ITEMS_DELETE_SUCCESS';
const ITEMS_DELETE_ERROR = 'ITEMS_DELETE_ERROR';

const SEND_FEEDBACK_REQUEST = 'SEND_FEEDBACK_REQUEST';
const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS';
const SEND_FEEDBACK_ERROR = 'SEND_FEEDBACK_ERROR';
const SET_FEEDBACK_ERRORS = 'SET_FEEDBACK_ERRORS';

const GET_CONTACTS_FM_REQUEST = 'GET_CONTACTS_FM_REQUEST';
const GET_CONTACTS_FM_SUCCESS = 'GET_CONTACTS_FM_SUCCESS';
const GET_CONTACTS_FM_ERROR = 'GET_CONTACTS_FM_ERROR';

const GET_LINKS_REQUEST = 'GET_LINKS_REQUEST';
const GET_LINKS_SUCCESS = 'GET_LINKS_SUCCESS';
const GET_LINKS_ERROR = 'GET_LINKS_ERROR';

const COMPANY_STATISTICS_REQUEST = 'COMPANY_STATISTICS_REQUEST';
const COMPANY_STATISTICS_SUCCESS = 'COMPANY_STATISTICS_SUCCESS';
const COMPANY_STATISTICS_ERROR = 'COMPANY_STATISTICS_ERROR';

const COMPANY_YEARS_STAT_REQUEST = 'COMPANY_YEARS_STAT_REQUEST';
const COMPANY_YEARS_STAT_SUCCESS = 'COMPANY_YEARS_STAT_SUCCESS';
const COMPANY_YEARS_STAT_ERROR = 'COMPANY_YEARS_STAT_ERROR';

const COMPANY_RANK_REQUEST = 'COMPANY_RANK_REQUEST';
const COMPANY_RANK_SUCCESS = 'COMPANY_RANK_SUCCESS';
const COMPANY_RANK_ERROR = 'COMPANY_RANK_ERROR';

const DEFAULT_PARAMS_REQUEST = 'DEFAULT_PARAMS_REQUEST';
const DEFAULT_PARAMS_SUCCESS = 'DEFAULT_PARAMS_SUCCESS';
const DEFAULT_PARAMS_ERROR = 'DEFAULT_PARAMS_ERROR';

const DOCUMENTS_AND_INSTRUCTIONS_REQUEST = 'DOCUMENTS_AND_INSTRUCTIONS_REQUEST';
const DOCUMENTS_AND_INSTRUCTIONS_SUCCESS = 'DOCUMENTS_AND_INSTRUCTIONS_SUCCESS';
const DOCUMENTS_AND_INSTRUCTIONS_ERROR = 'DOCUMENTS_AND_INSTRUCTIONS_ERROR';

const SET_PARAMS = 'SET_PARAMS';

//*  INITIAL STATE  *//

const initial = {
    deleteLoading: false,
    feedbackLoading: false,
    progress: false,
    links: [],
    error: null,
    companyStatistics: null,
    companyStatisticsLoad: false,
    companyYearsStat: null,
    companyYearsStatLoad: false,
    companyRank: false,
    companyRankLoad: false,
    defaultParams: null,
    params: {},
    docsAndInstructions: [],
};

//*  REDUCER  *//

export default (state = initial, { type, payload }) => {
    switch (type) {
        case LATES_DELETE_DOC_REQUEST:
        case ITEMS_DELETE_REQUEST:
            return {
                ...state,
                deleteLoading: true,
            };
        case LATES_DELETE_DOC_SUCCESS:
        case LATES_DELETE_DOC_ERROR:
        case ITEMS_DELETE_SUCCESS:
        case ITEMS_DELETE_ERROR:
            return {
                ...state,
                deleteLoading: false,
            };
        case SEND_FEEDBACK_REQUEST:
            return {
                ...state,
                feedbackLoading: true,
            };
        case SEND_FEEDBACK_SUCCESS:
            return {
                ...state,
                feedbackLoading: false,
            };
        case SEND_FEEDBACK_ERROR:
            return {
                ...state,
                feedbackLoading: false,
                error: payload
            };
        case SET_FEEDBACK_ERRORS:
            return {
                ...state,
                error: payload
            };
        case GET_CONTACTS_FM_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_CONTACTS_FM_SUCCESS:
            return {
                ...state,
                list: payload.isConcat ? [...state.list, ...payload.items] : payload.items,
                progress: false,
                totalCount: payload.totalCount,
            };
        case GET_CONTACTS_FM_ERROR:
            return {
                ...state,
                progress: false,
                list: [],
                totalCount: 0,
            };
        case GET_LINKS_REQUEST:
            return {
                ...state,
                progress: true,
                links: [],
            };
        case GET_LINKS_SUCCESS:
            return {
                ...state,
                progress: false,
                links: payload,
            };
        case GET_LINKS_ERROR:
            return {
                ...state,
                progress: false,
                links: [],
            };
        case COMPANY_STATISTICS_REQUEST:
            return {
                ...state,
                companyStatisticsLoad: true
            };
        case COMPANY_STATISTICS_SUCCESS:
            return {
                ...state,
                companyStatisticsLoad: false,
                companyStatistics: payload
            };
        case COMPANY_STATISTICS_ERROR:
            return {
                ...state,
                companyStatisticsLoad: false,
            };
        case COMPANY_YEARS_STAT_REQUEST:
            return {
                ...state,
                companyYearsStatLoad: true
            };
        case COMPANY_YEARS_STAT_SUCCESS:
            return {
                ...state,
                companyYearsStat: payload,
                companyYearsStatLoad: false
            };
        case COMPANY_YEARS_STAT_ERROR:
            return {
                ...state,
                companyYearsStatLoad: false,
            };
        case COMPANY_RANK_REQUEST:
            return {
                ...state,
                companyRankLoad: true
            };
        case COMPANY_RANK_SUCCESS:
            return {
                ...state,
                companyRank: payload,
                companyRankLoad: false
            };
        case COMPANY_RANK_ERROR:
            return {
                ...state,
                companyRankLoad: false,
            };
        case DEFAULT_PARAMS_SUCCESS:
            return {
                ...state,
                defaultParams: payload,
            };
        case DEFAULT_PARAMS_ERROR:
        case DEFAULT_PARAMS_REQUEST:
            return {
                ...state,
                defaultParams: null
            };
        case DOCUMENTS_AND_INSTRUCTIONS_REQUEST:
            return {
                ...state,
                progress: true
            };
        case DOCUMENTS_AND_INSTRUCTIONS_SUCCESS:
            return {
                ...state,
                progress: false,
                docsAndInstructions: payload
            };
        case DOCUMENTS_AND_INSTRUCTIONS_ERROR:
            return {
                ...state,
                progress: false,
                docsAndInstructions: []
            };
        case SET_PARAMS:
            return {
                ...state,
                params: {
                    ...state.params,
                    ...payload
                }
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export const latesDeleteDocsRequest = payload => {
    return {
        type: LATES_DELETE_DOC_REQUEST,
        payload,
    };
};

export const itemsDeleteRequest = payload => {
    return {
        type: ITEMS_DELETE_REQUEST,
        payload,
    };
};

export const sendFeedback = payload => {
    return {
        type: SEND_FEEDBACK_REQUEST,
        payload,
    };
};

export const setFeedbackErrors = payload => {
    return {
        type: SET_FEEDBACK_ERRORS,
        payload,
    };
};

export const getFmContacts = payload => {
    return {
        type: GET_CONTACTS_FM_REQUEST,
        payload,
    };
};

export const getLinks = payload => {
    return {
        type: GET_LINKS_REQUEST,
        payload,
    };
};

export const clearLinks = () => {
    return {
        type: GET_LINKS_ERROR
    };
};

export const getCompanyStats = payload => {
    return {
        type: COMPANY_STATISTICS_REQUEST,
        payload
    };
};

export const getYearsCompanyStats = payload => {
    return {
        type: COMPANY_YEARS_STAT_REQUEST,
        payload
    };
};

export const getRankCompany = payload => {
    return {
        type: COMPANY_RANK_REQUEST,
        payload
    };
};

export const getDefaultParams = payload => {
    return {
        type: DEFAULT_PARAMS_REQUEST,
        payload
    };
};

export const clearDefaultParams = () => {
    return {
        type: DEFAULT_PARAMS_ERROR,
    };
};

export const setParams = payload => {
    return {
        type: SET_PARAMS,
        payload
    };
};

export const getDocsAndInstructions = () => {
    return {
        type: DOCUMENTS_AND_INSTRUCTIONS_REQUEST
    };
};

//*  SELECTORS *//

const stateSelector = state => state.personalCabinet;
export const deleteLoadingSelector = createSelector(stateSelector, state => state.deleteLoading);
export const feedbackLoadingSelector = createSelector(stateSelector, state => state.feedbackLoading);
export const totalCountSelector = createSelector(stateSelector, state => state.totalCount);
export const listSelector = createSelector(stateSelector, state => state.list);
export const progressSelector = createSelector(stateSelector, state => state.progress);
export const companyYearsStatLoadSelector = createSelector(stateSelector, state => state.companyYearsStatLoad);
export const companyRankLoadSelector = createSelector(stateSelector, state => state.companyRankLoad);
export const companyRankSelector = createSelector(stateSelector, state => state.companyRank);
export const companyYearsStatSelector = createSelector(stateSelector, state => {
    let data = state.companyYearsStat;

    const getArray = (dt, name) => {
        const year = new Date().getFullYear();
        const month = new Date().getMonth();
        return dt[name].months.map((m, index) => (dt[name].year > year || (dt[name].year === year && index > month)) ? null : m.turnover);
    }

    if (data && data.fromYear && data.toYear) {
        data.fromYear.turnoverArray = getArray(data, 'fromYear');
        data.toYear.turnoverArray = getArray(data, 'toYear');
        data.years = {
            [data.fromYear.year]: data.fromYear.months,
            [data.toYear.year]: data.toYear.months
        }
    }
    return data;
});
export const companyStatisticsLoadSelector = createSelector(stateSelector, state => state.companyStatisticsLoad);
export const companyStatisticsSelector = createSelector(stateSelector, state => state.companyStatistics || {});
export const linksSelector = createSelector(stateSelector, state => state.links);
export const errorSelector = createSelector(stateSelector, state => state.error);
export const defaultParamsSelector = createSelector(stateSelector, state => state.defaultParams);
export const paramsSelector = createSelector(stateSelector, state => state.params);
export const docsAndInstructionsSelector = createSelector(stateSelector, state => state.docsAndInstructions);

//*  SAGA  *//

function* latesDeleteDocsSaga({ payload }) {
    try {
        const { ids, callback } = payload;
        const result = yield postman.post('/lates/deleteDocuments', ids);

        yield put({
            type: LATES_DELETE_DOC_SUCCESS,
            payload: result
        });
        callback && callback();
    } catch (error) {
        yield put({
            type: LATES_DELETE_DOC_ERROR,
            payload: error,
        });
    }
}

function* itemsDeleteSaga({ payload }) {
    try {
        const { name, ids, callback } = payload;
        const result = yield postman.post(`/${name}/delete`, ids);

        yield put({
            type: ITEMS_DELETE_SUCCESS,
            payload: result
        });
        callback && callback();
    } catch (error) {
        yield put({
            type: ITEMS_DELETE_ERROR,
            payload: error,
        });
    }
}

function* sendFeedbackSaga({ payload }) {
    try {
        const { comment, callback } = payload;
        const result = yield postman.post('/personalCabinet/contacts/feedback', {comment});

        const { isError, message = '' } = result;
        if (isError) {
            toast.error(message);
            yield put({
                type: SEND_FEEDBACK_ERROR,
                payload: message,
            });
        }
        else {
            message && toast.info(message);
            yield put({
                type: SEND_FEEDBACK_SUCCESS,
                payload: result
            });
            callback && callback();
        }
    } catch (error) {
        yield put({
            type: SEND_FEEDBACK_ERROR,
            payload: error,
        });
    }
}

function* getContactsFMSaga({ payload }) {
    try {
        const { filter = {}, isConcat, scrollTop, ...filters } = payload;

        const result = yield postman.post(`/personalCabinet/contacts/showContacts`, {...filter, ...filters});

        yield put({ type: GET_CONTACTS_FM_SUCCESS, payload: { ...result, isConcat } });
        scrollTop && scrollTop();
    } catch (error) {
        yield put({ type: GET_CONTACTS_FM_ERROR });
    }
}

function* getLinksSaga({ payload }) {
    try {
        const { type } = payload;
        const result = yield postman.post(`/informationBlockLink/getByType?type=${type}`);
        yield put({
            type: GET_LINKS_SUCCESS,
            payload: result
        });
    } catch (error) {
        yield put({ type: GET_LINKS_ERROR });
    }
}

function* getCompanyStatSaga({ payload }) {
    try {
        const { period, transportCompanyId } = payload;
        const result = yield postman.post(`/personalCabinet/home/getLeftSide`, {period, transportCompanyId});
        yield put({
            type: COMPANY_STATISTICS_SUCCESS,
            payload: result
        });
    } catch (error) {
        yield put({ type: COMPANY_STATISTICS_ERROR });
    }
}

function* getYearsCompanyStatSaga({ payload }) {
    try {
        const { period, transportCompanyId } = payload;
        const result = yield postman.post(`/personalCabinet/home/getRightSide`, {period, transportCompanyId});
        yield put({
            type: COMPANY_YEARS_STAT_SUCCESS,
            payload: result
        });
    } catch (error) {
        yield put({ type: COMPANY_YEARS_STAT_ERROR });
    }
}

function* getRankCompanySaga({ payload }) {
    try {
        const { period, transportCompanyId } = payload;
        const result = yield postman.post(`/personalCabinet/home/getCompanyRank`, {period, transportCompanyId});
        yield put({
            type: COMPANY_RANK_SUCCESS,
            payload: result
        });
    } catch (error) {
        yield put({ type: COMPANY_RANK_ERROR });
    }
}

function* getDefaultParamsSaga() {
    try {
        const result = yield postman.get(`/personalCabinet/home/loadSettingSetup`);
        yield put({
            type: DEFAULT_PARAMS_SUCCESS,
            payload: result
        });
    } catch (error) {
        yield put({ type: DEFAULT_PARAMS_ERROR });
    }
}

function* getDocsAndInstructionsSaga() {
    try {
        const result = yield postman.get(`/personalCabinet/documentsAndInstructions`);
        yield put({
            type: DOCUMENTS_AND_INSTRUCTIONS_SUCCESS,
            payload: result
        });
    } catch (error) {
        yield put({ type: DOCUMENTS_AND_INSTRUCTIONS_ERROR });
    }
}

export function* saga() {
    yield all([
        takeEvery(LATES_DELETE_DOC_REQUEST, latesDeleteDocsSaga),
        takeEvery(SEND_FEEDBACK_REQUEST, sendFeedbackSaga),
        takeEvery(GET_CONTACTS_FM_REQUEST, getContactsFMSaga),
        takeEvery(GET_LINKS_REQUEST, getLinksSaga),
        takeEvery(ITEMS_DELETE_REQUEST, itemsDeleteSaga),
        takeLatest(COMPANY_STATISTICS_REQUEST, getCompanyStatSaga),
        takeLatest(COMPANY_YEARS_STAT_REQUEST, getYearsCompanyStatSaga),
        takeLatest(COMPANY_RANK_REQUEST, getRankCompanySaga),
        takeEvery(DEFAULT_PARAMS_REQUEST, getDefaultParamsSaga),
        takeEvery(DOCUMENTS_AND_INSTRUCTIONS_REQUEST, getDocsAndInstructionsSaga),
    ]);
}
