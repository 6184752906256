import React, {useState} from 'react';
import {Confirm, Icon, Popup, Tab} from 'semantic-ui-react';
import Stopper from './stopper';
import StopperCreate from '../shippingTabsCreate/stopper';
import {useTranslation} from "react-i18next";
import {useSelector} from 'react-redux';
import {
    settingsExtSelector
} from '../../../../ducks/gridCard';
import { SETTINGS_TYPE_EDIT } from '../../../../constants/formTypes';

const Stoppers = ({
                      form,
                      routeActiveIndex,
                      tabChange,
                      onChange,
                      updateHistory,
                      isCreate,
                      columnsConfig,
                      error
                  }) => {
    const {t} = useTranslation();
    const settings = useSelector(state => settingsExtSelector(state, form.status));
    const {stoppers: stoppers = []} = form;
    const stopperTabs = [];
    const [rowForDelete, setRowForDelete] = useState(null);

    const getIndexOfSamePointType = (index, array) => {
        const targetValue = (array[index].pointType || {}).value;
        let count = 0;

        for (let i = 0; i <= index; i++) {
            if ((array[i].pointType || {}).value === targetValue) {
                count++;
            }
        }

        return count;
    }

    const getStopperType = pointType => {
        if (pointType) {
            switch (pointType.value) {
                case 'uploading':
                    return t('uploadingPoint')
                case 'unloading':
                    return t('unloadingPoint')
                case 'checkPoint':
                    return t('checkPoint')
            }
        }

        return null
    }

    const canCreate = ['makingChangesToRoute'].includes(form.status) && settings.cargoUnitsDisplay === SETTINGS_TYPE_EDIT;

    stoppers && stoppers.forEach((stopper, i) => {
        stopperTabs.push({
            menuItem: {
                key: i,
                content: (
                    <label className='stopper-label'>
                        <div>{getStopperType(stopper.pointType) || stopper.stopperType || t('newPoint')} {stopper.index || getIndexOfSamePointType(i, stoppers)}</div>
                        {
                            (isCreate || canCreate) && <Popup
                                trigger={<Icon
                                    className='cursor-pointer'
                                    name='trash'
                                    onClick={() => setRowForDelete(i)}
                                />}
                                content={t('delete')}
                                position='top center'
                            />
                        }
                    </label>
                ),
            },
            render: () => {
                return (
                    (isCreate || canCreate)
                        ? <StopperCreate
                            error={error}
                            index={i}
                            form={form}
                            stopper={stopper}
                            settings={settings}
                            onChange={onChange}
                            columnsConfig={columnsConfig}
                        />
                        : <Stopper
                            index={i}
                            form={form}
                            stopper={stopper}
                            settings={settings}
                            onChange={onChange}
                            updateHistory={updateHistory}
                        />
                );
            },
        });
    });

    const add = () => {
        onChange(null, {
            name: 'stoppers',
            value: [...(stoppers || []), {}]
        })
        tabChange(null, {activeIndex: (stoppers || []).length});
    }

    const del = (index) => {
        onChange(null, {
            name: 'stoppers',
            value: stoppers.filter((s, i) => i !== index)
        });
        setRowForDelete(null);
        tabChange(null, {activeIndex: 0});
    }

    return (
        <div className="tabs-card">
            {
                (isCreate || canCreate) && <div className='white-btn'>
                    <a onClick={add}>
                        <Icon name='plus'/>
                        {t('addStopper')}
                    </a>
                </div>
            }
            {!!stopperTabs.length && <Tab
                className="all-tabs"
                panes={stopperTabs}
                activeIndex={routeActiveIndex}
                menu={{vertical: true}}
                menuPosition="left"
                onTabChange={tabChange}
            />}
            <Confirm
                open={rowForDelete !== null}
                content={t(`deleteItemConfirm`)}
                onCancel={() => setRowForDelete(null)}
                cancelButton={t('cancelConfirm')}
                onConfirm={() => del(rowForDelete)}
            />
        </div>
    );
};

export default Stoppers;
