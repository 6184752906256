import React, { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, Grid, Popup, Table, Icon, Pagination, Segment, Select, Dropdown } from 'semantic-ui-react'
import Date from '../../components/BaseComponents/Date'
import { dateToString, parseDate } from '../../utils/dateTimeFormater'
import {
    clearFiltersData,
    exportProgressSelector,
    filterSettingsSelector,
    getDataForReportRequest,
    getReportRequest,
    progressSelector,
    reportExportToExcelRequest,
    reportSelector,
    totalCountSelector,
} from '../../ducks/reports'
import {
    PERSONAL_CABINET_KPI_ANALYSIS_TYPE
} from '../../constants/reportType'
import { useTranslation } from 'react-i18next'
import Block from '../../components/CardLayout/components/block'
import MultiSelect from "../../components/BaseComponents/MultiSelect"
import MultiSelectIS from "../../components/BaseComponents/MultiSelectInfinityScroll"
import { options, optionsNumberOfTc } from "../../constants/paginationOptions"
import Select_new from "../../components/BaseComponents/Select_new"
import Bool from "../../components/BaseComponents/Bool"
import { GRID_CARD_LINK } from "../../router/links"
import { SHIPPINGS_GRID } from "../../constants/grids"
import { filtersFromSearch } from "../../utils/urlParamsHelper"
import useReactRouter from "use-react-router"

const controlNames = {
    dateType: 'dateType',
    startDate: 'startDate',
    endDate: 'endDate'
}

const KpiAnalysis = ({ params: globalParams }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { location } = useReactRouter()

    const initialParams = {
        startDate: dateToString(),
        endDate: dateToString(),
        dateType: null,
        transportCompanies: [],
        detalizationType: null,
        detalizationTypeValue: null,
        showShippingDetalization: null,
        showTransportCompanyDetalization: null,
    }

    let [params, setParams] = useState(initialParams)
    let [paramsOpen, setParamsOpen] = useState(true)
    let [click, setClick] = useState(false)
    let [tcDetalizationSettingView, setTcDetalizationSettingView] = useState(false)

    const [openGroupIds, setOpenGroupIds] = useState([])
    const [paginationOptions, setPaginationOptions] = useState({
        text: 'numberOfRows',
        options
    })

    const [page, setPage] = useState(1)
    const [count, setCount] = useState(25)

    let [height, setHeight] = useState(0)

    useEffect(() => {
        let el = document.getElementById('params')
        if (el) {
            setHeight(el.getBoundingClientRect().height)
        }
    }, [params, paramsOpen])

    const report = useSelector(reportSelector)
    const totalCount = useSelector(totalCountSelector)
    const exportProgress = useSelector(exportProgressSelector)
    const loading = useSelector(progressSelector)

    const loadData = () => {
        getReport()
        document.getElementsByClassName('card-content-block_open')[0].scrollTo(0, 0)
    }

    useEffect(() => {
        if (click) loadData()
    }, [page, count])


    const mapDataForGenerateReport = () => {
        delete params.trigger
        delete params.filtersIsLoad
        return {
            gridName: PERSONAL_CABINET_KPI_ANALYSIS_TYPE,
            params: {
                ...params,
                skip: count * (page - 1),
                take: count
            },
        }
    }

    const filterSettings = useSelector(state => filterSettingsSelector(state))

    useEffect(() => {
        dispatch(
            getDataForReportRequest({ gridName: PERSONAL_CABINET_KPI_ANALYSIS_TYPE }),
        )
        return () => dispatch(clearFiltersData())
    }, [])

    const setFilters = (trigger, filtersIsLoad) => {
        globalParams = { ...(globalParams || {}), ...(filtersFromSearch(location) || {}) }
        setParams(params => {
            return {
                ...params,
                transportCompanies: globalParams.company ? [JSON.parse(globalParams.company)] : (filterSettings.transportCompanies || []),
                dateType: filterSettings.dateType,
                detalizationType: filterSettings.detalizationType,
                detalizationTypeValue: filterSettings.detalizationTypeValue,
                showShippingDetalization: filterSettings.showShippingDetalization,
                showTransportCompanyDetalization: filterSettings.showTransportCompanyDetalization,
                startDate: globalParams.startDate ? globalParams.startDate : (filterSettings.shippings ? null : dateToString()),
                endDate: globalParams.endDate ? globalParams.endDate : (filterSettings.shippings ? null : dateToString()),
                trigger: params.trigger || trigger,
                filtersIsLoad: params.filtersIsLoad || filtersIsLoad
            }
        })
        setTcDetalizationSettingView(!filterSettings.transportCompanies)
    }

    useEffect(() => {
        setFilters(null, Object.keys(filterSettings || {}).length)
    }, [filterSettings, globalParams])

    useEffect(() => {
        setFilters(filtersFromSearch(location).trigger)
    }, [location])

    useEffect(() => {
        if (params.trigger && params.filtersIsLoad) generateReport()
    }, [params.trigger, params.filtersIsLoad])

    const getReport = () => {
        dispatch(getReportRequest(mapDataForGenerateReport()))
    }

    const handleChangeParams = (e, { name, value }) => {
        setParams(params => {
            const newParams = {
                ...params,
                [name]: value
            }

            if (name === controlNames.dateType) {
                if (value && !params.dateType) {
                    newParams[controlNames.startDate] = dateToString()
                    newParams[controlNames.endDate] = dateToString()
                } else if (!value) {
                    newParams[controlNames.startDate] = null
                    newParams[controlNames.endDate] = null
                }
            }

            if (name === 'detalizationType' && params.detalizationType !== value) {
                newParams['detalizationTypeValue'] = null
            }

            return newParams
        })
    }

    const exportExcel = () => {
        dispatch(reportExportToExcelRequest(mapDataForGenerateReport()))
    }

    const generateReport = () => {
        !click && setClick(true)
        if (params.showTransportCompanyDetalization && count > 5) {
            setPaginationOptions({
                options: optionsNumberOfTc,
                text: 'numberOfTCs'
            })
            setCount(3)
        } else if (!params.showTransportCompanyDetalization && count <= 5) {
            setPaginationOptions({
                options: options,
                text: 'numberOfRows'
            })
            setCount(25)
        } else loadData()
    }

    const columns = [
        { name: 'detalizationTypeValue' },
        { name: 'totalShippingsCount' },
        { name: 'loadingOnTimeShippingsCount' },
        { name: 'loadingLateShippingsCount' },
        { name: 'loadingKPI' },
        { name: 'unloadingOnTimeShippingsCount' },
        { name: 'unloadingLateShippingsCount' },
        { name: 'unloadingKPI' },
        { name: 'averageKPI' },
    ]

    const onClickViewGroupId = (id) => {
        let idHidden = !openGroupIds.includes(id)
        setOpenGroupIds(!idHidden ? openGroupIds.filter(h => h !== id) : [...openGroupIds, id])
    }

    const groupRow = (r, lvl) => {
        return <>
            <Table.Row key={'row_' + r.groupId}>
                {
                    columns.map((col, index) => (
                        <Table.Cell key={'col_' + r.groupId + index}
                            className={index === 0 ? 'column-facet__first' : 'kpi-cell-secondary'}>
                            {index === 0 &&
                                <Icon
                                    style={{ marginLeft: `${lvl * 5}px`, visibility: (!r.subRows || !r.subRows.length) && 'hidden' }}
                                    className='personal-cabinet__btn-plus'
                                    onClick={() => onClickViewGroupId(r.groupId)}
                                    name={!openGroupIds.includes(r.groupId) ? 'plus' : 'minus'} />
                            }
                            {(index === 0 && r.id) ? <a target="_blanc" href={GRID_CARD_LINK.replace(':name', SHIPPINGS_GRID).replace(':id', r.id)}>{r[col.name]}</a> : r[col.name]}
                        </Table.Cell>))
                }
            </Table.Row>
            {
                openGroupIds.includes(r.groupId) && r.subRows && r.subRows.map(sr => groupRow(sr, lvl + 3))
            }
        </>
    }

    const item = {
        menuItem: 'serviceTypeKpi',
        render: () => (
            <Table className='report_table-data' celled>
                {click && <Table.Header className='personal-cabinet__table_header'>
                    <Table.Row>
                        <Table.HeaderCell width={4} className='column-facet__first'
                            rowSpan='2'>{t('kpiTkDetalizationType')}</Table.HeaderCell>
                        <Table.HeaderCell width={2} rowSpan='2'>{t('kpiShippingsTotalCount')}</Table.HeaderCell>
                        <Table.HeaderCell width={4} className='column-header__statuses' colSpan='3'>{t('kpiLoading')}</Table.HeaderCell>
                        <Table.HeaderCell width={4} className='column-header__statuses' colSpan='3'>{t('kpiUnloading')}</Table.HeaderCell>
                        <Table.HeaderCell width={2} rowSpan='2'>{t('kpiAveragePercent')}</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row className='ext-header-row'>
                        <Table.HeaderCell className='column-header__status'>{t('kpiAtTime')}</Table.HeaderCell>
                        <Table.HeaderCell className='column-header__status'>{t('kpiLate')}</Table.HeaderCell>
                        <Table.HeaderCell className='column-header__status'>{t('kpiLoadingPercent')}</Table.HeaderCell>
                        <Table.HeaderCell className='column-header__status'>{t('kpiAtTime')}</Table.HeaderCell>
                        <Table.HeaderCell className='column-header__status'>{t('kpiLate')}</Table.HeaderCell>
                        <Table.HeaderCell className='column-header__status'>{t('kpiUnloadingPercent')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>}
                <Table.Body>
                    {click && report.map((row, indexRow) => (<Fragment key={'fr_' + indexRow}>
                        {row.transportCompanyTitle &&
                            <Table.Row className='personal-cabinet__table_header personal-cabinet__tc-name'>
                                <Table.Cell className='column-facet__first' colSpan={9}>
                                    {row.transportCompanyTitle}
                                </Table.Cell>
                            </Table.Row>}
                        {
                            row.rows.map(r => groupRow(r, 0))
                        }
                    </Fragment>
                    ))}
                </Table.Body>
            </Table>
        ),
        actions: () => [
            <Popup
                key='exportToExcel'
                content={t('exportToExcel')}
                position="bottom right"
                trigger={
                    <Button
                        icon="download"
                        disabled={!report.length || exportProgress}
                        loading={exportProgress}
                        onClick={exportExcel}
                    />
                }
            />
        ],
    }

    const pagination = <Segment className='report__pagination' basic>
        <Form.Field inline>
            <label>{t(paginationOptions.text)}</label>
            <Dropdown
                className='report__pagination-select'
                options={paginationOptions.options}
                selection
                text={count}
                onChange={(e, { value }) => {
                    setCount(value)
                    setPage(1)
                }}
            />
        </Form.Field>
        <Pagination
            activePage={page}
            onPageChange={(e, { activePage }) => setPage(activePage)}
            totalPages={Math.ceil(totalCount / count)}
        />
    </Segment>

    return (
        <div className="container personal-cabinet__page">
            <div className="report">
                <div className="report_params" id='params'>
                    <div className="card-content-block_header">
                        <div className="card-content-block_header_actions">
                            <div
                                className="card-content-block_header_accordion"
                                onClick={() => setParamsOpen(!paramsOpen)}
                            >
                                <Icon name={paramsOpen ? 'angle down' : 'angle up'} />
                            </div>
                        </div>
                    </div>
                    {paramsOpen && <Form>
                        <Grid>
                            <Grid.Row columns={tcDetalizationSettingView ? 5 : 4}>
                                <Grid.Column>
                                    <Select_new
                                        isTranslate
                                        name={controlNames.dateType}
                                        value={params.dateType}
                                        source={'documentReturnDateType'}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Date
                                        name={controlNames.startDate}
                                        value={params.startDate}
                                        isDisabled={!params.startDate}
                                        onChange={handleChangeParams}
                                        noClearable
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Date
                                        name={controlNames.endDate}
                                        value={params.endDate}
                                        isDisabled={!params.endDate}
                                        onChange={handleChangeParams}
                                        noClearable
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <MultiSelect
                                        name="transportCompanies"
                                        source="transportCompanies/all"
                                        textValue={(!params.transportCompanies || params.transportCompanies.length === 0) && t('All')}
                                        value={params.transportCompanies}
                                        onChange={handleChangeParams}
                                        isDisabled={!tcDetalizationSettingView}
                                    />
                                </Grid.Column>
                                {tcDetalizationSettingView && <Grid.Column>
                                    <Bool
                                        name="showTransportCompanyDetalization"
                                        value={params.showTransportCompanyDetalization}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>}
                            </Grid.Row>
                            <Grid.Row columns={tcDetalizationSettingView ? 5 : 4}>
                                <Grid.Column>
                                    <Select_new
                                        isTranslate
                                        textValue={!params.detalizationType && t('All')}
                                        name="detalizationType"
                                        value={params.detalizationType}
                                        source={'detalizationType'}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <MultiSelectIS
                                        isTranslate
                                        textValue={!params.detalizationTypeValue && t('All')}
                                        name="detalizationTypeValue"
                                        value={params.detalizationTypeValue}
                                        source={params.detalizationType ? `detalizationTypeValue/${params.detalizationType.value}` : null}
                                        onChange={handleChangeParams}
                                        isDisabled={!params.detalizationType}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Bool
                                        name="showShippingDetalization"
                                        value={params.showShippingDetalization}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                                <Grid.Column className={tcDetalizationSettingView ? 'col-w-2' : ''}>
                                    <Button className='report_button' primary disabled={loading}
                                        onClick={generateReport}>
                                        {t('Generate report')}
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>}
                </div>

                <div className={`report_table ${!paramsOpen ? 'report_table_max' : ''}`}>
                    <Block
                        height={height}
                        item={item}
                        actions={item.actions}
                        loading={loading}
                        footer={click && pagination}
                    />
                </div>
            </div>
        </div>
    )
}

export default KpiAnalysis;

