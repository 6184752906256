import { all, put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { postman } from '../utils/postman';

//*  TYPES  *//
const GET_SHIPPINGS_MAP_DATA_REQUEST = 'GET_SHIPPINGS_MAP_DATA_REQUEST';
const GET_SHIPPINGS_MAP_DATA_SUCCESS = 'GET_SHIPPINGS_MAP_DATA_SUCCESS';
const GET_SHIPPINGS_MAP_DATA_ERROR = 'GET_SHIPPINGS_MAP_DATA_ERROR';

const GET_SHIPPINGS_MAP_DEFAULT_FILTERS_REQUEST = 'GET_SHIPPINGS_MAP_DEFAULT_FILTERS_REQUEST';
const GET_SHIPPINGS_MAP_DEFAULT_FILTERS_SUCCESS = 'GET_SHIPPINGS_MAP_DEFAULT_FILTERS_SUCCESS';
const GET_SHIPPINGS_MAP_DEFAULT_FILTERS_ERROR = 'GET_SHIPPINGS_MAP_DEFAULT_FILTERS_ERROR';

//*  INITIAL STATE  *//

const initial = {
    data: [],
    progress: false,
    defaultFilters: {},
};

//*  REDUCER  *//

export default (state = initial, { type, payload }) => {
    switch (type) {
        case GET_SHIPPINGS_MAP_DATA_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_SHIPPINGS_MAP_DATA_SUCCESS:
            return {
                ...state,
                progress: false,
                data: payload,
            };
        case GET_SHIPPINGS_MAP_DATA_ERROR:
            return {
                ...state,
                progress: false,
                data: [],
            };
        case GET_SHIPPINGS_MAP_DEFAULT_FILTERS_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_SHIPPINGS_MAP_DEFAULT_FILTERS_SUCCESS:
            return {
                ...state,
                progress: false,
                defaultFilters: payload,
            };
        case GET_SHIPPINGS_MAP_DEFAULT_FILTERS_ERROR:
            return {
                ...state,
                progress: false,
                defaultFilters: {},
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export const getShippingsMapDataRequest = payload => {
    return {
        type: GET_SHIPPINGS_MAP_DATA_REQUEST,
        payload,
    };
};

export const getDefaultFiltersRequest = () => {
    return {
        type: GET_SHIPPINGS_MAP_DEFAULT_FILTERS_REQUEST
    };
};

//*  SELECTORS *//

const stateSelector = state => state.maps;

export const progressSelector = createSelector(stateSelector, state => state.progress);
export const mapDataSelector = createSelector(stateSelector, state => state.data);
export const defaultFiltersSelector = createSelector(stateSelector, state => state.defaultFilters);

//*  SAGA  *//

function* getShippingsMapDataSaga({ payload }) {
    try {
        const result = yield postman.post(`/shipping/maps/get`, payload.filters);
        if (!(result || []).length && payload.arrayIsEmptyCallback) payload.arrayIsEmptyCallback()
        yield put({
            type: GET_SHIPPINGS_MAP_DATA_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({
            type: GET_SHIPPINGS_MAP_DATA_ERROR,
            payload: e,
        });
    }
}


function* getDefaultFiltersSaga() {
    try {
        const result = yield postman.get(`/shipping/maps/defaultFilter`);
        yield put({
            type: GET_SHIPPINGS_MAP_DEFAULT_FILTERS_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({
            type: GET_SHIPPINGS_MAP_DEFAULT_FILTERS_ERROR,
            payload: e,
        });
    }
}

export function* saga() {
    yield all([
        takeEvery(GET_SHIPPINGS_MAP_DATA_REQUEST, getShippingsMapDataSaga),
        takeEvery(GET_SHIPPINGS_MAP_DEFAULT_FILTERS_REQUEST, getDefaultFiltersSaga),
    ]);
}
