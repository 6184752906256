import {useSelector} from "react-redux";
import {isReadOnlyFieldsSelector} from "../../../ducks/gridList";
import {Grid} from "semantic-ui-react";
import FormField from "./formField";
import React from "react";

export const Content = ({error, columnsArray, form, handleChange, columnsConfig, settings, dictionaryName, label, customBlock}) => {
    const isReadOnlyFields = useSelector(state => isReadOnlyFieldsSelector(state, dictionaryName));

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    {label && <label className='dictionary-edit__label'>{label}</label>}
                    <div className="ui form dictionary-edit">
                        {
                            columnsArray.map(c => {
                                return (
                                    <FormField
                                        key={c}
                                        isReadOnly={(isReadOnlyFields[c] && form[isReadOnlyFields[c]]) || columnsConfig[c].isReadOnly}
                                        name={c}
                                        columnsConfig={columnsConfig}
                                        form={form}
                                        error={error}
                                        onChange={handleChange}
                                        settings={settings}
                                    />
                                )
                            })
                        }
                    </div>
                    {customBlock && customBlock({handleChange})}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
