import {
    BIG_TEXT_TYPE,
    DATE_TIME_TYPE,
    ENUM_TYPE,
    INTEGER_TYPE,
    NUMBER_TYPE,
    SELECT_TYPE,
    SELECT_TYPE_WITH_ADD,
    TEXT_TYPE
} from "./columnTypes";

export const stopperFields = [
    {
        name: 'pointType',
        type: ENUM_TYPE,
        source: 'pointType',
        isRequired: true
    },
    {
        name: 'clientName',
        type: TEXT_TYPE
    },
    {
        name: 'clientId',
        type: SELECT_TYPE,
        source: 'clients',
        isRequired: true
    },
    {
        name: 'address',
        type: TEXT_TYPE
    },
    {
        name: 'cityId',
        type: SELECT_TYPE,
        source: 'directions',
    },
    {
        name: 'countryId',
        type: SELECT_TYPE,
        source: 'countries',
        isRequired: true
    },
    {
        name: 'postalCode',
        type: TEXT_TYPE
    },
    {
        name: 'dateTime',
        type: DATE_TIME_TYPE,
        isRequired: true
    },
    {
        name: 'instruction',
        type: BIG_TEXT_TYPE,
        widthForForm: 100
    },
];

export const cargoUnitsFields = [
    {
        name: 'cargoUnit',
        type: SELECT_TYPE,
        source: 'cargoUnitTypes',
        isRequired: true
    },
    {
        name: 'count',
        type: INTEGER_TYPE,
        isRequired: true
    }
]

export const additionalServicesFields = [
    {
        name: 'financialRowId',
        type: SELECT_TYPE_WITH_ADD,
        source: 'financialRows',
        displayNameKey: 'form.additionalService.financialRowId',
        widthForForm: 40,
        isRequired: true
    },
    {
        name: 'count',
        type: NUMBER_TYPE,
        displayNameKey: 'form.additionalService.count',
        widthForForm: 20,
        isRequired: true
    },
    {
        name: 'cost',
        type: NUMBER_TYPE,
        displayNameKey: 'form.additionalService.cost',
        widthForForm: 20,
        isRequired: true
    },
    {
        name: 'currencyId',
        type: SELECT_TYPE,
        source: 'currencies',
        displayNameKey: 'currencyId',
        widthForForm: 20,
        isRequired: true
    }
]