import React, {useCallback, useEffect, useState} from 'react';
import CreateForm from "../CreateForm";
import {useTranslation} from "react-i18next";
import Information from "./information";
import {useSelector} from "react-redux";
import {additionalColumnsByNameSelector} from "../../../../ducks/gridList";
import {SHIPPINGS_GRID} from "../../../../constants/grids";
import Stoppers from "../shippingTabs/stoppers";
import Costs from "./costs";

const ShippingCardCreate = ({form, onChangeForm: onChange, errors, settings, ...props}) => {
    const [error, setError] = useState({});

    const {t} = useTranslation();
    const columnsConfig = useSelector(state => additionalColumnsByNameSelector(state, SHIPPINGS_GRID, 'createShippingFromForm')) || [];
    const [routeActiveIndex, setRouteActiveIndex] = useState(0);

    const handleTabChange = useCallback((e, { activeIndex }) => {
        setRouteActiveIndex(activeIndex);
    }, []);

    useEffect(() => {
        setError(errors)
    }, [errors])

    const onChangeForm = (e, d, errName) => {
        onChange(e, d);
        errName && setError(errs => ({
            ...errs,
            [errName]: null
        }))
    }

    const getPanes = () => {
        return [
            {
                menuItem: t('information'),
                render: () => (
                    <Information
                        form={form}
                        onChange={onChangeForm}
                        error={error}
                        settings={settings}
                        columnsConfig={columnsConfig}
                    />
                ),
            },
            {
                menuItem: t('stoppersFormCaption'),
                render: () => (
                    <Stoppers
                        error={error}
                        form={form}
                        routeActiveIndex={routeActiveIndex}
                        settings={settings}
                        tabChange={handleTabChange}
                        onChange={onChangeForm}
                        columnsConfig={columnsConfig}
                        isCreate
                    />
                ),
            },
            {
                menuItem: t('costs'),
                render: () => (
                    <Costs
                        form={form}
                        onChange={onChangeForm}
                        error={error}
                        settings={settings}
                        columnsConfig={columnsConfig}
                    />
                ),
            },
        ];
    }

    return <CreateForm
        {...props}
        form={form}
        onChange={onChangeForm}
        error={error}
        settings={settings}
        getPanes={getPanes}
    />;
};

export default ShippingCardCreate;