import React, {useEffect} from 'react';
import {Form, Grid} from 'semantic-ui-react';
import {useDispatch, useSelector} from 'react-redux';
import DocumentTC from "../regTransportCompanyTabs/document";
import {
    documentsTemplatesSelector,
    downloadDocumentRequest, getDocumentsRequest,
    getDocumentTypesRequest,
    getTemplatesDocuments
} from "../../../../ducks/documents";
import {SETTINGS_TYPE_EDIT} from "../../../../constants/formTypes";
import NumberAndDate from "../../../../components/NumberAndDate";
import {columnsTypesConfigSelector} from "../../../../ducks/representations";
import {SHIPPINGS_GRID} from "../../../../constants/grids";
import FormField from "../formField";

const excludeDocsByTemplateKey = ['ShippingPrintedForm']
const DeliveryDocuments = ({form = {}, settings = {}, updateHistory, name, onChange, error}) => {
    const dispatch = useDispatch();
    const documents = useSelector(
        state => form.transportCompanyId
            ? (form.transportCompanyId.isResident
                ? documentsTemplatesSelector(state, form.transportCompanyId.opf, 'opf', true, 'isResident', 'Resident')
                : documentsTemplatesSelector(state, 'NotResident', 'isResident'))
            : []
    );
    const columnsConfig = useSelector(state => columnsTypesConfigSelector(state, SHIPPINGS_GRID)) || {};

    useEffect(() => {
        if (!form.id) return;

        dispatch(getTemplatesDocuments({ id: form.id, grid: name }))
        dispatch(getDocumentTypesRequest())
        getDocs();
    }, [form.id])

    const getDocs = () => {
        dispatch(getDocumentsRequest({gridName: name, cardId: form.id}))
    }

    const handleDownload = id => {
        dispatch(downloadDocumentRequest({ id }))
    }

    const isResident = (form.transportCompanyId || {}).isResident;

    return (
        <Form className="tabs-card">
            <Form.Group widths='equal' className='margin-bottom-24'>
                <FormField
                    width='100%'
                    name='isWorkingByScan'
                    columnsConfig={columnsConfig}
                    settings={settings}
                    form={form}
                    error={error}
                    onChange={onChange}
                />
                <FormField
                    name='deliveryDocsDate'
                    columnsConfig={columnsConfig}
                    settings={settings}
                    form={form}
                    error={error}
                    onChange={onChange}
                />
            </Form.Group>
            <NumberAndDate
                form={form}
                name={'invoice'}
                onChange={onChange}
                settings={settings}
                error={error}
                columnsConfig={columnsConfig}
            />
            <NumberAndDate
                className={!isResident && 'margin-bottom-24'}
                form={form}
                name={'act'}
                onChange={onChange}
                settings={settings}
                error={error}
                columnsConfig={columnsConfig}
            />
            {isResident && <NumberAndDate
                className='margin-bottom-24'
                form={form}
                name={'bill'}
                onChange={onChange}
                settings={settings}
                error={error}
                columnsConfig={columnsConfig}
            />}
            <Grid>
                <Grid.Row>
                    {documents.filter(doc => !excludeDocsByTemplateKey.includes(doc.templateKey)).map(doc => (
                        <DocumentTC
                            onlyOne
                            getDocs={getDocs}
                            handleDownload={handleDownload}
                            typeId={doc.typeId}
                            id={form.id}
                            fileId={doc.fileId}
                            label={doc.name}
                            text={doc.description}
                            img={doc.file !== '' ? doc.file : null}
                            gridName={name}
                            updateHistory={updateHistory}
                            disabled={!(settings && settings['paymentDocuments'] === SETTINGS_TYPE_EDIT)}
                        />
                    ))}
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default DeliveryDocuments;
