import { all, put, takeEvery, take, spawn, delay, cancelled, cancel, fork, select } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import {downloader, postman} from '../utils/postman';
import downloadFile, {downloadBase64File} from "../utils/downloadFile";
import {toast} from "react-toastify";

const TYPE_API = 'reports';

//*  TYPES  *//

const GET_REPORT_REQUEST = 'GET_REPORT_REQUEST';
const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
const GET_REPORT_ERROR = 'GET_REPORT_ERROR';

const REPORT_EXPORT_TO_EXCEL_REQUEST = 'REPORT_EXPORT_TO_EXCEL_REQUEST';
const REPORT_EXPORT_TO_EXCEL_SUCCESS = 'REPORT_EXPORT_TO_EXCEL_SUCCESS';
const REPORT_EXPORT_TO_EXCEL_ERROR = 'REPORT_EXPORT_TO_EXCEL_ERROR';

const GENERATE_REPORT_REQUEST = 'GENERATE_REPORT_REQUEST';
const GENERATE_REPORT_SUCCESS = 'GENERATE_REPORT_SUCCESS';
const GENERATE_REPORT_ERROR = 'GENERATE_REPORT_ERROR';

const GET_DATA_FOR_REPORT_REQUEST = 'GET_DATA_FOR_REPORT_REQUEST';
const GET_DATA_FOR_REPORT_SUCCESS = 'GET_DATA_FOR_REPORT_SUCCESS';
const GET_DATA_FOR_REPORT_ERROR = 'GET_DATA_FOR_REPORT_ERROR';

const CLEAR_DATA_FOR_REPORT = 'CLEAR_DATA_FOR_REPORT';

const GET_TEMPLATES_REQUEST = 'GET_TEMPLATES_REQUEST';
const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
const GET_TEMPLATES_ERROR = 'GET_TEMPLATES_ERROR';

const UPLOAD_TEMPLATE_REQUEST = 'UPLOAD_TEMPLATE_REQUEST';
const UPLOAD_TEMPLATE_SUCCESS = 'UPLOAD_TEMPLATE_SUCCESS';
const UPLOAD_TEMPLATE_ERROR = 'UPLOAD_TEMPLATE_ERROR';

const RESTORE_TEMPLATE_REQUEST = 'RESTORE_TEMPLATE_REQUEST';
const RESTORE_TEMPLATE_SUCCESS = 'RESTORE_TEMPLATE_SUCCESS';
const RESTORE_TEMPLATE_ERROR = 'RESTORE_TEMPLATE_ERROR';

const PERSONAL_DATA_REQUEST = 'PERSONAL_DATA_REQUEST';
const PERSONAL_DATA_SUCCESS = 'PERSONAL_DATA_SUCCESS';
const PERSONAL_DATA_ERROR = 'PERSONAL_DATA_ERROR';

const CHECK_SHIPPING_NUMBER_REQUEST = 'CHECK_SHIPPING_NUMBER_REQUEST';
const CHECK_SHIPPING_NUMBER_SUCCESS = 'CHECK_SHIPPING_NUMBER_SUCCESS';
const CHECK_SHIPPING_NUMBER_ERROR = 'CHECK_SHIPPING_NUMBER_ERROR';

//*  INITIAL STATE  *//

const initial = {
    data: [],
    columns: [],
    config: [],
    templates: [],
    progress: false,
    exportProgress: false,
    printLoader: false,
    restoreLoader: false,
    uploadLoader: false,
    personalData: null,
    filterSettings: {}
};

//*  REDUCER  *//

export default (state = initial, { type, payload }) => {
    switch (type) {
        case GET_REPORT_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_REPORT_SUCCESS:
            return {
                ...state,
                progress: false,
                ...payload,
            };
        case GET_REPORT_ERROR:
            return {
                ...state,
                progress: false,
            };
        case REPORT_EXPORT_TO_EXCEL_REQUEST:
            return {
                ...state,
                exportProgress: true
            };
        case REPORT_EXPORT_TO_EXCEL_SUCCESS:
        case REPORT_EXPORT_TO_EXCEL_ERROR:
            return {
                ...state,
                exportProgress: false
            };
        case GET_TEMPLATES_REQUEST: {
            return {
                ...state,
                progress: true
            };
        }
        case GET_TEMPLATES_SUCCESS:
            return {
                ...state,
                templates: payload,
                progress: false,
            };
        case GET_TEMPLATES_ERROR:
            return {
                ...state,
                progress: false,
            };
        case PERSONAL_DATA_SUCCESS:
            return {
                ...state,
                templatePersonalData: payload
            };
        case GENERATE_REPORT_REQUEST:
            return {
                ...state,
                printLoader: true
            };
        case GENERATE_REPORT_ERROR:
        case GENERATE_REPORT_SUCCESS:
            return {
                ...state,
                printLoader: false
            };
        case UPLOAD_TEMPLATE_REQUEST:
            return {
                ...state,
                uploadLoader: true
            };
        case UPLOAD_TEMPLATE_SUCCESS:
        case UPLOAD_TEMPLATE_ERROR:
            return {
                ...state,
                uploadLoader: false
            };
        case RESTORE_TEMPLATE_REQUEST:
            return {
                ...state,
                restoreLoader: true
            };
        case RESTORE_TEMPLATE_SUCCESS:
        case RESTORE_TEMPLATE_ERROR:
            return {
                ...state,
                restoreLoader: false
            };
        case GET_DATA_FOR_REPORT_REQUEST:
            return {
                ...state,
                progress: true
            };
        case GET_DATA_FOR_REPORT_SUCCESS:
            return {
                ...state,
                progress: false,
                filterSettings: payload
            };
        case CLEAR_DATA_FOR_REPORT:
            return {
                ...state,
                filterSettings: {}
            };
        case GET_DATA_FOR_REPORT_ERROR:
            return {
                ...state,
                progress: false
            };
        default:
            return state;
    }
}

//*  ACTION CREATORS  *//

export const getReportRequest = (payload) => {
    return {
        type: GET_REPORT_REQUEST,
        payload
    }
};

export const getDataForReportRequest = (payload) => {
    return {
        type: GET_DATA_FOR_REPORT_REQUEST,
        payload
    }
};

export const reportExportToExcelRequest = payload => {
    return {
        type: REPORT_EXPORT_TO_EXCEL_REQUEST,
        payload
    }
};

export const generateReportRequest = payload => {
    return {
        type: GENERATE_REPORT_REQUEST,
        payload,
    }
};

export const getTemplatesListRequest = payload => {
    return {
        type: GET_TEMPLATES_REQUEST,
        payload
    }
};

export const uploadTemplatesRequest = payload => {
    return {
        type: UPLOAD_TEMPLATE_REQUEST,
        payload
    }
};

export const restoreTemplatesRequest = payload => {
    return {
        type: RESTORE_TEMPLATE_REQUEST,
        payload
    }
};

export const personalDataRequest = payload => {
    return {
        type: PERSONAL_DATA_REQUEST,
        payload
    }
};

export const clearFiltersData = () => {
    return {
        type: CLEAR_DATA_FOR_REPORT,
    }
};

export const checkShippingNumber = (payload) => {
    return {
        type: CHECK_SHIPPING_NUMBER_REQUEST,
        payload
    }
};

//*  SELECTORS *//

const stateSelector = state => state.reports || {};
export const reportSelector = createSelector(stateSelector, state => state.items || []);
export const turnoverInRublesSelector = createSelector(stateSelector, state => state.turnoverInRublesDtos || []);
export const turnoverInShippingsSelector = createSelector(stateSelector, state => state.turnoverInShippingsDtos || []);
export const totalCountSelector = createSelector(stateSelector, state => state.totalCount || 0);

export const columnsSelector = createSelector(stateSelector, state => {
    return state.config.filter(item => state.columns.map(column => column.toLowerCase()).includes(item.name.toLowerCase()))
});

export const progressSelector = createSelector(stateSelector, state => state.progress);
export const exportProgressSelector = createSelector(stateSelector, state => state.exportProgress);

export const templateSelector = createSelector(stateSelector, state => state.templates);
export const templatePersonalDataSelector = createSelector(stateSelector, state => state.templatePersonalData);
export const printLoaderSelector = createSelector(stateSelector, state => state.printLoader);
export const restoreLoaderSelector = createSelector(stateSelector, state => state.restoreLoader);
export const uploadLoaderSelector = createSelector(stateSelector, state => state.uploadLoader);
export const filterSettingsSelector = createSelector(stateSelector, state => state.filterSettings);

//*  SAGA  *//

function* getReportSaga({ payload }) {
    try {
        const {gridName, params} = payload;

        //const config = yield postman.get(`/${gridName}/reportConfiguration`);

        const result = yield postman.post(`/${gridName}/generateReport`, params);

        yield put({
            type: GET_REPORT_SUCCESS,
            payload: {
                ...result,
                //config
            }
        })
    } catch (e) {
        yield put({
            type: GET_REPORT_ERROR
        })
    }
}

function* reportExportToExcelSaga({ payload }) {
    try {
        const {gridName, params} = payload;

        const result = yield downloader.post(`/${gridName}/exportExcel`, params, { responseType: 'blob' });

        downloadFile(result);

        yield put({type: REPORT_EXPORT_TO_EXCEL_SUCCESS})
    } catch (e) {
        yield put({
            type: REPORT_EXPORT_TO_EXCEL_ERROR
        })
    }
}

function* generateReportSaga({ payload }) {
    try {
        const {gridName, params} = payload;

        const result = yield postman.post(`/${gridName}/generateReport`, params);

        if (result.isError) {
            toast.error(result.message);
            yield put({type: GENERATE_REPORT_ERROR})
        } else {
            toast.info(result.message);
            yield put({type: GENERATE_REPORT_SUCCESS})
            const {result: res} = result;
            downloadBase64File(res.body, res.name, res.contentType)
        }
    } catch (e) {

        yield put({
            type: GENERATE_REPORT_ERROR
        })
    }
}

function* getTemplatesListSaga({ payload }) {
    try {
        const result = payload ? yield postman.post(`/reporttemplate/getIdsFromRegistrationRequestAnonymous`, null,{params: payload})
            : yield postman.get(`/reporttemplate/getCurrentAndPreviousIds`);

        if (result.isError) {
            toast.error(result.message);

            yield put({
                type: GET_TEMPLATES_ERROR,
            });
        } else {
            yield put({
                type: GET_TEMPLATES_SUCCESS,
                payload: result
            })
        }
    } catch (e) {
        yield put({
            type: GET_TEMPLATES_ERROR
        })
    }
}


function* getPersonalDataSaga() {
    try {
        const result = yield postman.get(`/reporttemplate/getPersonalDataPolicyIdAnonymous`);

        if (result.isError) {
            toast.error(result.message);

            yield put({
                type: PERSONAL_DATA_ERROR,
            });
        } else {
            yield put({
                type: PERSONAL_DATA_SUCCESS,
                payload: result
            })
        }
    } catch (e) {
        yield put({
            type: PERSONAL_DATA_ERROR
        })
    }
}

function* uploadTemplateSaga({ payload }) {
    try {
        const { form, reportType, callbackSuccess } = payload;
        const result = yield postman.post(`/reporttemplate/uploadTemplate?reportType=${reportType}`, form, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (result.isError) {
            toast.error(result.message);

            yield put({
                type: UPLOAD_TEMPLATE_ERROR
            });
        } else {
            toast.info(result.message);
            yield put({
                type: UPLOAD_TEMPLATE_SUCCESS,
            });

            callbackSuccess && callbackSuccess();
        }
    } catch (e) {
        yield put({
            type: UPLOAD_TEMPLATE_ERROR
        })
    }
}

function* restoreTemplateSaga({ payload }) {
    try {
        const { id, callbackSuccess } = payload;
        const result = yield postman.post(`/reporttemplate/restoreTemplate?reportType=${id}`)
        if (result.isError) {
            toast.error(result.message);

            yield put({
                type: RESTORE_TEMPLATE_ERROR
            });
        } else {
            toast.info(result.message);
            yield put({
                type: RESTORE_TEMPLATE_SUCCESS,
            });

            callbackSuccess && callbackSuccess();
        }
    } catch (e) {
        yield put({
            type: RESTORE_TEMPLATE_ERROR
        })
    }
}

function* dataForReportSaga({ payload }) {
    try {
        const { gridName, shippingNumber } = payload;
        const result = yield postman.get(`/${gridName}/loadSettingSetup`, {params: {shippingNumber}})
        if (result.isError) {
            yield put({
                type: GET_DATA_FOR_REPORT_ERROR
            });
        } else {
            yield put({
                type: GET_DATA_FOR_REPORT_SUCCESS,
                payload: result
            });
        }
    } catch (e) {
        yield put({
            type: GET_DATA_FOR_REPORT_ERROR
        })
    }
}

function* checkShippingNumberSaga({ payload }) {
    try {
        const { number, callback } = payload;
        const result = yield postman.get(`/registriesForPayment/checkShippingNumber/${number}`)

        callback && callback(result);
        yield put({
            type: CHECK_SHIPPING_NUMBER_SUCCESS,
            payload: result
        });
    } catch (e) {
        yield put({
            type: CHECK_SHIPPING_NUMBER_ERROR
        })
    }
}

export function* saga() {
    yield all([
        takeEvery(GET_REPORT_REQUEST, getReportSaga),
        takeEvery(REPORT_EXPORT_TO_EXCEL_REQUEST, reportExportToExcelSaga),
        takeEvery(GENERATE_REPORT_REQUEST, generateReportSaga),
        takeEvery(GET_TEMPLATES_REQUEST, getTemplatesListSaga),
        takeEvery(UPLOAD_TEMPLATE_REQUEST, uploadTemplateSaga),
        takeEvery(RESTORE_TEMPLATE_REQUEST, restoreTemplateSaga),
        takeEvery(GET_DATA_FOR_REPORT_REQUEST, dataForReportSaga),
        takeEvery(PERSONAL_DATA_REQUEST, getPersonalDataSaga),
        takeEvery(CHECK_SHIPPING_NUMBER_REQUEST, checkShippingNumberSaga),
    ])
}
