import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const Number = ({
    value = '',
    name,
    onChange,
    isDisabled,
    noLabel,
    className,
    text,
    error,
    placeholder,
    isRequired,
    isInteger,
    autoFocus,
    hintInfo,
    hintKey,
    numberClasses = false,
    isMoney = false,
    isPercent = false
}) => {
    const { t } = useTranslation();

    const handleOnChange = (e, { value }) => {
        if (isPercent) {
            (!value || ((value.split('.')[1] || '').length < 3 && value <= 100)) && onChange(e, {
                name,
                value
            });
        } else {
            let reg = /^\d+(\.)?(\d)?(\d)?$/;
            value = isMoney ? value.split(' ').join('') : value;
            const isValid = (isMoney && value) ? reg.test(value) : true;
            isValid && onChange(e, {
                name,
                value: isMoney ? value : value.replace(/[^\d]/g, ''),
            });
        }
    };

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `${t(text || name)}${isRequired ? ' *' : ''}`,
                        }}
                    />
                </label>
            ) : null}
            {hintKey ? (
                <div className="field-hint">
                    <i>{t(hintKey)}</i>
                </div>
            ) : null}
            <Input
                placeholder={placeholder}
                className={className}
                type={(isInteger || isMoney) ? 'text' : 'number'}
                error={error}
                disabled={isDisabled || false}
                name={name}
                value={numberClasses ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : value}
                step="any"
                autoFocus={autoFocus}
                onChange={(isInteger || isMoney || isPercent) ? handleOnChange : onChange}
                autoComplete="off"
            />
            {error && typeof error === 'string' ? (
                <span className="label-error" dangerouslySetInnerHTML={{ __html: error }} />
            ) : null}
            {hintInfo && typeof hintInfo === 'string' ? (
                <span className="label-info" dangerouslySetInnerHTML={{ __html: hintInfo }} />
            ) : null}
        </Form.Field>
    );
};
export default Number;
