import React from 'react';
import CardLayout from "../../../components/CardLayout";
import {useTranslation} from "react-i18next";

const CreateForm = ({gridName, getPanes, actionsFooter, onClose, loading}) => {
    const {t} = useTranslation();

    const title = t(`new_${gridName}`);

    return (
        <CardLayout
            title={title}
            actionsFooter={actionsFooter}
            content={getPanes}
            onClose={onClose}
            loading={loading}
        />
    );
};

export default CreateForm;