import React from 'react';
import FormField from "../../containers/customGrid/components/formField";
import {Form} from "semantic-ui-react";

export const styleForFields = (colsCounts, initWidth) => {
    const width = 100 / colsCounts - 3;
    const margin = `calc(calc(${(100 - (width * colsCounts))}% / ${colsCounts}) / 2)`
    const customWidth = initWidth && `calc(${initWidth}% - ${margin} - ${margin})`

    return {
        width: customWidth || `${width}%`,
        marginLeft: margin,
        marginRight: margin,
    }
};

const FormFields = ({label, columnsArray, columnsConfig, form, error, handleChange, settings, nameError, colCount = 3, noLabel, content, index, disabled}) => {
    return (
        <Form className='form-fields'>
            {label && <div
                style={{
                    marginLeft: styleForFields(colCount).marginLeft
                }}
                className='form-fields__label'
            >
                <label>{label}</label>
            </div>}
            <div className='form-fields__fields-block'>
                {
                    columnsArray.map(c => {
                        return (
                            <div style={styleForFields(colCount, columnsConfig[c].widthForForm)}>
                                <FormField
                                    noLabel={noLabel}
                                    key={c}
                                    isReadOnly={columnsConfig[c].isReadOnly || disabled}
                                    name={c}
                                    columnsConfig={columnsConfig}
                                    form={form}
                                    error={error}
                                    onChange={handleChange}
                                    settings={settings}
                                    nameError={nameError && nameError(c, index)}
                                />
                            </div>
                        )
                    })
                }
                {
                    content && <div style={styleForFields(colCount, 100)}>
                        {content}
                    </div>
                }
            </div>
        </Form>
    );
};

export default FormFields;