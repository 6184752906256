import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import CardLayout from '../../components/CardLayout';
import {Button, Confirm, Divider, Form, Grid, Popup, TextArea} from 'semantic-ui-react';
import {
    cardProgressSelector,
    cardSelector,
    clearDictionaryCard,
    deleteDictionaryEntryRequest,
    errorSelector,
    getCardRequest,
    progressSelector,
    saveDictionaryCardRequest,
} from '../../ducks/dictionaryView';
import Documents from '../customGrid/components/shared/documents';
import {PERSONAL_CABINET_CARD_LINK} from "../../router/links";
import {canDeleteSelector, columnsFormSelector, isReadOnlyFieldsSelector} from "../../ducks/gridList";
import {PERSONAL_CABINET_MEETING_PROTOCOLS} from "../../constants/personalCabinetPages";
import {columnsTypesConfigSelector} from "../../ducks/representations";
import FormField from "../customGrid/components/formField";
import {settingsFormSelector} from "../../ducks/gridCard";
import {commentsToText} from "../../utils/comments";
import TaskCompletion from "./taskCompletion";

const Content = ({error, form, handleChange, columnsConfig, settings, dictionaryName}) => {
    const {t} = useTranslation();

    const columnsArray = [
        'transportCompanyId', 'countryId', 'meetingDateTime', 'meetingType',
        'subject', 'deadline', 'state'
    ];

    const isReadOnlyFields = useSelector(state => isReadOnlyFieldsSelector(state, dictionaryName));

    return (
        <Grid className='meeting-protocol__content'>
            <Grid.Row>
                <Grid.Column>
                    <div className="ui form dictionary-edit">
                        {
                            columnsArray.map(c => {
                                return (
                                    <FormField
                                        isReadOnly={(isReadOnlyFields[c] && form[isReadOnlyFields[c]]) || columnsConfig[c].isReadOnly}
                                        name={c}
                                        columnsConfig={columnsConfig}
                                        form={form}
                                        error={error}
                                        onChange={handleChange}
                                        settings={settings}
                                    />
                                )
                            })
                        }
                    </div>
                </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row className='field-comments'>
                <Grid.Column>
                    <div className="ui form dictionary-edit">
                        <Form.Field>
                            <FormField
                                name="fmComment"
                                placeholder={t('rtcCommentPlaceholder')}
                                columnsConfig={columnsConfig}
                                settings={settings}
                                form={form}
                                error={error}
                                onChange={handleChange}
                                isDisabled={!form.isFm}
                            />
                            <label>{t('rtcCommentsHistory')}</label>
                            <TextArea
                                className='textarea-disabled-scroll'
                                disabled
                                autoHeight
                                name='comments'
                                value={commentsToText(form.fmComments)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <FormField
                                name="tcComment"
                                placeholder={t('rtcCommentPlaceholder')}
                                columnsConfig={columnsConfig}
                                settings={settings}
                                form={form}
                                error={error}
                                onChange={handleChange}
                                isDisabled={!form.isTc}
                            />
                            <label>{t('rtcCommentsHistory')}</label>
                            <TextArea
                                className='textarea-disabled-scroll'
                                disabled
                                autoHeight
                                name='comments'
                                value={commentsToText(form.tcComments)}
                            />
                        </Form.Field>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};


const MeetingProtocolForm = ({...props}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {
        match = {},
        history,
        location = {},
    } = props;
    const {params = {}} = match;
    const {id} = params;

    const name = PERSONAL_CABINET_MEETING_PROTOCOLS;

    let [form, setForm] = useState({});
    let [confirmation, setConfirmation] = useState({open: false});
    let [notChangeForm, setNotChangeForm] = useState(true);

    const columns = useSelector(state => columnsFormSelector(state, name));
    const columnsConfig = useSelector(state => columnsTypesConfigSelector(state, name)) || {};
    const settings = useSelector(state => settingsFormSelector(state, form.state));

    const canDelete = useSelector(state => canDeleteSelector(state, name));
    const loading = useSelector(state => cardProgressSelector(state));
    const progress = useSelector(state => progressSelector(state));
    const card = useSelector(state => cardSelector(state));
    const error = useSelector(state => errorSelector(state));

    useEffect(() => {
        id && fetchData();

        return () => {
            dispatch(clearDictionaryCard());
        };
    }, []);

    useEffect(() => {
        setForm(form => ({
            ...form,
            ...card
        }));
    }, [card]);

    const fetchData = () => {
        dispatch(getCardRequest({
            id,
            name,
            redirectCallback: onClose
        }));
    };

    const title = useMemo(
        () => (id ? `${t(name)}: ${t('edit_record')}` : `${t(name)}: ${t('new_record')}`),
        [name, id],
    );

    const getActionsFooter = useCallback(() => {
        return (
            <>
                <Button color="grey" onClick={handleClose}>
                    {t('CancelButton')}
                </Button>
                <Button
                    color="blue"
                    disabled={notChangeForm || progress}
                    loading={progress}
                    onClick={() => handleSave(!id)}
                >
                    {t('SaveButton')}
                </Button>
            </>
        );
    }, [form, notChangeForm, progress, id]);

    const handleSave = (isNotClose) => {
        let params = {
            ...form,
        };

        if (id) {
            params = {
                ...params,
                id,
            };
        }

        dispatch(
            saveDictionaryCardRequest({
                params,
                name,
                callbackSuccess: (id, lookup, isClose = false) => {
                    setNotChangeForm(true);
                    if (isNotClose && !isClose) {
                        history.replace({
                            pathname: PERSONAL_CABINET_CARD_LINK.replace(":pageName", name).replace(":id", id),
                            state: {...location.state}
                        });
                    } else {
                        fetchData();
                    }
                },
            }),
        );
    };

    const handleDelete = () => {

        setConfirmation({
            open: true,
            content: t('Delete dictionary entry', {name: t(name)}),
            onCancel: confirmClose,
            onConfirm: () => {
                dispatch(
                    deleteDictionaryEntryRequest({
                        name,
                        id,
                        callbackSuccess: () => onClose(),
                    }),
                );
            },
        });
    };

    const getActionsHeader = useCallback(() => {
        return (
            <div>
                {canDelete && id ? (
                    <Popup
                        content={t('delete')}
                        position="bottom right"
                        trigger={
                            <Button icon="trash alternate outline" onClick={handleDelete} content={t('delete')}/>
                        }
                    />
                ) : null}
            </div>
        );
    }, []);

    const getContent = useCallback(() => {
        return [
            {
                menuItem: 'information',
                render: () => {
                    return <Content
                        settings={settings}
                        columns={columns}
                        error={error || {}}
                        form={form}
                        dictionaryName={name}
                        handleChange={handleChange}
                        columnsConfig={columnsConfig}
                    />
                },
                isOpen: true,
                disabled: false
            },
            {
                menuItem: 'taskCompletion',
                render: () => {
                    return <TaskCompletion
                        settings={settings}
                        columns={columns}
                        error={error || {}}
                        form={form}
                        dictionaryName={name}
                        handleChange={handleChange}
                        columnsConfig={columnsConfig}
                        name='taskCompletion'
                    />
                },
                isOpen: true,
                disabled: false
            },
            {
                menuItem: 'tcDocuments',
                render: () => {
                    return <Documents
                        gridName={name}
                        cardId={id}
                        isEditPermissions={form.isTc}
                        updateHistory={() => {
                            setNotChangeForm(false);
                        }}
                        typeId={form.tcDocTypeId}
                    />
                },
                isOpen: !!id,
                disabled: !id
            },
            {
                menuItem: 'fmDocuments',
                render: () => {
                    return <Documents
                        gridName={name}
                        cardId={id}
                        isEditPermissions={form.isFm}
                        updateHistory={() => {
                            setNotChangeForm(false);
                        }}
                        typeId={form.fmDocTypeId}
                    />
                },
                isOpen: !!id,
                disabled: !id
            }
        ];

    }, [form, error, columns, name, id]);

    const handleChange = useCallback(
        (event, {name, value}) => {
            if (notChangeForm) {
                setNotChangeForm(false);
            }
            setForm(form => ({
                ...form,
                [name]: value,
            }));
        },
        [notChangeForm, form],
    );

    const confirmClose = () => {
        setConfirmation({open: false});
    };

    const onClose = (lookup = {}) => {
        const {state = {}} = location;

        const autocompliteNull =
            (!lookup || Object.keys(lookup).length === 0) ? {
                autocompleteItems: null,
                name: null
            } : null;

        state.pathname ? history.replace({
                pathname: state.pathname,
                state: {
                    ...state,
                    lookup,
                    ...autocompliteNull,
                    pathname: state.gridLocation
                        ? state.gridLocation
                        : state.pathname,
                },
            }) : history.goBack();
    };

    const handleClose = () => {
        if (notChangeForm) {
            onClose();
        } else {
            setConfirmation({
                open: true,
                content: t('confirm_close_dictionary'),
                onCancel: confirmClose,
                onConfirm: () => onClose(),
            });
        }
    };

    return (
        <div className="personal-cabinet__page">
            <CardLayout
                title={title}
                actionsFooter={getActionsFooter}
                actionsHeader={getActionsHeader}
                content={getContent}
                onClose={handleClose}
                loading={loading}
            />
            <Confirm
                dimmer="blurring"
                open={confirmation.open}
                onCancel={confirmation.onCancel}
                cancelButton={t('cancelConfirm')}
                confirmButton={t('Yes')}
                onConfirm={confirmation.onConfirm}
                content={confirmation.content}
            />
        </div>
    );
};

export default MeetingProtocolForm;