import React from 'react';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import {useTranslation} from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutСhart = ({positivePercent, labels, counts}) => {
    const { t } = useTranslation();
    const data = {
        labels,
        datasets: [
            {
                data: [positivePercent, 100 - positivePercent],
                backgroundColor: [
                    '#92E72B',
                    '#FF0000'
                ],
                borderWidth: 0,

            },
        ],
    };


    const options = {
        maintainAspectRatio: false,
        cutout: '65%',
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false,
                external: (context) => {
                    let tooltipEl = document.getElementById('chartjs-tooltip');

                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip';
                        tooltipEl.innerHTML = '<div class="chart-tooltip__label"></div><div class="chart-tooltip__description"></div>';
                        document.body.appendChild(tooltipEl);
                    }

                    const tooltipModel = context.tooltip;
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }

                    if (tooltipModel.dataPoints) {
                        const data = tooltipModel.dataPoints[0];

                        tooltipEl.className = 'chart-tooltip chart-tooltip_'+data.dataIndex;
                        tooltipEl.querySelector('.chart-tooltip__label').innerHTML = t(data.label);
                        tooltipEl.querySelector('.chart-tooltip__description').innerHTML = `${t('count')} <b></b>`;
                        tooltipEl.querySelector('b').innerHTML = counts[data.dataIndex];
                    }

                    const position = context.chart.canvas.getBoundingClientRect();

                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.position = 'absolute';
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 20 + 'px';
                    tooltipEl.style.pointerEvents = 'none';
                }
            }
        }
    };

    return (
        <div className='doughnut-chart'>
            <div className='doughnut-chart__diagram'>
                <div>
                    <Doughnut
                        height={175}
                        options={options}
                        data={data}
                    />
                </div>
                <div className='doughnut-chart__percent'>{`${positivePercent}%`}</div>
            </div>
            <div className='doughnut-chart__legend'>
                <div>{labels[0]}</div>
                <div>{labels[1]}</div>
            </div>
        </div>
    );
};

export default DoughnutСhart;