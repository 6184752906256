import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Popup} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import {formatDate, parseDate} from '../../utils/dateTimeFormater';
import {useTranslation} from 'react-i18next';

const Period = ({
                    value,
                    name,
                    onChange,
                    isDisabled,
                    noLabel,
                    text,
                    isRequired,
                    error,
                    setBtn
                }) => {
    const {t} = useTranslation();

    const [tmpValue, setTmpValue] = useState(value);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(()=>{
        !isOpen && setBtn && setTmpValue(value);
    }, [isOpen, value]);

    const idInput = 'input-dates' + Math.random();

    const input = (
        <Input
            id={idInput}
            readOnly
            name={name}
            value={setBtn ? tmpValue : value || ""}
        />
    );

    const getStringItem = (i) => {
        const parts = (setBtn ? tmpValue : value || "").split(" - ");
        return (parts[i] || null);
    };

    const getDateItem = (i) => {
        let s = getStringItem(i);
        if (s)
            return parseDate(s);
        return null;
    };

    const handleChange = (indexDate, value) => {
        const valueDates = `${indexDate === 0 ? value : getStringItem(0)} - ${indexDate === 1 ? value : getStringItem(1)}`;
        setBtn ? setTmpValue(valueDates) : onChange(null, {name, value: valueDates});
    }

    const content = (
        <div className='select-dates'>
            <div>
                <DatePicker
                    selected={getDateItem(0)}
                    locale={localStorage.getItem('i18nextLng')}
                    onChange={(date) => {
                        handleChange(0, date ? formatDate(date) : null)
                    }}
                    inline
                    maxDate={getDateItem(1)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
                <DatePicker
                    selected={getDateItem(1)}
                    locale={localStorage.getItem('i18nextLng')}
                    onChange={(date) => {
                        handleChange(1, date ? formatDate(date) : null)
                    }}
                    inline
                    minDate={getDateItem(0)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
            </div>

            <div className='years__select-block'>
                {setBtn && <Button
                    className='years__select-btn'
                    onClick={() => {
                        onChange(null, {name, value: tmpValue});
                        document.getElementById(idInput).click();
                    }}
                >
                    {t('applyBtn')}
                </Button>}
            </div>
        </div>
    )

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span dangerouslySetInnerHTML={{__html: `${t(text || name)}${isRequired ? ' *' : ''}`}}/>
                </label>
            ) : null}
            <Popup
                trigger={input}
                content={content}
                on='click'
                hideOnScroll
                position='bottom center'
                onClose={() => setIsOpen(false)}
                onOpen={() => setIsOpen(true)}
            />
            {error && typeof error === 'string' ? (
                <span className="label-error" dangerouslySetInnerHTML={{__html: error}}/>
            ) : null}
        </Form.Field>
    );
};
export default Period;
