import React, {useState} from 'react';
import {Form, Grid} from 'semantic-ui-react';
import FormField from '../../../../components/BaseComponents';
import {TEXT_TYPE, BIG_TEXT_TYPE, DATE_TIME_TYPE, SELECT_TYPE} from '../../../../constants/columnTypes';


const pointType = {
    uploading: "uploading",
    unloading: "unloading",
    checkPoint: "checkPoint"
}

const Stopper = ({ form = {}, stopper = {}, index, settings, onChange }) => {
    const [changedValues, setChangedValues] = useState({});

    const onChangeValue = (e, {value, name}) => {
        let newValue = [...form.stoppers];
        setChangedValues(v => ({...v, [`${name}_${index}`]: true}));

        newValue[index] = {
            ...newValue[index],
            [name]: value
        };

        onChange(e, {
            value: newValue,
            name: 'stoppers'
        })
    };

    return (
        <Form style={{ paddingLeft: '12px' }}>
            <Grid>
                <Grid.Row>
                    <Grid.Column width="8">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width="16">
                                    <FormField
                                        isReadOnly
                                        name="clientName"
                                        text={
                                            stopper.pointType.value == pointType.unloading
                                                ? 'stopper_clientNameDelivery'
                                                : stopper.pointType.value == pointType.uploading
                                                    ? 'stopper_clientNameLoading'
                                                    : 'stopper_clientNameCheckPoint'
                                        }
                                        value={stopper['clientName'] || ''}
                                        type={TEXT_TYPE}
                                        settings={settings['clientName']}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        isReadOnly
                                        name="dateTime"
                                        text={'estimatedDateTime'}
                                        value={stopper['dateTime'] || ''}
                                        type={DATE_TIME_TYPE}
                                        settings={settings['dateTime']}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        name="factArrivalTime"
                                        text={'factArrivalTime'}
                                        value={stopper['factArrivalTime'] || ''}
                                        type={DATE_TIME_TYPE}
                                        settings={settings['factArrivalTime']}
                                        onChange={onChangeValue}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        name="factDepartureTime"
                                        text={'factDepartureTime'}
                                        value={stopper['factDepartureTime'] || ''}
                                        type={DATE_TIME_TYPE}
                                        settings={settings['factDepartureTime']}
                                        onChange={onChangeValue}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width="8">
                        <Grid>
                             <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        isReadOnly
                                        name="country"
                                        value={stopper['country'] || ''}
                                        type={TEXT_TYPE}
                                        settings={settings['country']}
                                    />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        isReadOnly
                                        name="city"
                                        text={'stopper_city'}
                                        value={stopper['city'] || ''}
                                        type={TEXT_TYPE}
                                        settings={settings['city']}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        isReadOnly
                                        name="postalCode"
                                        text={'stopper_postalCode'}
                                        value={stopper['postalCode'] || ''}
                                        type={TEXT_TYPE}
                                        settings={settings['postalCode']}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        isReadOnly
                                        name="address2And3"
                                        text={'stopper_address2And3'}
                                        value={stopper['address2And3'] || ''}
                                        type={TEXT_TYPE}
                                        settings={settings['address2And3']}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        isReadOnly
                                        name="cargoCargoUnitsInfoUnits"
                                        text={'stopper_cargoUnits'}
                                        value={stopper['cargoUnitsInfo'] || ''}
                                        type={BIG_TEXT_TYPE}
                                        settings={settings['cargoUnitsInfo']}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        isReadOnly
                                        name="instruction"
                                        text={'stopper_instruction'}
                                        value={stopper['instruction'] || ''}
                                        type={BIG_TEXT_TYPE}
                                        settings={settings['instruction']}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default Stopper;
